import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import isFunction from 'lodash/isFunction';

import ToastNotify from '@app/components/ToastNotifier';
import { NotifyConfig } from '@app/types/NotifyConfig';
import { ErrorCode, errorMessage, ErrorType } from '@app/utils/errorMessage';
import { TrialModalType } from '@app/contexts/PopupsContext';

import { usePopupsContext } from './usePopupsContext';

const defaultError: NotifyConfig['error'] = () => 'Something went wrong';
const defaultSuccess: NotifyConfig['success'] = () =>
  'The data was successfully updated';

const IGNORED_ERROR_CODES = [
  ErrorCode.CONTACTS_CANNOT_BE_REMOVED,
  ErrorCode.PHONE_CANNOT_BE_DELETED,
  ErrorCode.PHONES_CANNOT_BE_DELETED,
];

export const useEntityMutationNotifier = () => {
  const client = useQueryClient();
  const { errorModal, setError, showTrialPopup } = usePopupsContext();

  useEffect(() => {
    const unsubscribe = client.getMutationCache().subscribe((event) => {
      const { state, options } = event;
      const { status, variables, data } = state;
      const { error: stateError }: ErrorType = state;
      if (
        stateError?.details?.code &&
        !IGNORED_ERROR_CODES.includes(stateError.details.code)
      ) {
        const { code } = stateError.details;
        if (code === ErrorCode.LIMIT_EXCEEDED_SMS) {
          showTrialPopup(TrialModalType.USED);
          return;
        }

        if (code === ErrorCode.LIMIT_EXCEEDED_CAMPAIGN) {
          showTrialPopup(TrialModalType.ATTENTION);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setError(errorMessage(code) ?? stateError?.message);
        errorModal.onOpen();
        return;
      }
      if ((status === 'success' || status === 'error') && options.notify) {
        const {
          operation,
          label,
          error = defaultError,
          success = defaultSuccess,
          options: notifyOptions = {},
        } = options.notify;

        const context = { operation, label };

        let title = '';

        if (status === 'success') {
          title = success(data, variables, context);
        } else if (isFunction(error)) {
          title = error(state.error, variables, context);
        }

        if (title) {
          ToastNotify({
            status,
            title,
            ...notifyOptions,
          });
        }
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
