import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import ROUTES from '@app/utils/routes';
import { TrialModalType } from '@app/contexts/PopupsContext';

import { TrialLimitsPopupProps } from './types';

const SendButton: FC<TrialLimitsPopupProps> = ({ type, onClose }) => {
  const history = useHistory();
  const title = useMemo(() => {
    if (type === TrialModalType.NO_PHONES) {
      return 'Phone Setup';
    }
    if (type === TrialModalType.USED) {
      return 'Subscribe';
    }
    return 'Ok';
  }, [type]);

  const onClick = () => {
    if (type === TrialModalType.NO_PHONES) {
      history.push(`${ROUTES.settings}/phone-setup`);
    }
    if (type === TrialModalType.USED) {
      history.push(`${ROUTES.settings}/membership`);
    }
    onClose();
  };

  return (
    <Button
      minW={type === TrialModalType.NO_PHONES ? '145px' : '120px'}
      variant="primary"
      onClick={onClick}>
      {title}
    </Button>
  );
};

export default SendButton;
