import React, { FC } from 'react';
import { Box, Text, UseDisclosureProps, Button, Link } from '@chakra-ui/react';
import { isAndroid } from 'react-device-detect';
import Popup from '@app/components/Popup';

interface BottomPopupPropsTypes {
  position: 'fixed';
  bottom: string;
  mb: string;
}

const bottomPopupProps = {
  position: 'fixed',
  bottom: '0px',
  mb: '0',
};

const appStore = 'https://apps.apple.com/us/app/smarter-contact/id1637673379';
const googlePlayMarket =
  'https://play.google.com/store/apps/details?id=com.smartercontactapp';

const MobileAlertPopup: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const appLink = isAndroid ? googlePlayMarket : appStore;

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon
      bottomPopupProps={bottomPopupProps as BottomPopupPropsTypes}
      isOpen={isOpen}
      maxW="100%"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onClose}>
      <Box p="0 40px 40px">
        <Box
          background="secondary.200"
          borderRadius="16px"
          h="6px"
          m="20px auto 0px"
          w="36px"
        />
        <Box>
          <Text
            color="main.400"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="-0.5px"
            m="32px auto 20px"
            textAlign="center">
            Did you know we have a mobile app? Dowload it here
          </Text>
        </Box>
        <Box lineHeight="22px" m="10px auto" maxW="369px" textAlign="center">
          <Button
            isExternal
            as={Link}
            borderRadius="16px"
            boxShadow="0px 10px 20px rgba(37, 116, 255, 0.3)"
            h="50px"
            href={appLink}
            variant="primary"
            w="100%"
            onClick={onClose}>
            Continue
          </Button>
          <Button height="40px" variant="cancel" width="100%" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};

export default MobileAlertPopup;
