import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const PageViewLogger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {}, [location.pathname]);

  return null;
};

export default PageViewLogger;
