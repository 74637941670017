import React, { memo } from 'react';

const NotificationIcon = () => {
  return (
    <svg
      fill="none"
      height="20px"
      viewBox="0 0 20 20"
      width="20px"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.013 20a3.128 3.128 0 01-3.125-3.125.625.625 0 011.25 0c0 1.034.84 1.875 1.875 1.875 1.034 0 1.875-.84 1.875-1.875a.625.625 0 011.25 0A3.128 3.128 0 0110.013 20z"
        fill="#8F9EAF"
      />
      <path
        d="M16.888 17.5H3.138a1.46 1.46 0 01-.948-2.567 5.584 5.584 0 001.99-4.276V8.333A5.84 5.84 0 0110.013 2.5a5.84 5.84 0 015.833 5.833v2.324c0 1.649.723 3.206 1.982 4.27a1.458 1.458 0 01-.94 2.573zM10.013 3.75A4.588 4.588 0 005.43 8.333v2.324a6.83 6.83 0 01-2.425 5.225.207.207 0 00-.075.16.21.21 0 00.208.208h13.75a.21.21 0 00.208-.208.206.206 0 00-.073-.159 6.832 6.832 0 01-2.427-5.226V8.333a4.588 4.588 0 00-4.583-4.583z"
        fill="#8F9EAF"
      />
    </svg>
  );
};

const MemoNotificationIcon = memo(NotificationIcon);
export default MemoNotificationIcon;
