import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Plivo from 'plivo-browser-sdk';
import { Device, Call as TwilioCall } from '@twilio/voice-sdk';

import DialerContext from '@app/contexts/DialerContext';
import { Call, DialerStatus } from '@app/types/Dialer';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { MessagingProviderType } from '@app/api/queries/types';

export const DialerProvider: FC = ({ children }) => {
  const { messaging } = useCurrentAccountData();
  const plivoRef = useRef<Plivo>(null);
  const twilioRef = useRef<Device>(null);
  const twilioCallInstance = useRef<TwilioCall>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentCallDetails, setCurrentCallDetails] = useState<Call>(null);
  const [dialerStatus, setDialerStatus] = useState<DialerStatus>(
    DialerStatus.IDLE,
  );
  const [isMicMuted, setIsMicMuted] = useState(false);

  const toggleMic = useCallback(() => {
    if (messaging?.providerType === MessagingProviderType.PLIVO) {
      if (isMicMuted) {
        plivoRef.current.client.unmute();
      } else {
        plivoRef.current.client.mute();
      }
    }

    if (messaging?.providerType === MessagingProviderType.TWILIO) {
      if (isMicMuted) {
        twilioCallInstance.current.mute(false);
      } else {
        twilioCallInstance.current.mute(true);
      }
    }

    setIsMicMuted((prevState) => !prevState);
  }, [isMicMuted, messaging?.providerType]);

  const providerValue = useMemo(
    () => ({
      plivoInstance: plivoRef,
      twilioInstance: twilioRef,
      twilioCallInstance,
      isMicMuted,
      isLoggedIn,
      currentCall: currentCallDetails,
      status: dialerStatus,
      toggleMic,
      setCurrentCallDetails,
      setIsLoggedIn,
      setDialerStatus,
      providerType: messaging?.providerType,
    }),
    [
      isMicMuted,
      isLoggedIn,
      currentCallDetails,
      dialerStatus,
      toggleMic,
      messaging?.providerType,
    ],
  );

  return (
    <DialerContext.Provider value={providerValue}>
      {children}
    </DialerContext.Provider>
  );
};

export const useDialerContext = () => {
  const context = useContext(DialerContext);

  return context;
};
