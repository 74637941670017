import { useFunctions } from 'reactfire';
import { UseBaseQueryOptions } from 'react-query';
import { collection, limit, orderBy, query } from 'firebase/firestore';
import { useMemo } from 'react';

import useCurrentAccount from '@app/hooks/useCurrentAccount';

import { useFunctionsSearchInfinityQuery } from '../hooks/useFunctionsSearchInfinityQuery';

import { SearchInboxQueryParams, SearchInboxItem } from './types';

export const useSearchInboxQueryData = (
  params: SearchInboxQueryParams,
  options?: UseBaseQueryOptions,
) => {
  const functions = useFunctions();
  const account = useCurrentAccount();

  const subscriptionQuery = useMemo(
    () =>
      query(
        collection(account.ref, 'contacts'),
        orderBy('lastMessageAt', 'desc'),
        limit(1),
      ),
    [account],
  );

  return useFunctionsSearchInfinityQuery<
    SearchInboxQueryParams,
    SearchInboxItem
  >(
    ['search-inbox', params],
    functions,
    'search-v2-inbox',
    params,
    options.refetchInterval
      ? null
      : {
          subscriptionQuery,
        },
    { ...options, keepPreviousData: true },
  );
};
