import React, { FC } from 'react';
import { Button, Container, Stack, Text } from '@chakra-ui/react';

import BgSignUp from '@app/icons/bg-sign-up.svg';
import { ReactComponent as OfflineBackground } from '@app/icons/image-offline.svg';

const OfflineMode: FC = () => (
  <Container
    backgroundColor="white"
    backgroundImage={`url(${BgSignUp})`}
    backgroundPosition="bottom"
    backgroundRepeat="no-repeat"
    height="100vh"
    maxWidth="100%"
    overflow="hidden">
    <Stack
      alignItems="center"
      direction="column"
      height="80%"
      justifyContent="center">
      <OfflineBackground />
      <Text color="darkCharcoal" fontSize="24px" mt="26px">
        No internet connection available
      </Text>
      <Button variant="outlined" onClick={() => window.location.reload()}>
        Refresh page
      </Button>
    </Stack>
  </Container>
);

export default OfflineMode;
