import React, { FC } from 'react';
import {
  Box,
  Flex,
  Switch,
  Text,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
} from '@chakra-ui/react';

import NotificationIcon from '../../../icons/NotificationIcon';
import ScheduleIcon from '../../../icons/ScheduletIcon';
import { DividerContainer } from '../../styles';

const NotificationPopover: FC = () => {
  return (
    <Popover isLazy matchWidth>
      <PopoverTrigger>
        <Box mr="30px">
          <Box cursor="pointer" data-cy="notification-bell-button">
            <NotificationIcon />
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent className="popover-arrow-help-support">
        <PopoverArrow />
        <Box
          backgroundColor="white"
          borderRadius="5px"
          data-cy="notifications-pop-up-window"
          filter="drop-shadow(0px 4px 30px rgba(94, 94, 94, 0.25))"
          height="auto">
          <Flex direction="column">
            <DividerContainer direction="column" p="14px">
              <Box mb="14px">
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between">
                  <Text
                    as="span"
                    color="secondary.600"
                    fontSize="16px"
                    fontWeight="bold">
                    Notifications
                  </Text>

                  <Text color="#b4bdc8" fontSize="12px" lineHeight="14px">
                    6 new
                  </Text>
                </Flex>
              </Box>
            </DividerContainer>

            <Flex
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              pl="18px"
              pr="18px">
              <Box>
                <ScheduleIcon />
              </Box>

              <Flex direction="column" pl="12px" pr="12px">
                <Text color="#323e4a" fontSize="14px" lineHeight="16px">
                  Scheduled Campaign
                </Text>
                <Text color="#475f7b" fontSize="12px" lineHeight="14px">
                  USA Server is down due you campaign
                </Text>
              </Flex>

              <Box>
                <Text color="#c5cdd6" fontSize="12px" lineHeight="14px">
                  12:00
                </Text>
              </Box>
            </Flex>
            <Flex p="14px">
              <Box
                backgroundColor="#f3f3f3"
                height="1px"
                opacity="0.5"
                width="100%"
              />
            </Flex>

            <Flex
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              p="14px">
              <Text
                color="#b4bdc8"
                fontSize="12px"
                id="show-notifications-settings"
                lineHeight="14px">
                Show notifications settings
              </Text>

              <Box>
                <Switch colorScheme="primary.500" size="sm" />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationPopover;
