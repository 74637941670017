import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { useDefaultLayoutInMobile } from '@app/navigation/components/DefaultLayout/useDefaultLayoutInMobile';

const DefaultLayout: FC = ({ children }) => {
  const defaultLayoutInMobile = useDefaultLayoutInMobile();

  if (!defaultLayoutInMobile && isMobile) {
    return <Flex backgroundColor="white">{children}</Flex>;
  }

  return (
    <Flex
      backgroundColor="white"
      borderRadius="18px"
      direction="row"
      height="calc(100vh - 90px)"
      mb="20px"
      overflow="hidden"
      position="relative"
      sx={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
      }}
      width="100%">
      {children}
    </Flex>
  );
};

export default DefaultLayout;
