import React, { useCallback, useMemo } from 'react';
import {
  Flex,
  Box,
  Text,
  chakra,
  Avatar,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
  Divider,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';

import { useSignOut } from '@app/hooks/useSignOut';
import useUserPhoto from '@app/hooks/useUserPhoto';
import LogOutIcon from '@app/icons/LogOutIcon';
import UserIcon from '@app/icons/UserIcon';
import ROUTES from '@app/utils/routes';
import { PopoverLinkProfile } from '@app/navigation/styles';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { usePlansData } from '@app/api/queries/usePlansData';

const IconUser = chakra(UserIcon);

const AvatarPopover = () => {
  const history = useHistory();
  const { isEnterprisePlan } = usePlanPermission();

  const { data: user } = useUserPhoto();
  const account = useCurrentAccountData();
  const { data: plans } = usePlansData();

  const fullName = useMemo(
    () => `${account?.firstName} ${account?.lastName}`,
    [account.firstName, account.lastName],
  );

  const currentPlan = useMemo(
    () => plans?.find((plan) => plan.ref.path === account?.plan?.path),
    [plans, account?.plan?.path],
  );

  const photoURL = useMemo(() => user?.photoURL, [user?.photoURL]);

  const { redirectToLogout } = useSignOut();

  const handleProfileSettings = useCallback(() => {
    history.push(`${ROUTES.settings}/profile`);
    //eslint-disable-next-line
  }, []);

  return (
    <Popover matchWidth placement="bottom-end">
      <PopoverTrigger>
        <Box cursor="pointer">
          <Avatar h="40px" name={fullName?.trim()} src={photoURL} w="40px" />
        </Box>
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: '0 none' }} border="0" width="240px">
        <PopoverArrow boxShadow="0 0 0 transparent!important" zIndex="2" />
        <Box
          backgroundColor="white"
          borderRadius="5px"
          data-cy="avatar-popover-window"
          filter="drop-shadow(0px 4px 30px rgba(94, 94, 94, 0.25))"
          zIndex="10000000">
          <Flex direction="column">
            <Flex
              direction="column"
              justifyContent="center"
              my="18px"
              textAlign="center"
              width="100%">
              <Text
                color="secondary.600"
                fontSize="14px"
                fontWeight={500}
                lineHeight="16px"
                mb="2px">
                {fullName}
              </Text>

              <Text
                color="secondary.300"
                fontSize="12px"
                fontWeight={400}
                lineHeight="14px"
                textTransform="capitalize">
                {isEnterprisePlan ? 'Enterprise' : currentPlan?.title} plan
              </Text>
            </Flex>
            <Divider opacity="0.8" />
            <Box p="16px 20px 2px">
              <Flex direction="column">
                <PopoverLinkProfile onClick={handleProfileSettings}>
                  <Flex alignItems="center">
                    <Box data-cy="my-profile-button" mr="10px">
                      <IconUser color="main.400" />
                    </Box>
                    My Profile
                  </Flex>
                </PopoverLinkProfile>
                <PopoverLinkProfile onClick={redirectToLogout}>
                  <Flex alignItems="center">
                    <Box data-cy="sign-out-button" mr="10px">
                      <LogOutIcon />
                    </Box>
                    Sign Out
                  </Flex>
                </PopoverLinkProfile>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default AvatarPopover;
