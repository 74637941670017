const video = {
  calendar: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
  messengerAll: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
  contacts: {
    all: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
    group: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
    phoneVerification: 'https://youtu.be/MRoGRFalSQ8',
    noData: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
  },
  campaigns: {
    viewAll: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
    messageTemplate:
      'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
    createConfirmation:
      'https://www.youtube.com/watch?v=3yAO1UOXdGU&ab_channel=SmarterContact',
    messageTemplatesPreview: 'https://www.youtube.com/watch?v=O9_G1BNCPTA',
  },
  settings: {
    phoneSetup: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
    api: 'KD-c5rvk0CA',
    trial: {
      explain: 'https://rumble.com/v1ksian-sending-sms-during-your-trial.html',
    },
  },
  macros: {
    emptyState: 'O9_G1BNCPTA',
  },
  skiptrace: {
    emptyState: '-UospylA_HQ',
  },
  questionnaire: 'https://www.loom.com/embed/c8bfc8b53e5642aa8d06dcf87a794239',
  affiliate: {
    program: 'kByb5hWviMk',
  },
  welcomeVideo: 'alg5R8EsPoU',
  newRelease: 'https://www.loom.com/embed/30103c1ea6cc457d846592bb1a90efff',
};
export default video;
