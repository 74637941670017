import React, { memo } from 'react';

const LogOutIcon = () => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14H3.333A1.334 1.334 0 012 12.667V3.333A1.333 1.333 0 013.333 2H6M10.667 11.333L14 8l-3.333-3.333M14 8H6"
        stroke="#323E4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const MemoLogOutIcon = memo(LogOutIcon);
export default MemoLogOutIcon;
