import React, { FC } from 'react';
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  AnalyticsProvider,
  AppCheckProvider,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

import ROUTES from '@app/utils/routes';

const firebaseAppName = window.location.pathname.includes(
  `${ROUTES.impersonate}`,
)
  ? 'impersonate'
  : undefined;

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG,
) as FirebaseOptions;

const firebaseApp = initializeApp(firebaseConfig, firebaseAppName);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_FIREBASE_APP_CHECK_RECAPTCHA,
  ),
  isTokenAutoRefreshEnabled: true,
});

const authInstance = getAuth(firebaseApp);
const storageInstance = getStorage(firebaseApp);

const firestoreInstance = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

const functionsInstance = getFunctions(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);

const emulatorEnabled = process.env.NODE_ENV === 'test';

if (emulatorEnabled) {
  connectAuthEmulator(authInstance, 'http://localhost:9099');
  connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
  connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
  connectStorageEmulator(storageInstance, 'localhost', 9199);
}

const FirebaseProvider: FC = ({ children }) => (
  <FirebaseAppProvider firebaseApp={firebaseApp}>
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={authInstance}>
        <FirestoreProvider sdk={firestoreInstance}>
          <StorageProvider sdk={storageInstance}>
            <FunctionsProvider sdk={functionsInstance}>
              <AnalyticsProvider sdk={analyticsInstance}>
                {children}
              </AnalyticsProvider>
            </FunctionsProvider>
          </StorageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </AppCheckProvider>
  </FirebaseAppProvider>
);

export default FirebaseProvider;
