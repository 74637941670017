import React, { FC } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import FeedbackScore1 from '@app/assets/images/feedback/1.png';
import FeedbackScore2 from '@app/assets/images/feedback/2.png';
import FeedbackScore3 from '@app/assets/images/feedback/3.png';
import FeedbackScore4 from '@app/assets/images/feedback/4.png';
import FeedbackScore5 from '@app/assets/images/feedback/5.png';

import { CustomerFeedbackValues } from './types';

interface FeedbackControlProps {
  value: number;
}

const IconMap: {
  [key: number]: string;
} = {
  1: FeedbackScore1,
  2: FeedbackScore2,
  3: FeedbackScore3,
  4: FeedbackScore4,
  5: FeedbackScore5,
};

const TitleMap: {
  [key: number]: string;
} = {
  1: 'Terrible',
  2: 'Bad',
  3: 'Normal',
  4: 'Good',
  5: 'Awesome',
};

export const FeedbackControl: FC<FeedbackControlProps> = ({ value }) => {
  const {
    values: { score },
    setFieldValue,
  } = useFormikContext<CustomerFeedbackValues>();

  const handleClick = () => {
    setFieldValue('score', value);
  };

  const isSelected = score === value;

  return (
    <Box
      _last={{
        marginRight: 0,
      }}
      cursor="pointer"
      marginRight="15px"
      textAlign="center"
      onClick={handleClick}>
      <Flex
        alignItems="center"
        backgroundColor={isSelected ? 'primary.600' : 'aliceBlueLight'}
        borderRadius="35px"
        height="60px"
        justifyContent="center"
        marginBottom="5px"
        transition="all 100ms ease-in"
        width="60px">
        <Image height="37.85px" src={IconMap[value]} width="35px" />
      </Flex>
      <Text fontSize="12px" fontWeight={isSelected ? 700 : 400}>
        {TitleMap[value]}
      </Text>
    </Box>
  );
};
