import React, { FC, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ApprovedImg from '@app/assets/images/youtube_A2P10DLC_approved.png';
import ROUTES from '@app/utils/routes';

import Popup from '../Popup';

import Player from './Player';

const videoId = '9Vv4tnYxwhg';

const PendingBrandModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();

  const goToPage = useCallback(() => {
    onClose();
    history.push(`${ROUTES.settings}/dlc-registration`);
  }, [history, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="455px"
      title="We’re getting your account approved.."
      onClose={onClose}>
      <Box p="20px 40px 40px">
        <Box m="0 0 25px" position="relative">
          <Player
            image={<Image minHeight="211px" src={ApprovedImg} />}
            video={videoId}
          />
        </Box>
        <Text textAlign="center" variant="list-style">
          Our team is getting your business registered and we’re awaiting final
          approval. Once approved you’ll be able to send SMS immediately 🚀
        </Text>
        <Text mt="10px" textAlign="center" variant="list-style">
          You can also view your account status in the{' '}
          <Button variant="link" onClick={goToPage}>
            Settings menu
          </Button>
        </Text>
        <Flex justifyContent="center" mt="25px">
          <Button variant="primary" w="145px" onClick={onClose}>
            Close
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default PendingBrandModal;
