import styled from '@emotion/styled';
import React from 'react';

const Icon = styled.div`
  position: relative;
  top: -3px;
  left: 0;
  width: 20px;
  height: 20px;
  transform: rotate(-44deg);

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    left: 0;
    width: 2px;
    height: 0;
    background-color: #4f8ced;
    border-radius: 1px 1px 0 1px;
    animation-name: checkAnimationBefore;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 3px);
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4f8ced;
    border-radius: 1px;
    animation-delay: 0.1s;
    animation-name: checkAnimationAfter;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
  @keyframes checkAnimationBefore {
    from {
      height: 0;
    }
    to {
      height: 10px;
    }
  }

  @keyframes checkAnimationAfter {
    from {
      width: 0;
    }
    to {
      width: 20px;
    }
  }
`;

const CheckIconAnimated = () => {
  return <Icon></Icon>;
};

export default CheckIconAnimated;
