import React, { memo } from 'react';

const ScheduletIcon = () => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9 12a6.9 6.9 0 11-13.8 0 6.9 6.9 0 0113.8 0z"
        stroke="#475F7B"
        strokeLinecap="round"
        strokeWidth={8.2}
      />
      <path
        d="M12 5v7l4 4"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.2}
      />
    </svg>
  );
};

const MemoScheduletIcon = memo(ScheduletIcon);
export default MemoScheduletIcon;
