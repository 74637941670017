import isEmpty from 'lodash/isEmpty';
import { matchPath } from 'react-router-dom';

import Bootstrap from '@app/types/Bootstrap';
import ROUTES, { PUBLIC_ROUTES } from '@app/utils/routes';

enum SearchParams {
  OAUTH = 'code',
  ResetPassword = 'resetPassword',
  verifyEmail = 'verifyEmail',
}

const knownRoutes = Object.values(ROUTES);

export const getInitialRoute = ({ account, registered }: Bootstrap) => {
  // eslint-disable-next-line no-restricted-globals
  const { pathname, search } = location;
  const params = new URLSearchParams(search);

  // Redirect user to OAUTH
  if (params.get('response_type') === SearchParams.OAUTH) {
    if (!registered) {
      return `${ROUTES.login}/${search}`;
    }
    return `${ROUTES.oauth}/${search}`;
  }

  // Redirect user to change password
  if (params.get('mode') === SearchParams.ResetPassword) {
    return `${ROUTES.changePassword}/${search}`;
  }

  // Skip public page
  if (!registered && PUBLIC_ROUTES.includes(pathname)) {
    return pathname + search;
  }

  if (!registered) {
    return ROUTES.login;
  }

  // Redirect user to questionnaire
  if (registered && !account) {
    return ROUTES.questionnaire;
  }

  // Redirect user to questionnaire
  if (registered && account && isEmpty(account.data().questionnaire)) {
    return ROUTES.questionnaire;
  }

  // Redirect from questionnaire to messenger
  if (pathname === ROUTES.questionnaire) {
    return ROUTES.messenger;
  }

  // Set redirect after login
  if (
    [ROUTES.login, ROUTES.signUp, '/'].includes(pathname) &&
    registered &&
    account
  ) {
    return ROUTES.messenger;
  }

  // Redirect user to messenger after verify email
  if (
    params.get('mode') === SearchParams.verifyEmail &&
    registered &&
    account
  ) {
    return ROUTES.messenger;
  }

  if (
    matchPath(pathname, {
      path: knownRoutes,
    })
  ) {
    return pathname + search;
  }

  return ROUTES.notFound;
};
