import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import ROUTES from '@app/utils/routes';
import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

const AdminRouteGuard: FC<RouteProps> = ({ children, ...rest }) => {
  const { isAdmin } = useContext(CurrentAccountContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.messenger,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRouteGuard;
