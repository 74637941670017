import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import Popup from '@app/components/Popup';
import ROUTES from '@app/utils/routes';
import { useAccountMutation } from '@app/api/mutations/useAccountMutation';
import WelcomePopupImage from '@app/assets/images/welcome_popup.png';
import WelcomePopupImageSecStep from '@app/assets/images/welcome_popup_sec_step.png';
import Player from '@app/components/10Dlc/Player';
import video from '@app/utils/videoLinks';

const FIRST_STEP = 0;
const SECOND_STEP = 1;

const WelcomePopup: FC<Partial<UseDisclosureProps>> = ({ isOpen, onClose }) => {
  const accountMutation = useAccountMutation();
  const history = useHistory();

  const [isActiveStep, setActiveStep] = useState<number>(FIRST_STEP);

  const onSubmitPopup = useCallback(async () => {
    await accountMutation.mutateAsync({
      notification: {
        firstLogin: true,
      },
    });
    history.push({
      pathname: `${ROUTES.settings}/membership`,
      state: { showMobileModal: true },
    });

    onClose();
  }, [accountMutation, history, onClose]);

  const stepAction = useCallback(() => {
    return isActiveStep === FIRST_STEP
      ? setActiveStep(SECOND_STEP)
      : onSubmitPopup();
  }, [isActiveStep, onSubmitPopup]);

  return (
    <Popup
      closeOnOverlayClick={false}
      hideCloseIcon={isActiveStep === FIRST_STEP}
      isOpen={isOpen}
      maxW="630px"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="40px">
        <Flex justifyContent="center">
          <Text
            color="main.400"
            fontSize="22px"
            fontWeight="500"
            lineHeight="26px"
            mb="25px">
            Welcome to Smarter Contact
          </Text>
        </Flex>
        {isActiveStep === FIRST_STEP ? (
          <Box mb="25px">
            <Player
              height="305px"
              image={<Image src={WelcomePopupImage} />}
              video={video.welcomeVideo}
              width="545px"
            />
          </Box>
        ) : (
          <>
            <Image pt="5px" src={WelcomePopupImageSecStep} />
            <Flex alignItems="center" direction="column" mt="17px">
              <Text
                color="main.400"
                fontSize="14px"
                lineHeight="19px"
                maxW="512px"
                textAlign="center">
                The first step to set up your account will be to go to update
                your info in the{' '}
                <Button variant="link" onClick={onSubmitPopup}>
                  membership page
                </Button>
                .
              </Text>
              <Box maxW="500px" my="25px" textAlign="center">
                <Text
                  as="span"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px">
                  Start your trial today and we`ll match up to $10 on your first
                  deposit,{' '}
                </Text>
                <Text as="span" fontSize="14px">
                  getting you off to a running start 💪
                </Text>
              </Box>
            </Flex>
          </>
        )}
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          mb="30px">
          {Array(2)
            .fill(0)
            .map((_, i) => (
              <Box
                key={`step-${i}`}
                background={isActiveStep === i ? '#4F8CED' : '#D2D2D2'}
                borderRadius="50px"
                display="block"
                height="7px"
                margin="0 5px"
                width="7px"
              />
            ))}
        </Flex>

        <Flex
          flexDirection="row"
          justifyContent="space-around"
          m="0 auto"
          w="275px">
          <Button
            disabled={accountMutation.isLoading}
            variant="primary"
            w="150px"
            onClick={stepAction}>
            {isActiveStep === FIRST_STEP ? 'Next' : 'Close'}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default WelcomePopup;
