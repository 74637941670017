import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useRouteMatch } from 'react-router';
import { isMobile } from 'react-device-detect';
import ActivateAccountPopup from '@app/components/ActivateAccountPopup';
import { RegisterBrandModal } from '@app/components/10Dlc';
import PendingBrandModal from '@app/components/10Dlc/PendingBrandModal';
import TrialStatusModal from '@app/components/10Dlc/TrialStatusModal';
import ErrorModal from '@app/components/ErrorModal';
import { NewVersionModal } from '@app/components/NewVersionModal';
import ConfirmAlert from '@app/components/ConfirmAlert';
import { usePopupsProvider } from '@app/hooks/usePopupsProvider';
import { AccountStatus } from '@app/api/queries/types';
import {
  FirstDayTrialPopup,
  SecondDayTrialPopup,
  SmsLimitPopup,
  WelcomePopup,
  MobileAlertPopup,
} from '@app/components/BasicPopups';
import PopupsContext, {
  ConfirmStateType,
  TrialModalType,
} from '@app/contexts/PopupsContext';
import { CustomerFeedbackModal } from '@app/components/Modals/CustomerFeedbackModal';
import { ReferralPromoModal } from '@app/components/Modals/ReferralPromoModal';
import TrialLimitsPopup from '@app/components/BasicPopups/TrialLimitsPopup';
import PhoneFieldModal from '@app/pages/Settings/content/Profile/components/PhoneField/PhoneFieldModal';
import { useAccountMutation } from '@app/api/mutations/useAccountMutation';
import { UpdateBrandModal } from '@app/components/10Dlc/UpdateBrandModal';
import ROUTES from '@app/utils/routes';

import { CurrentAccountContext } from './CurrentAccountProvider';
interface PopupsProviderProps {
  children: React.ReactChild | React.ReactChild[];
}

type ModalType =
  | 'activateAccountModal'
  | 'dlcSubmitModal'
  | 'dlcPendingStatusModal'
  | 'trialStatusModal'
  | 'trialLimitModal'
  | 'errorModal'
  | 'oldUserWelcomeModal'
  | 'newVersionModal'
  | 'customerFeedbackModal'
  | 'referralPromoModal'
  | 'mobileAlertModal';

const initialConfirmState: ConfirmStateType = {
  title: '',
  description: null,
  confirmButtonText: '',
};

const SMS_LIMIT_START_PERCENT = 79;

const PopupsProvider: FC<PopupsProviderProps> = ({ children }) => {
  const {
    accountData,
    userPlan,
    percent,
    canWelcomePopup,
    messages,
    isToday,
    isNextDayAfterRegister,
    checkDaysSinceRegister,
  } = usePopupsProvider();

  const { mutateAsync } = useAccountMutation();
  const isMessengerPage = useRouteMatch(ROUTES.messenger);

  const { isImpersonate } = useContext(CurrentAccountContext);

  const activateAccountModal = useDisclosure();
  const dlcSubmitModal = useDisclosure();
  const dlcPendingStatusModal = useDisclosure();
  const dlcUpdateAccountModal = useDisclosure();
  const trialStatusModal = useDisclosure();
  const trialLimitModal = useDisclosure();
  const errorModal = useDisclosure();
  const confirmModal = useDisclosure();
  const smsLimitModal = useDisclosure();
  const welcomeModal = useDisclosure();
  const firstDayTrialModal = useDisclosure();
  const secondDayTrialModal = useDisclosure();
  const customerFeedbackModal = useDisclosure();
  const mobilePhoneModal = useDisclosure();
  const referralPromoModal = useDisclosure();
  const newReleaseModal = useDisclosure();
  const mobileAlertModal = useDisclosure();

  const checkIsAnyModalOpen = (currentModal: ModalType) => {
    const modals = {
      activateAccountModal: activateAccountModal.isOpen,
      dlcSubmitModal: dlcSubmitModal.isOpen,
      dlcPendingStatusModal: dlcPendingStatusModal.isOpen,
      trialStatusModal: trialStatusModal.isOpen,
      trialLimitModal: trialLimitModal.isOpen,
      errorModal: errorModal.isOpen,
      confirmModal: confirmModal.isOpen,
      smsLimitModal: smsLimitModal.isOpen,
      welcomeModal: welcomeModal.isOpen,
      firstDayTrialModal: firstDayTrialModal.isOpen,
      secondDayTrialModal: secondDayTrialModal.isOpen,
      customerFeedbackModal: customerFeedbackModal.isOpen,
      mobilePhoneModal: mobilePhoneModal.isOpen,
      referralPromoModal: referralPromoModal.isOpen,
      newReleaseModal: newReleaseModal.isOpen,
      mobileAlertModal: mobileAlertModal.isOpen,
    };

    return Object.keys(modals).some(
      (modal) => modals[modal] && modal !== currentModal,
    );
  };

  const handleModalClose = async (type: ModalType) => {
    if (type === 'newVersionModal') {
      // TODO: remove this after backend is released for new version modals
      Cookies.set('newVersionModal_release_2.3', 'true', {
        expires: 365,
      });
      newReleaseModal.onClose();
      return;
    }
    if (type === 'referralPromoModal') {
      referralPromoModal.onClose();
      await mutateAsync({
        notification: {
          popupReferralPromo: true,
        },
      });
      return;
    }
  };

  useEffect(() => {
    // Show new release modals after 24 hours
    if (
      isNextDayAfterRegister &&
      Cookies.get('newVersionModal_release_2.3') !== 'true'
    ) {
      newReleaseModal.onOpen();
    }

    if (!accountData?.notification?.firstLogin) {
      welcomeModal.onOpen();
      return;
    }

    if (
      !accountData?.notification?.popupTrialFirstDay &&
      accountData.status === AccountStatus.TRIAL
    ) {
      firstDayTrialModal.onOpen();
      return;
    }

    if (
      isToday &&
      !accountData?.notification?.popupTrialSecondDay &&
      accountData.status === AccountStatus.TRIAL
    ) {
      secondDayTrialModal.onOpen();
      return;
    }

    if (accountData?.notification?.feedbackRequired && !isImpersonate) {
      customerFeedbackModal.onOpen();
      return;
    }

    if (isMobile && !!messages?.pages?.length) {
      mobileAlertModal.onOpen();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages?.pages]);

  useEffect(() => {
    if (
      !accountData?.notification?.popupReferralPromo &&
      !checkIsAnyModalOpen('referralPromoModal') &&
      isMessengerPage?.path &&
      checkDaysSinceRegister(7)
    ) {
      referralPromoModal.onOpen();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessengerPage?.path]);

  useEffect(() => {
    if (
      percent > SMS_LIMIT_START_PERCENT &&
      !Cookies.get('show_sms_limit') &&
      !smsLimitModal.isOpen
    ) {
      smsLimitModal.onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  const [error, setError] = useState<string>(null);
  const [confirmModalState, setConfirmModalState] =
    useState<ConfirmStateType>(initialConfirmState);

  const [mobilePhoneCloseState, setMobilePhoneCloseState] = useState(true);

  const [trialModalState, setTrialModalState] = useState<TrialModalType>();

  const resolveRef = useRef<(value?: unknown) => void>();
  const rejectRef = useRef<(value?: unknown) => void>();

  const showConfirmPopup = (data: ConfirmStateType) => {
    return new Promise((resolve, reject) => {
      setConfirmModalState(data);
      confirmModal.onOpen();
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const showMobilePhonePopup = (canClose?: boolean) => {
    return new Promise((resolve, reject) => {
      setMobilePhoneCloseState(canClose);
      mobilePhoneModal.onOpen();
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const showTrialPopup = (type: TrialModalType) => {
    setTrialModalState(type);
    trialLimitModal.onOpen();
  };

  const closeConfirmPopup = () => {
    confirmModal.onClose();
  };

  const closeMobilePhoneModal = () => {
    mobilePhoneModal.onClose();
  };

  return (
    <PopupsContext.Provider
      value={{
        activateAccountModal,
        dlcSubmitModal,
        dlcPendingStatusModal,
        dlcUpdateAccountModal,
        trialStatusModal,
        errorModal,
        error,
        setError,
        confirmModal,
        showTrialPopup,
        showConfirmPopup,
        closeConfirmPopup,
        showMobilePhonePopup,
        closeMobilePhoneModal,
      }}>
      <>
        {children}
        <ActivateAccountPopup
          canClose={!accountData?.masterAccount}
          isOpen={activateAccountModal.isOpen}
          onClose={activateAccountModal.onClose}
        />
        <RegisterBrandModal
          isOpen={dlcSubmitModal.isOpen}
          onClose={dlcSubmitModal.onClose}
        />
        <PendingBrandModal
          isOpen={dlcPendingStatusModal.isOpen}
          onClose={dlcPendingStatusModal.onClose}
        />
        <UpdateBrandModal
          isOpen={dlcUpdateAccountModal.isOpen}
          onClose={dlcUpdateAccountModal.onClose}
        />
        <TrialStatusModal
          isOpen={trialStatusModal.isOpen}
          onClose={trialStatusModal.onClose}
        />
        <ErrorModal
          error={error}
          isOpen={errorModal.isOpen}
          onClose={errorModal.onClose}
        />
        <NewVersionModal
          isOpen={newReleaseModal.isOpen}
          onClose={() => handleModalClose('newVersionModal')}
        />
        <ConfirmAlert
          confirmText={confirmModalState?.confirmButtonText}
          description={confirmModalState?.description}
          handler={() => {
            resolveRef.current();
          }}
          isOpen={confirmModal.isOpen}
          title={confirmModalState?.title}
          verticalActions={confirmModalState?.verticalActions}
          onClose={() => {
            rejectRef.current();
            confirmModal.onClose();
          }}
        />
        {mobilePhoneModal.isOpen && (
          <PhoneFieldModal
            canClose={mobilePhoneCloseState}
            handler={() => {
              resolveRef.current();
              closeMobilePhoneModal();
            }}
            isOpen={mobilePhoneModal.isOpen}
            onClose={() => {
              rejectRef.current();
              closeMobilePhoneModal();
            }}
          />
        )}
        {smsLimitModal?.isOpen && (
          <SmsLimitPopup
            isOpen={smsLimitModal.isOpen}
            percent={percent}
            planName={userPlan}
            onClose={smsLimitModal.onClose}
          />
        )}
        {canWelcomePopup && welcomeModal?.isOpen && (
          <WelcomePopup
            isOpen={welcomeModal.isOpen}
            onClose={welcomeModal.onClose}
          />
        )}
        {firstDayTrialModal?.isOpen && (
          <FirstDayTrialPopup
            isOpen={firstDayTrialModal.isOpen}
            onClose={firstDayTrialModal.onClose}
          />
        )}
        {secondDayTrialModal?.isOpen && (
          <SecondDayTrialPopup
            isOpen={secondDayTrialModal.isOpen}
            onClose={secondDayTrialModal.onClose}
          />
        )}
        {customerFeedbackModal.isOpen && (
          <CustomerFeedbackModal
            isOpen={customerFeedbackModal.isOpen}
            onClose={customerFeedbackModal.onClose}
          />
        )}
        {trialLimitModal?.isOpen && (
          <TrialLimitsPopup
            isOpen={trialLimitModal.isOpen}
            type={trialModalState}
            onClose={trialLimitModal.onClose}
          />
        )}
        {referralPromoModal?.isOpen && (
          <ReferralPromoModal
            isOpen={referralPromoModal.isOpen}
            onClose={() => handleModalClose('referralPromoModal')}
          />
        )}
        {mobileAlertModal.isOpen && (
          <MobileAlertPopup
            isOpen={mobileAlertModal.isOpen}
            onClose={mobileAlertModal.onClose}
          />
        )}
      </>
    </PopupsContext.Provider>
  );
};

export default PopupsProvider;
