import { extendTheme } from '@chakra-ui/react';

import { components } from './components';

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  shadows: {
    outline: 'none',
  },
  colors: {
    transparent: 'transparent',
    white: '#fff',
    aliceBlueLight: '#F1F5FB',
    cultured: '#f5f5f5',
    athensGray: '#F5F7F9',
    catskillWhite: '#F3F6F9',
    zircon: '#F9FCFF',
    mystic: '#EAEDF3',
    gallery: '#F0F0F0',
    bombay: '#B0B6BD',
    silver: '#BCBCBC',
    americanSilver: '#D1D1D1',
    cadetBlue: '#ACB4BF',
    neutral50: '#8F9EAF',
    dustyGray: '#9B9B9B',
    mineShafft: '#3D3D3D',
    arsenic: '#323E4A',
    darkCharcoal: '#333333',
    osloGray: '#838A91',
    downy: '#68BACF',
    iceberg: '#67BACE',
    seaSerpent: '#61C0CC',
    mediumAquamarine: '#6FCF97',
    apple: '#55CD53',
    maximumYellowRed: '#F5BC4C',
    tartOrange: '#F54C4C',
    portlandOrange: '#FF5630',
    pressedAzure: '#1B65C8',
    royalBlue: '#2F80ED',
    azureHover: '#1F76EB', // Figma: Hover_Azure
    switchedBlue: {
      500: '#067EFE',
    },
    primary: {
      100: '#F1F5FB',
      200: '#E6EFFA', // Figma: Linen, Code: #E6EFFA
      300: '#A1CEFD',
      400: '#74B7FE',
      500: '#47A0FE',
      600: '#1A89FF', // Figma: Primary_Azure, Code: primary.600
      700: '#1F76EB',
      800: '#54A7FF',
    },
    secondary: {
      100: '#EAECEF',
      200: '#D8DDE2', // Figma Blue_Bayoux/Secondary 20, Code: secondary.200
      300: '#B4BDC8',
      400: '#8F9EAF', // Figma Bali_Hai, Code: secondary.400
      500: '#6B7E95',
      600: '#475F7B',
      700: '#8F9BB3',
    },
    main: {
      100: '#ABB0B5', // Figma: Arsenic 40
      300: '#5A646E', // Figma: Arsenic 80
      400: '#323E4A', // Figma: Arsenic
      500: '#5A607F',
    },
  },
  fontFamily: {
    sans: ['Roboto', 'sans-serif'],
  },
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
  },
  components,
});

export default theme;
