import { useMemo } from 'react';
import { useAuth } from 'reactfire';

export const usePhoneProvider = () => {
  const auth = useAuth();
  const phoneProvider = useMemo(
    () =>
      auth?.currentUser?.providerData?.find(
        (provider) => provider.providerId === 'phone',
      ) ?? null,
    [auth?.currentUser?.providerData],
  );

  return {
    auth,
    phoneProvider,
  };
};
