import React from 'react';
import { Box } from '@chakra-ui/react';

const envShotTitle = {
  development: 'DEV',
  staging: 'STG',
};

const EnvironmentLabel = () => {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <Box
      backgroundColor="red"
      borderRadius="13px"
      bottom="11px"
      color="white"
      fontSize="0.75rem"
      minWidth="30px"
      p="4px"
      position="absolute"
      right="-22px">
      {envShotTitle[process.env.NODE_ENV]}
    </Box>
  );
};

export default EnvironmentLabel;
