import { Timestamp, DocumentReference } from 'firebase/firestore';

import {
  AccountStatus,
  Campaign,
  DlcFormFields,
  Label,
  Messaging,
  Plan,
} from '@app/api/queries/types';

import { NotificationsData } from './Notifications';

export enum AccountClaimRole {
  ADMIN = 'admin',
  IMPERSONATE = 'impersonate',
}

export interface AccountCounters {
  smsCampaigns?: number;
  smsActiveCampaigns?: number;
  smsDraftCampaigns?: number;

  rvmCampaigns?: number;
  rvmActiveCampaigns?: number;
  rvmDraftCampaigns?: number;

  contactLists?: number;
  skiptraces?: number;

  dnc_suppressions?: number;
  response_filter_suppressions?: number;
  undeliverable_suppressions?: number;
  unsubscribed_suppressions?: number;
  crm_suppressions?: number;
}

export enum PlanPriceType {
  LEGACY = 'legacy',
  NEW = 'new',
  V2 = 'v2',
  ANNUAL_LEGACY = 'annualLegacy',
  ANNUAL_NEW = 'annualNew',
  ANNUAL_1 = 'annual1',
  CUSTOM_1 = 'custom_1',
  CUSTOM_2 = 'custom_2',
  CUSTOM_3 = 'custom_3',
}

export enum AccountType {
  REAL_ESTATE = 'real_estate',
  UNIVERSAL = 'universal',
}

export enum Trial {
  NOT_STARTED = 'not_started',
}

export enum Status {
  ACTIVE = 'active',
  TRIAL = 'trial',
  INACTIVE = 'inactive',
}

export interface BillingAccount {
  autoRecharge: boolean;
  balance?: number;
  low: number;
  toBalance: number;
  couponsApplied?: string[];
  trialStartedAt?: Timestamp;
  paidUntil?: Timestamp;
}

export default interface Account {
  id: string;
  messaging: Messaging;
  firstName: string;
  lastName: string;
  companyName: string;
  timezone?: string;
  uid: string;
  email: string;
  phone?: {
    activeIn?: string;
    monthAlertCount?: number;
    isSipEnabled?: boolean;
    alertFilter?: {
      labels?: DocumentReference<Label>[];
      groups?: DocumentReference<Label>[];
      campaigns?: DocumentReference<Campaign>[];
    };
  };
  plan: DocumentReference<Plan>;
  oldPlanType?: PlanPriceType;
  planType: PlanPriceType;
  billing: BillingAccount;
  userPhone: string;
  masterAccount: DocumentReference;
  trial: Trial | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: AccountStatus;
  counters: AccountCounters;
  headerNotifications: NotificationsData;
  questionnaire: Record<string, string>;
  monthCampaignSms?: number;
  labels?: {
    defaultColor: string;
  };

  accountType: AccountType;
  updateInterval: number;
  members: {
    [memberId: string]: string;
  };
  businessInfo: DlcFormFields;
  notification: Record<string, boolean>;
  isOnboarded: boolean;
  messengerNewTabOpen?: boolean;
  monthSms: number;
  isTest?: boolean;
}
