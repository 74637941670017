import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query';
import {
  Functions,
  httpsCallable,
  HttpsCallableOptions,
} from 'firebase/functions';
import { onSnapshot, Query, Unsubscribe } from 'firebase/firestore';
import { useEffect, useRef } from 'react';

import { DEFAULT_LIMIT } from '../queries/utils';

interface SearchResponse<T> {
  data: T[];
  meta: {
    total: number;
    nextCursor: number | null;
  };
}

export function useFunctionsSearchInfinityQuery<
  RequestData = unknown,
  ResponseData = unknown,
>(
  key: QueryKey,
  functions: Functions,
  trigger: string,
  params?: RequestData | null,
  options?: HttpsCallableOptions & {
    subscriptionQuery?: Query;
  },
  useQueryOptions?: Omit<
    UseInfiniteQueryOptions<
      SearchResponse<ResponseData>,
      Error,
      ResponseData[]
    >,
    'queryFn'
  >,
) {
  const queryResult = useInfiniteQuery<
    SearchResponse<ResponseData>,
    Error,
    ResponseData[]
  >({
    ...useQueryOptions,
    queryKey: useQueryOptions?.queryKey ?? key,
    async queryFn(ctx: QueryFunctionContext<QueryKey, number>) {
      const from = ctx.pageParam ?? 0;

      const { data } = await httpsCallable<
        RequestData,
        SearchResponse<ResponseData>
      >(
        functions,
        trigger,
        options,
      )({
        ...params,
        from,
        size: DEFAULT_LIMIT,
      });
      return data;
    },
    select(data) {
      return {
        pages: data.pages.map((page) => page.data),
        pageParams: data.pageParams,
      };
    },
    getNextPageParam(lastPage) {
      return lastPage.meta.nextCursor;
    },
  });

  const shouldTrack = useRef(true);
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;

    if (options?.subscriptionQuery) {
      unsubscribe = onSnapshot(options.subscriptionQuery, {
        next: () => {
          if (shouldTrack.current) {
            shouldTrack.current = false;
            return;
          }

          timeoutId.current = setTimeout(() => {
            // eslint-disable-next-line no-void
            void queryResult.refetch();
          }, 5000); // 5000 avg time for syncing firestore -> elastic
        },
      });
    }

    return () => {
      if (options?.subscriptionQuery) {
        unsubscribe?.();
      }

      clearTimeout(timeoutId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.subscriptionQuery]);

  return queryResult;
}
