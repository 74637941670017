import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { collection, query } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { Plan } from './types';
import { createConvertor } from './utils';

const convertor = createConvertor<Plan>();

export const usePlansData = () => {
  const firestore = useFirestore();

  const dataQuery = query(
    collection(firestore, 'plans').withConverter(convertor),
  );

  return useFirestoreQueryData<Plan>(['plans'], dataQuery, {
    subscribe: true,
  });
};
