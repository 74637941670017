import React, {
  ChangeEvent,
  useCallback,
  KeyboardEvent,
  Dispatch,
  FC,
} from 'react';
import { Flex } from '@chakra-ui/react';

import { PhoneActions } from '../reducer';

import { Code } from './Code';

interface VerifyPhoneType {
  verifyCode: string[];
  dispatch: Dispatch<PhoneActions>;
  verifyPhoneNumber: (code: string) => void;
}

const VerifyPhone: FC<VerifyPhoneType> = ({
  verifyCode,
  dispatch,
  verifyPhoneNumber,
}) => {
  const handleVerifyCodeChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newVerifyCode = [...verifyCode];
      newVerifyCode[index] = e.target.value;
      dispatch({ type: 'setCode', verifyCode: newVerifyCode });

      if (e.target.value.length === 2) {
        const nextElement = e.target.nextElementSibling as HTMLInputElement;

        if (nextElement) {
          nextElement.focus();
        } else {
          e.target.blur();
        }
      }

      const checkVerify = newVerifyCode.join('');

      if (checkVerify.length === 6) {
        void verifyPhoneNumber(checkVerify);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verifyCode],
  );

  const handleCodeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (
      ['Backspace', 'Delete'].includes(event.key) &&
      target.value.length === 0
    ) {
      const prevElement = target.previousElementSibling as HTMLInputElement;

      if (prevElement) {
        prevElement.focus();
      }
    }
  };

  const handleCodeKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const regex = /^[0-9]+$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    },
    [],
  );

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      mt="20px"
      width="320px">
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <Code
            key={i}
            id={`phone-verify-${i + 1}-input`}
            onChange={handleVerifyCodeChange(i)}
            onKeyDown={handleCodeKeyDown}
            onKeyPress={handleCodeKeyPress}
          />
        ))}
    </Flex>
  );
};

export default VerifyPhone;
