import ROUTES from './routes';

export enum AccountStatusModal {
  IS_INACTIVE = 'isInactive',
  IS_INACTIVE_PLAN = 'isInActivePlan',
  SECONDARY_ACCOUNT = 'secondaryAccount',
}

export interface AccountModalTextType {
  title: string;
  description: string;
  buttonText: string;
  route: string;
  modalWidth: number;
}

export const accountModalText = {
  isInactive: {
    title: 'Select your plan',
    description: 'Choose a plan to get started',
    buttonText: 'Choose a Plan',
    route: `${ROUTES.settings}/membership`,
    modalWidth: 320,
  },
  isInActivePlan: {
    title: 'Activate your subscription',
    description: 'To keep using Smarter Contact, activate the subscription',
    buttonText: 'Activate Subscription',
    route: `${ROUTES.settings}/membership`,
    modalWidth: 340,
  },
  secondaryAccount: {
    title: 'Notification',
    description:
      'You have Inactive status. Please contact your primary account.',
    buttonText: 'Close',
    route: ROUTES.messenger,
    modalWidth: 372,
  },
} as Record<AccountStatusModal, AccountModalTextType>;
