import { useEffect } from 'react';
import { User } from 'firebase/auth';
import { setUser } from '@sentry/react';

import { useTracking } from 'react-tracking';

export const useUserTracker = (user: User) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (user) {
      setUser({
        id: user.uid,
        email: user?.email,
        username: user?.displayName || 'No Name',
      });

      trackEvent({
        event: 'user_id',
        user_id: user.uid,
      });
    } else {
      setUser(null);
    }
  }, [trackEvent, user]);
};
