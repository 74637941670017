import { useMemo } from 'react';

import { PlanType } from '@app/api/queries/types';
import { PlanPriceType } from '@app/types/Account';
import { useMasterAccountData } from '@app/api/queries/useMasterAccountData';

import { useCurrentAccountData } from './useCurrentAccountData';

export type PlanPermissionType = Record<string, boolean>;

interface UsePlanPermissionProps {
  isStarterPlan: boolean;
  isNewStarter: boolean;
  isOldStarter: boolean;
  isPro: boolean;
  isNewPro: boolean;
  isOldPro: boolean;
  isElite: boolean;
  isNewElite: boolean;
  isOldElite: boolean;
  isAdvanced: boolean;
  isMiddleAdvanced: boolean;
  isAllNewPlans: boolean;
  isEnterprisePlan: boolean;
}

export const usePlanPermission = (): UsePlanPermissionProps => {
  const account = useCurrentAccountData();
  const { data } = useMasterAccountData();
  const currentAccountPlan = useMemo(
    () => (account?.masterAccount ? data?.plan?.path : account.plan?.path),
    [account?.masterAccount, account.plan, data?.plan],
  );
  const currentAccountPlanType = useMemo(
    () => (account?.masterAccount ? data?.planType : account.planType),
    [account?.masterAccount, account.planType, data?.planType],
  );
  const accountPlan = currentAccountPlan?.split('/')[1] as PlanType;

  const isOldStarter = useMemo(
    () => accountPlan === PlanType.STARTER,
    [accountPlan],
  );

  const isNewStarter = useMemo(
    () => accountPlan === PlanType.STANDARD,
    [accountPlan],
  );

  const isStarterPlan = useMemo(
    () => isOldStarter || isNewStarter,
    [isNewStarter, isOldStarter],
  );

  const isOldPro = useMemo(() => accountPlan === PlanType.PRO, [accountPlan]);
  const isNewPro = useMemo(() => accountPlan === PlanType.NINJA, [accountPlan]);
  const isPro = useMemo(() => isOldPro || isNewPro, [isNewPro, isOldPro]);

  const isNewElite = useMemo(
    () => accountPlan === PlanType.TOP_G,
    [accountPlan],
  );
  const isOldElite = useMemo(
    () => accountPlan === PlanType.ELITE,
    [accountPlan],
  );
  const isElite = useMemo(
    () => isNewElite || isOldElite,
    [isNewElite, isOldElite],
  );

  const isAdvanced = useMemo(() => isPro || isElite, [isElite, isPro]);

  const isMiddleAdvanced = useMemo(
    () => isElite || isNewPro,
    [isElite, isNewPro],
  );

  const isAllNewPlans = useMemo(
    () => isNewStarter || isNewPro || isNewElite,
    [isNewElite, isNewPro, isNewStarter],
  );

  const isEnterprisePlan = useMemo(
    () =>
      [
        PlanPriceType.CUSTOM_1,
        PlanPriceType.CUSTOM_2,
        PlanPriceType.CUSTOM_3,
      ].includes(currentAccountPlanType),
    [currentAccountPlanType],
  );

  return {
    isStarterPlan,
    isNewStarter,
    isOldStarter,
    isPro,
    isNewPro,
    isOldPro,
    isElite,
    isNewElite,
    isOldElite,
    isAdvanced,
    isMiddleAdvanced,
    isAllNewPlans,
    isEnterprisePlan,
  };
};
