import React from 'react';

import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';

const PreloadData = () => {
  useLabelsQueryData();
  return <></>;
};

export default PreloadData;
