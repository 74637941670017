import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { collection, query, orderBy } from 'firebase/firestore';

import useCurrentAccount from '../../hooks/useCurrentAccount';

import { Label } from './types';
import { createConvertor } from './utils';

const convertor = createConvertor<Label>();

export const useLabelsQueryData = () => {
  const account = useCurrentAccount();

  const dataQuery = query(
    collection(account.ref, 'labels').withConverter(convertor),
    orderBy('createdAt', 'desc'),
  );

  return useFirestoreQueryData<Label>(
    ['labels'],
    dataQuery,
    {
      subscribe: true,
    },
    {
      placeholderData: [],
    },
  );
};
