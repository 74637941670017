import React, { FC } from 'react';
import { Skeleton as S, Flex } from '@chakra-ui/react';

const Skeleton: FC = () => (
  <Flex
    alignItems="center"
    direction="column"
    height="100%"
    justifyContent="center"
    pb="10px"
    pt="20px"
    px={['5px', '20px']}
    zIndex="300">
    <S borderRadius="10px" h="40px" mb="20px" w="100%" />
    <S borderRadius="10px" flex="1" w="100%" />
  </Flex>
);

export default Skeleton;
