import { Box, chakra, Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const Link = chakra(NavLink, {
  baseStyle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#8F9EAF',
    textDecoration: 'none',
    width: '100px',

    '&.active': {
      color: '#323E4A',
      _after: {
        content: `""`,
        position: 'relative',
        top: '-10px',
        width: '0',
        height: '0',
        borderLeft: '85px solid transparent',
        borderRight: '85px solid transparent',
        borderBottom: '85px solid #fff',
        left: '50%',
        marginLeft: '-85px',
      },
    },
    '&.activeFull': {
      _after: {
        borderBottomColor: '#F9FCFF',
      },
    },
  },
});

const LinkSettings = chakra(NavLink, {
  baseStyle: {
    width: '20px',
    height: '20px',
    '&.active': {
      _after: {
        content: `""`,
        position: 'relative',
        top: '-10px',
        width: '0',
        height: '0',
        borderLeft: '35px solid transparent',
        borderRight: '35px solid transparent',
        borderBottom: '35px solid #fff',
        left: '50%',
        marginLeft: '-35px',
      },
    },
  },
});

const LinkArticle = chakra(NavLink, {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    color: '#1a89ff',
    marginBottom: '10px',
  },
});

const PopoverLinkProfile = chakra(Box, {
  baseStyle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#51606d',
    textDecoration: 'none',
    marginBottom: '18px',
    cursor: 'pointer',
  },
});

const LinkSubRoutes = chakra(NavLink, {
  baseStyle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: 'arsenic',
    textDecoration: 'none',
    cursor: 'pointer',
    letterSpacing: '0.1px',
    padding: '10px',

    '&.disabled': {
      color: 'americanSilver',
      pointerEvents: 'none',
    },

    '&.active': {
      color: 'azureHover',
      bg: 'primary.200',
      padding: '10px',
      borderRadius: '20px',
    },

    '&:hover': {
      color: 'azureHover',
    },
  },
});

const DividerContainer = chakra(Flex, {
  baseStyle: {
    '&::after': {
      content: '""',
      display: 'block',
      borderTop: '1px solid #f3f3f3',
      opacity: '0.5',
    },
  },
});

const HelpSupportVideoContainer = chakra(Box, {
  baseStyle: {
    '&::after': {
      content: '""',
      display: 'block',
      borderTop: '1px solid #f3f3f3',
      opacity: '0.5',
    },
  },
});

const SidebarLink = chakra(NavLink, {
  baseStyle: {
    fontSize: '14px',
    borderRadius: '0px 20px 20px 0px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '30px',
    fontWeight: 500,
    position: 'relative',
    color: '#8f9eaf',
    transition: '0.5s',

    '&.active': {
      background: '#f5faff',
      borderRadius: '0px 20px 20px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      paddingLeft: '30px',
      color: '#067efe',
      fontWeight: 500,
      position: 'relative',
      transition: '0.5s',

      _before: {
        borderRadius: '0px 100px 100px 0px',
        background: '#067efe',
        display: 'block',
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        height: '100%',
      },
    },
  },
});

const SideBarLinkSubMenu = chakra(NavLink, {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '0px 20px 20px 0px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '26px',
    fontWeight: 500,
    position: 'relative',
    color: '#8f9eaf',
    transition: '0.5s',
    margin: '7px 0 7px',

    p: {
      width: '155px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },

    '&:hover': {
      background: '#f5faff',
      cursor: 'pointer',
    },

    '&.active': {
      background: '#f5faff',
      borderRadius: '0px 20px 20px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      paddingLeft: '30px',
      color: '#067efe',
      fontWeight: 500,
      position: 'relative',
      transition: '0.5s',

      _before: {
        borderRadius: '0px 100px 100px 0px',
        background: '#067efe',
        display: 'block',
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        height: '40px',
        borderLeft: '2px solid transparent',
      },
    },
  },
});

export {
  Link,
  PopoverLinkProfile,
  LinkArticle,
  DividerContainer,
  HelpSupportVideoContainer,
  LinkSettings,
  SidebarLink,
  LinkSubRoutes,
  SideBarLinkSubMenu,
};
