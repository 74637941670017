import React, { memo } from 'react';

const VideoIcon = () => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 78 48"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <rect fill="#ECECF2" height={48} rx={1} width={78} />
      <path
        d="M41.639 23.687l-3.322-2.415a.4.4 0 00-.637.323v4.829a.4.4 0 00.637.322l3.322-2.412a.398.398 0 000-.646z"
        fill="#1A89FF"
      />
      <path
        d="M39 16.002A8 8 0 1039 32a7.999 7.999 0 100-15.998zm0 14.663a6.663 6.663 0 01-6.664-6.663A6.666 6.666 0 0139 17.337a6.665 6.665 0 010 13.328z"
        fill="#1A89FF"
      />
    </svg>
  );
};

const MemoVideoIcon = memo(VideoIcon);
export default MemoVideoIcon;
