import { createContext } from 'react';
import { UseDisclosureProps } from '@chakra-ui/react';

export type ConfirmStateType = {
  title: string;
  description: JSX.Element;
  confirmButtonText: string;
  verticalActions?: boolean;
};

export enum TrialModalType {
  LIMIT = 'limit',
  ATTENTION = 'attention',
  USED = 'used',
  NO_PHONES = 'noPhones',
}

export interface PopupsContextProps {
  activateAccountModal: UseDisclosureProps;
  dlcSubmitModal: UseDisclosureProps;
  dlcPendingStatusModal: UseDisclosureProps;
  dlcUpdateAccountModal: UseDisclosureProps;
  trialStatusModal: UseDisclosureProps;
  showTrialPopup: (type: TrialModalType) => void;
  errorModal: UseDisclosureProps;
  error: string;
  setError: (error?: string) => void;
  confirmModal: UseDisclosureProps;
  showConfirmPopup: (data: ConfirmStateType) => void | Promise<void | unknown>;
  closeConfirmPopup: () => void;
  showMobilePhonePopup: (canClose?: boolean) => void | Promise<void | unknown>;
  closeMobilePhoneModal: () => void;
}

const PopupsContext = createContext<PopupsContextProps>(null);

export default PopupsContext;
