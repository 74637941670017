import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import theme from './theme';
import initSentry from './setupSentry';
import initGTM from './setupGTM';
import initSourceBuster from './setupSourceBuster';
import UpdateApp from './components/UpdateApp';
import { collectClid } from './collect-clid';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  initSentry();
  initGTM();
  initSourceBuster();
  collectClid();
}

// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider portalZIndex={40} theme={theme}>
      <UpdateApp />
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
