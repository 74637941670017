import React, { useCallback, FC, useRef, useReducer, useMemo } from 'react';
import {
  Flex,
  Box,
  Text,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import {
  AuthErrorCodes,
  linkWithCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
  unlink,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { serverTimestamp } from 'firebase/firestore';

import PhoneInput from '@app/components/PhoneInput';
import { ReactComponent as WarningIcon } from '@app/icons/warning-circle-icon.svg';
import { catchErrorLog } from '@app/utils/logger';
import { useAccountMutation } from '@app/api/mutations/useAccountMutation';

import { initialState, phoneReducer } from './reducer';
import VerifyPhone from './VerifyPhone';
import { usePhoneProvider } from './hooks/usePhoneProvider';

interface PhoneFieldProps {
  canEdit?: boolean;
  isShowConfirmCheckbox?: boolean;
  placeholder?: string;
}

const PhoneField: FC<PhoneFieldProps> = ({
  canEdit = false,
  isShowConfirmCheckbox = false,
  placeholder = '(___) ___-____',
}) => {
  const [state, dispatch] = useReducer(phoneReducer, initialState);
  const { mutateAsync } = useAccountMutation();
  const recaptchaWrapperRef = useRef<HTMLDivElement | null>(null);

  const { auth, phoneProvider } = usePhoneProvider();

  const handlePhoneConfirm = useCallback(
    async () => {
      dispatch({ type: 'sentCode', isCodeSent: false });
      const recaptcha = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {},
        },
        auth,
      );
      const provider = new PhoneAuthProvider(auth);
      try {
        const verifyId = await provider.verifyPhoneNumber(
          `+1${state.phone}`,
          recaptcha,
        );
        dispatch({ type: 'sentCode', isCodeSent: true, verifyId });
      } catch (error) {
        let message = 'Something went wrong';
        if (error instanceof FirebaseError) {
          message = error.message;

          switch (error.code) {
            case AuthErrorCodes.INVALID_PHONE_NUMBER:
              message = 'Invalid phone number';
              break;
            case AuthErrorCodes.INVALID_CODE:
              message = 'Invalid verification code';
              break;
            case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
              message = 'Too many attempts, try again later';
              break;
            default:
              break;
          }
        }
        dispatch({ type: 'onError', error: message });
      } finally {
        recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
        recaptcha.clear();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  const verifyPhoneNumber = async (verifyCodeString: string) => {
    const phoneCredential = PhoneAuthProvider.credential(
      state.verifyId,
      verifyCodeString,
    );
    if (phoneProvider) {
      await unlink(auth.currentUser, phoneProvider.providerId);
    }
    await linkWithCredential(auth.currentUser, phoneCredential)
      .then(() => {
        dispatch({ type: 'setVerified' });
      })
      .catch((err) => {
        let message = 'Something went wrong';
        if (err instanceof FirebaseError) {
          message = err.message;
          switch (err.code) {
            case AuthErrorCodes.NEED_CONFIRMATION:
              message = 'Mobile number already exists in the system.';
              break;
            case AuthErrorCodes.INVALID_CODE:
              message = 'Wrong code. Try again.';
              break;
            default:
              break;
          }
        }
        dispatch({ type: 'onError', error: message });
      });
    await mutateAsync({
      updatedAt: serverTimestamp(),
    });
  };

  const handleResendCode = useCallback(async () => {
    dispatch({ type: 'sentCode', isCodeSent: true });
    try {
      await handlePhoneConfirm();
    } catch (error) {
      catchErrorLog(error, 'VerifyPhone/handleResendCode');
    }
  }, [handlePhoneConfirm]);

  const onConfirmPhone = useCallback(async () => {
    dispatch({ type: 'setLoading' });

    try {
      if (state.verifyId) {
        await handleResendCode();
        return;
      }
      await handlePhoneConfirm();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: 'setLoading' });
    }
  }, [handlePhoneConfirm, handleResendCode, state.verifyId]);

  const onChange = useCallback((phone: string) => {
    dispatch({ type: 'setPhone', phone });
  }, []);

  const errorPhone = useMemo(
    () => state.phone && !state.isCodeSent && state.error,
    [state],
  );
  const errorCode = useMemo(
    () => state.phone && state.isCodeSent && state.error,
    [state],
  );

  return (
    <>
      <Flex direction="column">
        <Box>
          <FormControl isInvalid={!!errorPhone}>
            <PhoneInput
              canEdit={canEdit && (state.edit || !!phoneProvider?.phoneNumber)}
              defaultValue={phoneProvider?.phoneNumber}
              isCodeSent={state.isCodeSent}
              isEditPhone={state.edit}
              isLoading={state.isLoading}
              isShowConfirmCheckbox={isShowConfirmCheckbox}
              placeholder={placeholder}
              status={state.status}
              value={state.phone}
              onChange={onChange}
              onConfirm={onConfirmPhone}
            />
            <FormErrorMessage pt="10px">
              <Text as="span" color="portlandOrange" mr="10px">
                <WarningIcon />
              </Text>{' '}
              {state.error}
            </FormErrorMessage>
          </FormControl>
        </Box>

        <div ref={recaptchaWrapperRef}>
          <Box id="recaptcha-container" width="100%" />
        </div>
        {state.isCodeSent && !state.verifiedPhone && (
          <>
            <FormControl isInvalid={!!errorCode}>
              <VerifyPhone
                dispatch={dispatch}
                verifyCode={state.verifyCode}
                verifyPhoneNumber={verifyPhoneNumber}
              />

              <FormErrorMessage pt="10px">
                <Text as="span" mr="10px">
                  <WarningIcon />
                </Text>
                {state.error}
              </FormErrorMessage>

              {!errorCode && (
                <Text
                  color="dustyGray"
                  fontSize="12px"
                  fontWeight="400"
                  id="enter-6-digit-code-text"
                  mt="10px"
                  textAlign="left">
                  Please enter the 6 digit verification code we sent you.
                </Text>
              )}
            </FormControl>
          </>
        )}
      </Flex>
    </>
  );
};

export default PhoneField;
