import React, { FC, useMemo } from 'react';
import {
  Box,
  Button,
  Center,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';

import Popup from '@app/components/Popup';

import PhoneField from '.';

import { usePhoneProvider } from './hooks/usePhoneProvider';

interface PhoneFieldModalProps extends UseDisclosureProps {
  canClose?: boolean;
  handler: () => void;
}

const PhoneFieldModal: FC<PhoneFieldModalProps> = ({
  canClose = true,
  handler,
  isOpen,
  onClose,
}) => {
  const { phoneProvider } = usePhoneProvider();
  const onCloseHandler = () => {
    if (phoneProvider) {
      handler();
    }
    if (!canClose) {
      return;
    }

    onClose();
  };
  const hideCloseIcon = useMemo(() => {
    if (phoneProvider) {
      return false;
    }
    return !canClose;
  }, [canClose, phoneProvider]);
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={hideCloseIcon}
      isOpen={isOpen}
      maxW="420px"
      title="Verification"
      titlePadding="40px 56px 25px"
      onClose={onCloseHandler}>
      <Box p="0 40px 40px">
        <Text mb="25px" textAlign="center" variant="heading-fifth">
          Please verify your mobile phone number.
        </Text>
        <PhoneField isShowConfirmCheckbox placeholder="Mobile number" />
        {!hideCloseIcon && (
          <Center>
            <Button
              mt="25px"
              variant="primary"
              w="145px"
              onClick={onCloseHandler}>
              Close
            </Button>
          </Center>
        )}
      </Box>
    </Popup>
  );
};

export default PhoneFieldModal;
