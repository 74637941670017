import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
  title?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} — ` : ''}Smarter Contact</title>
    </Helmet>
  );
};
