const primary = {
  control: {
    borderRadius: '4px',
    border: '1px',
    borderColor: 'silver',
    width: '14px',
    height: '14px',
    backgroundColor: 'white',
    _hover: {
      backgroundColor: 'white',
      borderColor: 'primary.600',
    },
    _checked: {
      backgroundColor: 'white',
      color: 'primary.600',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: 'white',
      },
    },
    _focus: {
      border: '1px solid primary.300',
      backgroundColor: 'zircon',
      boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    },
  },
  label: {
    fontSize: '14px',
    color: 'main.400',
  },
  icon: {
    height: '7px',
  },
};

const multiList = {
  container: {
    padding: '8px 12px',
    borderRadius: '20px',
    width: '100%',
    color: 'unset',
    _hover: {
      backgroundColor: '#E6EFFA',
      color: 'primary.600',
    },
    _checked: {
      color: 'primary.600',
    },
  },
  label: {
    ...primary.label,
    color: 'unset',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const Checkbox = {
  parts: ['container', 'control', 'label', 'icon'],
  variants: {
    primary,
    filter: {
      ...primary,
      container: {
        padding: '7px 11px',
        margin: '0',
        borderRadius: '7px',
        _hover: {
          backgroundColor: 'primary.100',
        },
      },
    },
    primaryPartial: {
      ...primary,
      control: {
        ...primary.control,
        borderColor: 'primary.600',
        backgroundColor: '#5DA5F1',
        _hover: {
          backgroundColor: '#5DA5F1',
          borderColor: 'primary.600',
        },
        _checked: {
          backgroundColor: '#5DA5F1',
          color: 'white',
          borderColor: 'primary.600',
          _hover: {
            backgroundColor: '#5DA5F1',
          },
        },
        _focus: {
          backgroundColor: '#5DA5F1',
          boxShadow: '0 0 0 transparent',
        },
      },
    },
    circle: {
      control: {
        borderRadius: '6px',
      },
    },
    'thead-circle': {
      control: {
        borderRadius: '6px',
        boxShadow: '0 0 0 3px #F1F4F7',
      },
    },
    'table-checkbox': {
      control: {
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: 'silver',
        boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
        borderRadius: '4px',
        _checked: {
          backgroundColor: 'transparent',
          color: 'primary.600',
          borderColor: 'primary.600',
          _hover: {
            backgroundColor: 'transparent',
            color: 'primary.600',
            borderColor: 'primary.600',
          },
        },
        _hover: {
          backgroundColor: 'transparent',
          color: 'primary.600',
          borderColor: 'primary.600',
        },
      },
    },
    list: {
      container: {
        padding: '8px 16px',
        borderRadius: '20px',
        minWidth: '100%',
        _hover: {
          bgColor: 'primary.200',
          color: 'primary.600',
        },
        _groupHover: {
          borderColor: 'primary.600',
        },
      },
      control: {
        borderRadius: '4px',
        border: '1px',
        borderColor: 'silver',
        width: '14px',
        height: '14px',
        marginRight: '3px',
        backgroundColor: 'white',
        _hover: {
          borderColor: 'primary.600',
        },
        _checked: {
          backgroundColor: 'white',
          color: 'primary.600',
          borderColor: 'primary.600',
        },
        _focus: {
          boxShadow: '0 0 0 transparent',
        },
      },
      label: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '14px',
        _checked: {
          color: 'primary.600',
        },
      },
      icon: {
        backgroundColor: 'white',
      },
    },
    daysSelect: {
      control: {
        borderRadius: '4px',
        border: '1px',
        borderColor: 'silver',
        width: '14px',
        height: '14px',
        backgroundColor: 'white',
        _hover: {
          backgroundColor: 'white',
          borderColor: 'primary.600',
        },
        _checked: {
          backgroundColor: 'white',
          color: 'primary.600',
          borderColor: 'primary.600',
          _hover: {
            backgroundColor: 'white',
          },
        },
        _focus: {
          backgroundColor: 'white',
          boxShadow: '0 0 0 transparent',
        },
      },
      label: {
        fontSize: '14px',
        color: 'secondary.400',
        _hover: {
          backgroundColor: 'white',
          color: 'main.400',
        },
      },
      icon: {
        height: '7px',
      },
    },
    multiList: {
      ...primary,
      ...multiList,
    },
    labelsList: {
      ...primary,
      ...multiList,
      label: {
        ...multiList.label,
        fontSize: '12px',
        lineHeight: '14px',
      },
      container: {
        ...multiList.container,
        padding: '0px',
        _hover: {
          '& > .chakra-checkbox__label': {
            color: 'primary.600',
          },
          '& > .chakra-checkbox__control': {
            borderColor: 'primary.600',
          },
          color: 'primary.600',
        },
        _checked: {
          ...multiList.container._checked,
          _hover: {
            '& > .chakra-checkbox__label': {
              color: 'main.400',
            },
            '& > .chakra-checkbox__control': {
              borderColor: 'silver',
            },
          },
        },
      },
    },
  },
};

export default Checkbox;
