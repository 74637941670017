import React, { ReactNode, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalProps,
  ModalOverlayProps,
} from '@chakra-ui/react';

interface PopupProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  children: ReactNode;
  width?: string;
  size?: string;
  closeOnOverlayClick?: boolean;
  hideCloseIcon?: boolean;
  maxW?: string;
  titlePadding?: string;
  scrollBehavior?: 'inside' | 'outside';
  modalOverplayProps?: ModalOverlayProps;
  blockScrollOnMount?: boolean;
  bottomPopupProps?: {
    position: 'fixed';
    bottom: string;
    mb: string;
  };
}

const Popup: React.FC<PopupProps> = ({
  children,
  title,
  isOpen,
  onClose,
  closeOnOverlayClick,
  hideCloseIcon,
  size,
  maxW,
  width,
  scrollBehavior = 'outside',
  titlePadding = '40px 0 0 0',
  modalOverplayProps,
  bottomPopupProps,
  blockScrollOnMount = false,
  isCentered = true,
}) => {
  const finalRef = useRef();

  return (
    <Modal
      blockScrollOnMount={blockScrollOnMount}
      closeOnEsc={closeOnOverlayClick}
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={finalRef}
      isCentered={isCentered}
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      size={size}
      onClose={onClose}>
      <ModalOverlay bg="rgba(255, 255, 255, 0.8)" {...modalOverplayProps} />

      <ModalContent
        {...bottomPopupProps}
        background="#FDFEFE"
        boxShadow="0px 8px 44px rgba(66, 66, 66, 0.16) !important"
        maxW={maxW}
        width={{ md: width, base: width }}>
        {title && (
          <ModalHeader
            color="main.400"
            fontSize={size === 'full' ? '35px' : '22px'}
            fontWeight={500}
            lineHeight="26px"
            padding={titlePadding}
            textAlign="center"
            width="100%">
            {title}
          </ModalHeader>
        )}

        {hideCloseIcon ? null : (
          <ModalCloseButton
            _focus={{ outline: 'none' }}
            color="osloGray"
            data-cy="close-btn"
            right="19px"
            size="sm"
            top="19px"
          />
        )}
        {children}
      </ModalContent>
    </Modal>
  );
};
export default Popup;

Popup.defaultProps = {
  size: 'md',
  width: '775px',
  hideCloseIcon: false,
  closeOnOverlayClick: false,
};
