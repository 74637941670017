import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import Popup from '@app/components/Popup';
import ROUTES from '@app/utils/routes';

export const ReferralPromoModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="515px"
      title=""
      width="100%"
      onClose={onClose}>
      <Box p="40px 40px 40px">
        <Text
          fontSize="20px"
          mb="20px"
          textAlign="center"
          variant="tertiary-title">
          Have a friend who would love Smarter Contact?
          <br />
          Refer them and get a month on us.
        </Text>
        <Center>
          <Image
            borderRadius="12px"
            mb="20px"
            minHeight="309px"
            src="/modals/referral-promo.png"
          />
        </Center>
        <Center>
          <Button
            as={Link}
            to={`${ROUTES.settings}/affiliate-program`}
            variant="primary"
            width="197px"
            onClick={onClose}>
            Referral Program
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
