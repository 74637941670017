import React, { memo } from 'react';

const AlertCircle = () => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334zM8 5.333V8M8 10.667h.007"
        stroke="#47A0FE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const AlertCircleIcon = memo(AlertCircle);
export default AlertCircleIcon;
