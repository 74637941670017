export enum ErrorCode {
  LOW_BALANCE = 'low-balance',
  NO_10DLC = 'no-10dlc',
  NO_10DLC_PHONES = 'no-10dlc-phones',
  NO_PHONES = 'no-phones',
  LIMIT_EXCEEDED_SMS = 'limit-exceeded-sms',
  LIMIT_EXCEEDED_CAMPAIGN = 'limit-exceeded-campaign',
  CONTACTS_CANNOT_BE_REMOVED = 'contacts-cannot-be-removed',
  PHONE_CANNOT_BE_DELETED = 'phone-cannot-be-deleted',
  PHONES_CANNOT_BE_DELETED = 'phones-cannot-be-deleted',
}

type ErrorDetailsType = {
  code?: ErrorCode;
};

export interface ErrorType {
  error: {
    details?: ErrorDetailsType;
    message?: string;
  };
}

const errors: {
  [key: string]: string;
} = {
  [ErrorCode.LOW_BALANCE]:
    'Your account balance is low. Please add funds in the <a href="/settings/membership">membership page</a>',
  [ErrorCode.NO_10DLC]:
    'Please setup your <a href="/settings/dlc-registration">Brand</a> information (it takes 2 mins)',
  [ErrorCode.NO_10DLC_PHONES]:
    'The phone number is pending approval to be used for messaging.',
  [ErrorCode.NO_PHONES]:
    'You have no available phone numbers. Go to <a href="/settings/phone-setup">Phone Setup</a> page to purchase one.',
};

export const errorMessage = (code: ErrorCode): string | null => {
  return errors[code] ?? null;
};
