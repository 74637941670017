import { theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const ghostVariant = theme.components.Button.variants.ghost;

export const Button = {
  variants: {
    primary: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0 20px',
      fontWeight: 500,
      fontStyle: 'normal',
      _focus: {
        boxShadow: 'unset',
      },
      _hover: {
        backgroundColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        backgroundColor: 'primary.500',
      },
      _disabled: {
        backgroundColor: 'secondary.200',
        opacity: 'unset',
      },
    },

    link: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: 'transparent',
      color: 'primary.600',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'underline',
      textDecorationColor: 'primary.600',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: '#1B65C8',
        textDecorationColor: '#1B65C8',
      },
      _focus: {
        color: '#1B65C8',
        textDecorationColor: '#1B65C8',
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },
    linkSecondary: {
      fontSize: '14px',
      backgroundColor: 'transparent',
      padding: 0,
      color: 'primary.600',
      display: 'inline',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'none',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: '#1B65C8',
        textDecorationColor: 'none',
      },
      _focus: {
        color: '#1B65C8',
        textDecorationColor: 'none',
        boxShadow: 'unset',
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },

    goBack: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: 'transparent',
      color: 'primary.600',
      fontWeight: 'normal',
      fontStyle: 'normal',
      marginRight: '20px',
      height: 'auto',
      textDecorationColor: 'primary.600',
      padding: '0',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: '#1B65C8',
        textDecorationColor: '#1B65C8',
      },
      _focus: {
        color: '#1B65C8',
        textDecorationColor: '#1B65C8',
        boxShadow: 'unset',
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },

    outlined: {
      border: '1px',
      borderColor: 'primary.600',
      borderRadius: '20px',
      backgroundColor: 'white',
      color: 'primary.600',
      fontWeight: 500,
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '16px',
      padding: '0 20px',

      _hover: {
        backgroundColor: 'zircon',
        color: 'primary.600',
        borderColor: 'primary.700',
        _disabled: {
          backgroundColor: 'transparent',
          borderColor: 'secondary.200',
          color: 'secondary.200',
        },
      },
      _focus: {
        boxShadow: 'unset',
      },
      _active: {
        backgroundColor: 'primary.200',
        color: 'primary.500',
      },
      _disabled: {
        backgroundColor: 'transparent',
        borderColor: 'secondary.200',
        color: 'secondary.200',
        opacity: 'unset',
      },

      _loading: {
        backgroundColor: 'secondary.200',
        borderColor: 'secondary.200',
        color: 'white',
        opacity: 'unset',
      },
    },
    text: {
      fontSize: '14px',
      border: '0 none',
      backgroundColor: 'transparent',
      borderRadius: '20px',
      color: 'secondary.400',
      fontWeight: 400,
      fontStyle: 'normal',
      width: '100%',
      padding: '0',
      textAlign: 'left',
      display: 'block',
      height: '28px',
      _hover: {
        backgroundColor: 'transparent',
        color: 'secondary.400',
      },
      _active: {
        backgroundColor: 'transparent',
        color: 'secondary.400',
      },
      _disabled: {
        backgroundColor: 'transparent',
        borderColor: 'secondary.400',
      },
    },

    cancel: {
      color: 'secondary.400',
      backgroundColor: 'transparent',
      borderRadius: 'unset',
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0 20px',
      fontWeight: 500,
      fontStyle: 'normal',
      _hover: {
        color: 'primary.700',
      },
      _focus: {
        boxShadow: 'unset',
        color: 'pressedAzure',
      },
      _disabled: {
        color: 'secondary.200',
        _hover: {
          color: 'secondary.200',
        },
        opacity: 'unset',
      },
    },

    export: {
      color: 'primary.600',
      backgroundColor: 'transparent',
      padding: '0',
      _hover: {
        color: 'primary.700',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },

    login: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      color: 'white',
      fontSize: '17px',
      lineHeight: '22px',
      padding: '0 20px',
      fontWeight: 700,
      fontStyle: 'normal',
      _focus: {
        boxShadow: 'unset',
      },
      _hover: {
        backgroundColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        backgroundColor: 'primary.500',
      },
      _disabled: {
        backgroundColor: '#4F8CED',
        opacity: '.5',
      },
    },

    loginConnect: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      width: '380px',
      height: '48px',
      fontWeight: 'bold',
      fontSize: '17px',
      color: 'white',
      border: '1px',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: 'primary.700',
        borderColor: 'primary.700',
        color: 'white',
      },
    },

    loginDisconnect: {
      backgroundColor: 'white',
      borderRadius: '24px',
      width: '380px',
      height: '48px',
      fontWeight: 'bold',
      fontSize: '17px',
      color: 'primary.600',
      border: '1px',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: '#3b5999',
        borderColor: '#3b5999',
        color: 'white',
      },
    },
    iconButton: {
      color: 'main.100',
      backgroundColor: 'white',
      _hover: {
        color: 'primary.600',
        backgroundColor: 'primary.200',
      },
      _active: {
        color: 'pressedAzure',
        backgroundColor: 'primary.200',
      },
    },
    flatIconButton: {
      color: 'primary.600',
      _hover: {
        color: 'primary.600',
        opacity: '0.8',
      },
      _active: {
        color: 'pressedAzure',
      },
    },
    tab: {
      fontSize: '14px',
      border: '0 none',
      backgroundColor: 'transparent',
      color: 'secondary.400',
      fontWeight: 500,
      fontStyle: 'normal',
      padding: 0,
      borderRadius: 0,
      height: '31px',
      borderBottom: '2px solid',
      borderColor: 'transparent',
      minWidth: 'auto',
      _active: {
        backgroundColor: 'transparent',
        color: 'main.400',
        borderColor: 'primary.600',
      },
    },
    ghostPlain: (props: StyleFunctionProps) => ({
      ...ghostVariant(props),
      padding: '0',
      color: 'main.100',
      height: 'auto',
      minWidth: 'auto',
      _hover: {
        color: 'primary.600',
      },
      _loading: {
        _hover: {
          color: 'main.100',
        },
      },
    }),
    rounded: (props: StyleFunctionProps) => ({
      ...ghostVariant(props),
      padding: '12px',
      borderRadius: '50%',
      border: '1px solid',
      backgroundColor: 'white',
      borderColor: 'primary.600',
      _hover: {
        color: 'primary.600',
        backgroundColor: 'zircon',
      },
      _loading: {
        _hover: {
          color: 'main.100',
        },
      },
    }),
  },
};

export default Button;
