import { Auth } from 'firebase/auth';
import {
  DocumentReference,
  FieldValue,
  OrderByDirection,
  Timestamp,
  WhereFilterOp,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { CSSProperties } from 'react';

import { Header } from '@app/types/Contact';
import { PlanPriceType } from '@app/types/Account';

export interface DocumentId {
  id: string;
  path: string;
  ref: DocumentReference;
}

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type PayloadCreate<T> = Omit<T, keyof DocumentId>;
export type PayloadUpdate<T> = RecursivePartial<T>;

export interface Macro extends DocumentId {
  name: string;
  content: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
}

export enum CampaignType {
  SMS = 'sms',
  RVM = 'rvm',
}

export enum ExportMode {
  ALL = 'all',
  SENT = 'sent',
  UNSENT = 'unsent',
  RESPONDED = 'responded',
  UNRESPONDED = 'unresponded',
  CARRIER_BLOCKED = 'blocked',
  FAILED = 'failed',
  DELIVERED = 'delivered',
}

export enum CampaignStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  PAUSED = 'paused',
  ENDED = 'ended',
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SuppressionType {
  RESPONSE_FILTER = 'response_filter',
  UNDELIVERABLE = 'undeliverable',
  UNSUBSCRIBED = 'unsubscribed',
  DNC = 'dnc',
}

export enum PhoneType {
  TRIAL = 'trial',
  LIVE = 'live',
}

export interface PhoneNumberState {
  name: string;
  abbreviation: string;
}

export interface Phone extends DocumentId {
  phone: string;
  twilioSid: string;
  resourceUrl?: string;
  sms?: number;
  lastSmsAt?: Timestamp;
  voice?: number;
  lastVoiceAt?: Timestamp;
  conversations?: number;
  blocks?: number;
  lastBlockedAt?: Timestamp;
  totalPaid?: number;
  lastPaidAt?: Timestamp;
  messaging: {
    accountSid: string;
    authToken: string;
  };
  createdAt: Timestamp;
  updatedAt: Timestamp;
  a2pStatus: A2PStatus;

  ownerRef?: string;
  addedAt?: Timestamp;
  expiredAt?: Timestamp;
  limit?: number;
  type?: PhoneType;
  state?: PhoneNumberState;
}

export interface SearchPhonesView {
  id: string;
  phone: string;
  a2pStatus: A2PStatus;
  createdAt: string;
  lastSmsAt: string;
  sms: number;
  blocks: number;
  conversations: number;
}

export interface ContactPhoneNumber {
  value: string;
}

export interface ContactCustomField {
  id: string;
  value: string;
}

export interface ContactAddress {
  phone?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  email?: string;
  propertyAddress?: string;
  propertyCity?: string;
  propertyState?: string;
  propertyZip?: string;
  mailingAddress?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZip?: string;
}

export interface CustomField {
  id: string;
  title: string;
}

export interface CustomFields extends DocumentId {
  fields: CustomField[];
}

export interface AdditionalInfo {
  zillow?: Record<string, unknown>;
  followUp?: Timestamp;
  othernote?: string;
  zpid?: string;
  bed: number;
  bedrooms: number;
  zestimate: string | number;
  yearBuilt: number;
  owes: string | number;
  offer: string | number;
  finishedSqFt: string | number;
  lotSizeSqFt: string | number;
  occupancy: string;
  ac: string | number;
  taxAssessment: string | number;
  roof: string | number;
  parkingType: string | number;
  ptype: string | number;
  pool: string | number;
  repairs: string | number;
  rent: string | number;
  lastSoldDate: string | number;
}

export interface Contact extends DocumentId {
  customFields?: Record<string, ContactCustomField>;

  fullName?: string;
  firstName?: string;
  lastName?: string;
  headers?: string[];
  data?: string[];

  address?: ContactAddress;

  additionalInfo?: AdditionalInfo;

  grade: DocumentReference<Label> | null;
  labels: DocumentReference<Label>[] | null;

  email?: string;
  lastSent?: Timestamp;

  isFavorite?: boolean;

  lastSmsReceivedDate?: Timestamp;
  lastVoiceReceivedDate?: Timestamp;

  sources?: DocumentReference<ContactList>[];

  status?: ContactStatus;

  read: boolean;
  replied: boolean;
  lastMessageReplied: boolean;

  lastMessageAt?: Timestamp;

  isBlocked?: boolean;
  isUnsubscribed?: boolean;

  campaignHistory?: DocumentReference<Campaign>[];
  repliedCampaigns?: DocumentReference<Campaign>[];

  phoneNumbers: ContactPhoneNumber[];

  isDealClosed: boolean;
  dealClosedAt: Timestamp;

  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface AudioFile {
  contentType: string;
  fullPath?: string;
  bucket?: string;
}

export interface Template extends DocumentId {
  type: CampaignType;
  name: string;
  description: string;
  audioFile?: AudioFile;
  content: string;
  optOut: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface Suppression extends DocumentId {
  number: string;
  source: SuppressionType;

  info?: {
    firstName: string;
    lastName: string;
  };
  contact: DocumentReference<Contact>;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface SuppressionCreate extends Partial<Suppression> {
  firstName?: string;
  lastName?: string;
  contactId?: string;
  source: SuppressionType;
}

export interface ContactListOccurrences {
  addresses: number;
  cities: number;
  emails: number;
  landlines: number;
  mobiles: number;
  names: number;
  states: number;
}

export interface SearchContactList {
  id: string;
  verified: boolean;
  name: string;
  contacts: number;
  chats: number;
  campaigns: string[];
  createdAt: string;
  leads: number;
  ref: string;
  ready: boolean;
}

export type TableHeader = Header[] | string[];

export interface ContactList extends DocumentId {
  name: string;
  headers?: TableHeader;
  isChecked?: boolean;
  rows?: number;
  initialSize?: number;
  linkedCount?: number;
  ready?: boolean;
  readySince?: Timestamp;
  size?: number;
  totalPhone?: number;
  excludeFirstRow?: boolean;

  verified?: boolean;
  verifiedAt?: Timestamp;

  isSkiptracing?: boolean;
  fromSkiptracing?: boolean;

  skiptracingStarted?: boolean;
  skiptracingStartedAt?: Timestamp;

  occurrences?: ContactListOccurrences;

  deleteUnverified?: boolean;
  deleteManual?: boolean;
  deleteSuppression?: boolean;
  deleteDuplicate?: boolean;
  deleteDnc?: boolean;
  deleteUnsubscribed?: boolean;

  chats?: number;

  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface SkiptraceOrder extends DocumentId {
  contactList: DocumentReference<ContactList>;
  orderId: string;

  chargeId: string | null;

  isFree: boolean;

  amount: number;
  price: number;

  contacts: number;
  processed: number;

  successfully: number;
  mobiles: number;
  landlines: number;

  cached?: number;

  isEnded: boolean;
  endedAt?: Timestamp;

  isPaid: boolean;
  paidAt?: Timestamp;

  isNotified: boolean;

  file?: {
    bucket: string;
    filePath: string;
  };

  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface RawContactSkiptracedInfo {
  names?: string[];
  emails?: string[];
  cities?: string[];
  states?: string[];
  mobiles?: string[];
  addresses?: string[];
  landlines?: string[];
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
}

export interface RawContact extends DocumentId {
  phone: string[];
  data: string[];

  rowIndex: number;

  skiptracedInfo?: RawContactSkiptracedInfo | null;
  skiptraceOrderInfo?: { skiptraceOrder: DocumentReference };
  contact?: ContactInfo;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

type WhereType<T> = [keyof T, WhereFilterOp, unknown];

export interface QueryParams<T> {
  limit?: number;
  orderBy?: [[keyof T, OrderByDirection]];
  where?: WhereType<T>[];
  id?: string;
}

export interface DefaultSort {
  created_at?: Direction;
}

export interface Sort extends DefaultSort {
  is_favorite?: Direction;
  deal_closed_at?: Direction;
}

export interface SearchContactsSort extends Sort {
  last_message_at?: Direction;
  'full_name.keyword'?: Direction;
}

export interface SearchInboxQueryParams {
  status?: ContactStatus;
  search?: string;
  read?: boolean;
  last_message_replied?: boolean;
  replied_campaigns?: string[];
  labels?: string[];
  sources?: string[];
  sort?: Sort;
  accountId?: string;
  is_unsubscribed?: boolean;
}

export interface SearchContactListQueryParams {
  sort?: DefaultSort;
  search?: string;
  size?: number;
  refs?: string[];
}

export interface SearchContactsQueryParams {
  search?: string;
  status?: ContactStatus;
  labels?: string[];
  sources?: string[];
  sort?: SearchContactsSort;
}

export interface SearchPhonebookQueryParams {
  search?: string;
}

export interface SearchCampaignsParams {
  search?: string;
  status?: string;
  size?: number;
  from?: number;
}

export interface SearchCampaignItemCost {
  captured: number;
  refunded: number;
}

export interface SearchCampaignItem {
  id: string;
  name: string;
  contacts: number;
  phones: number;
  createdAt: string;
  lastSentAt?: string;
  lists: string[];
  ref: string;
  status: CampaignStatus;
  templateId?: string;
  content?: string;
  costs?: SearchCampaignItemCost;
}

export interface SearchPaginationResponse<T> {
  data: T[];
  meta: {
    total: number;
    nextCursor: number | null;
  };
}

export interface SearchResultItem<T> {
  _source: T;
}

export interface SearchResult<T> {
  hits: {
    hits: SearchResultItem<T>[];
    total: {
      value: number;
    };
  };
}

export enum LastMessageContentType {
  TEXT = 'text',
  MEDIA = 'media',
  VOICE = 'voice',
  INCOMING_CALL = 'incoming-call',
  OUTGOING_CALL = 'outgoing-call',
}

export interface SearchInboxItem extends DocumentId {
  fullName: string;
  isUnsubscribed: boolean;
  isFavorite: boolean;
  labels: string[];
  lastMessage: string;
  lastMessageAt: number;
  lastMessageContentType: LastMessageContentType;
  phone: string;
  read: boolean;
  status: string;
}

export interface Plans extends DocumentId {
  stripePlanId: string;
  title: string;
  messageFee: number;
  monthFee: number;
  phoneFee: number;
  rvmFee: number;
  skiptraceFee: number;
}

export interface Affiliate extends DocumentId {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  referName: string;
  referUid: string;
  email: string;
  firstName: string;
  lastName: string;
  firstpromoterLink: string;
  link?: string;
}

export interface SearchContactUpdateOneParams {
  contactId: string;
  status?: ContactStatus;
  isFavorite?: boolean;
  read?: boolean;
  labels?: Label['id'][];
  fullName?: string;
  address?: ContactAddress;
}

export interface SearchContact extends DocumentId {
  fullName: string;
  isFavorite: boolean;
  phone: string;
  sources: string[];
  labels: string[];
  campaigns: string[];
  createdAt: number;
  status?: ContactStatus;
  isDealClosed?: boolean;
}

export interface SearchPhonebook {
  id: string;
  ref: string;
  fullName: string;
  phone: string;
}

export interface AutoSubmitNotificationsProps {
  values: unknown;
  submitForm: () => void;
}

export interface AdminCounters extends DocumentId {
  accounts: {
    activePrimary: number;
    activeSecondary: number;
    trial: number;
    inactive: number;
    monthNewActive: number;
    monthSms: number;
  };
  plans: {
    [k: string]: number;
  };
}

export enum EventType {
  CONTACT = 'contact',
  CAMPAIGN = 'campaign',
  FOLLOWUP = 'followup',
}

export interface BaseEvent {
  title: string;
  description: string;

  type: EventType;

  contact: DocumentReference<Contact>;
  campaign?: DocumentReference<Campaign>;
}

export enum EventStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface Event extends DocumentId, BaseEvent {
  startAt: Timestamp;
  endAt: Timestamp;

  createdAt?: Timestamp;
  updatedAt?: Timestamp;

  status?: EventStatus;
}

export interface UIEvent extends Event {
  top: CSSProperties['top'];
  relativeTop?: CSSProperties['top'];
  height: number;
  width: CSSProperties['width'];
  left: number;
  scheduledFromTime: DateTime;
  scheduledToTime: DateTime;
}

export interface CreateEvent extends Partial<Event> {
  title: string;
  description: string;

  contact?: DocumentReference<Contact>;
  campaign?: DocumentReference<Campaign>;

  scheduledAt: Timestamp;
  range: TimeRange;

  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}
export interface LocalEvent extends BaseEvent {
  range: TimeRange;
  startAt: Date;
  endAt: Date;
  status: ContactStatus;
}

export interface Label extends DocumentId {
  title: string;
  color: string;
  default: boolean;
  isFolder: boolean;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface AccountCounters {
  smsCampaigns?: number;
  smsActiveCampaigns?: number;
  smsDraftCampaigns?: number;

  rvmCampaigns?: number;
  rvmActiveCampaigns?: number;
  rvmDraftCampaigns?: number;

  contactLists?: number;
  skiptraces?: number;

  dnc_suppressions?: number;
  response_filter_suppressions?: number;
  undeliverable_suppressions?: number;
  unsubscribed_suppressions?: number;
  crm_suppressions?: number;
}

export interface LabelsSelectData {
  title: string;
  value: string;
}

export interface NotificationsData {
  followUp: boolean;
  campaigns: boolean;
  labels: boolean;
  labelsSelect: LabelsSelectData[];
  skiptrace: boolean;
}

export enum MessagingCarrier {
  AT_T = 'atT',
  T_MOBILE = 'tMobile',
  US_CELLULAR = 'usCellular',
  VERIZON_WIRELESS = 'verizonWireless',
}

export type A2PCampaignCapacityValue = {
  tpm: number | null;
  tpd: number | null;
};

export type A2PCampaignCapacity = Record<
  MessagingCarrier,
  A2PCampaignCapacityValue
>;

export interface MessagingEndpoint {
  password: string;
  username: string;
  id: string;
}

export enum MessagingProviderType {
  TWILIO = 'twilio',
  PLIVO = 'plivo',
}

export interface Messaging {
  isOnline: boolean;
  a2pBrandIdUpdatedAt: Timestamp;
  statusUpdatedAt: Timestamp;
  phonesCount: number;
  optOutDisabled: boolean;
  accountSid: string;
  lastOnline: Timestamp;
  a2pType: A2PType;
  responseFilters: string[];
  a2pCampaignIdUpdatedAt: Timestamp;
  checkOnlyFirstReply: boolean;
  serviceSid: string;
  a2pCampaignId: string;
  status: A2PStatus;
  a2pBrandId: string;
  providerType: MessagingProviderType;
  authToken: string;
  a2pTypeUpdatedAt: Timestamp;
  isDocRequired?: boolean;
  a2pCapacity?: A2PCampaignCapacity;
  maxTpm?: number;
  endpoint: MessagingEndpoint;
  allowUrl?: boolean;
}

export enum PlanPricePeriod {
  MONTH = 'month',
  YEAR = 'year',
}

export interface PlanPrice {
  subscription: number;
  subscription_full: number;
  period: PlanPricePeriod;
  sms: number;
  freeSms: number;
  rvm: number;
  alert: number;
  freeAlerts: number;
  lookup: number;
  phone: number;
  freePhones: number;
  skiptrace: number;
  call: number;
}

export interface Plan extends DocumentId {
  title: string;
  isAnnual: boolean;

  prices: Record<PlanType | string, PlanPrice>;

  monthFee: number;

  rvmFee: number;
  messageFee: number;
  phoneFee: number;
  alertFee: number;
  skiptraceFee: number;
  lookupFee: number;

  freeSms: number;
  freeAlerts: number;

  messagesPerDayLimit?: number;
  messagesPerMonthLimit?: number;

  stripeCouponId: string;

  stripePlanIds: string[];
  stripePlans: unknown;

  level: PlanPriceType;

  secondaryUsersEnabled?: boolean;
  stripeSecondaryUserPlanId?: string;

  optOutPlanId: string;
  version?: number;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface BillingAccount {
  autoRecharge: boolean;
  balance?: number;
  low: number;
  toBalance: number;
  couponsApplied?: string[];
  trialStartedAt?: Timestamp;
  paidUntil?: Timestamp;
}

export interface Account extends DocumentId {
  messaging: Messaging;
  firstName: string;
  lastName: string;
  companyName: string;
  timezone?: string;
  uid: string;
  email: string;
  role: string;
  status: AccountStatus;
  members: string;
  updateInterval?: number;
  phone?: {
    activeIn: string;
  };
  questionnaire: Record<string, string>;
  counters: AccountCounters;
  headerNotifications: NotificationsData;
  createdAt: Timestamp;
  plan: DocumentReference<Plan>;
  planType: PlanPriceType;
  billing: BillingAccount;
  messengerNewTabOpen?: boolean;
  masterAccount: DocumentReference;
  monthSms?: number;
  businessInfo: DlcFormFields;
  notification: Record<string, boolean>;
  isOnboarded: boolean;
  isTest?: boolean;
}

export enum A2PStatus {
  NONE = 'none',
  REVIEW = 'review',
  PENDING = 'pending',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export enum A2PType {
  NONE = 'NONE',
  STARTER = 'STARTER',
  STANDARD = 'STANDARD',
}

export interface SearchAccount {
  id: string;
  account_ref: string;
  full_name: string;
  phone: string;
  email: string;
  email_verified: boolean;
  refer_name?: string;
  created_at: number;
  updated_at: number;
  plan: string;
  type: 'primary' | 'secondary';
  months_of_membership: number;
  membership_started: number;
  membership_ended?: number;
  trial_started: number;
  last_billing_date: number;
  last_billed_amount: number;
  total_billed: number;
  total_billed_to_date: number;
  total_membership_fees: number;
  month_sms_usage: number;
  total_sms_usage: number;
  total_skiptrace_usage: number;
  opt_out_disabled: boolean;
  balance: number;
  is_onboarded: boolean;
  status: AccountStatus;
  industry: string;
  a2p_status?: A2PStatus;
  a2p_type?: A2PType;
  a2p_brand_id?: string;
  a2p_campaign_id?: string;
  is_test?: boolean;
  messaging_provider_type: MessagingProviderType;
  messaging_account_id?: string | null;
}

export interface ContactNote extends DocumentId {
  text: string;
  attachments?: {
    fullPath: string;
    bucket: string;
  }[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export enum Type {
  SMS = 'sms',
  RVM = 'rvm',
}

export enum PlanType {
  STARTER = 'starter',
  PRO = 'pro',
  ELITE = 'elite',
  STANDARD = 'standard',
  NINJA = 'ninja',
  TOP_G = 'top_g',
}

export enum ContactStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export enum AccountStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  INACTIVE = 'inactive',
  ABANDONED = 'abandoned',
}

export interface TimeRangePiece {
  hour: number;
  minute: number;
}
export interface TimeRange {
  from: TimeRangePiece;
  to: TimeRangePiece;
}

export enum CampaignRuleStrategy {
  REPLY = 'reply',
  SMS_REPLY = 'sms_reply',
  CALL_REPLY = 'call_reply',
  NO_REPLY = 'no_reply',
}

export interface CampaignBilling {
  price: number;
  chargeId: string | null;
  reserved: number;
  amount?: number;
  isPaid?: boolean;
  chargedAt?: Timestamp;

  sentCaptured?: boolean;
  sentCapturedAt?: Timestamp;

  captured?: boolean;
  capturedAt?: Timestamp;
}

export interface CampaignRuleOptions {
  contactList?: string;
  label?: string;
}

export interface CampaignRule {
  removeFromNextSequences: boolean;
  strategy: CampaignRuleStrategy;
  options?: CampaignRuleOptions;
}

export interface CampaignSequenceItem {
  executeAfterIntervalMs?: number;
  campaignType: CampaignType;
  templates: DocumentReference<Template>[];
  rules?: CampaignRule[];
}

export interface DripCampaignProgress {
  leads: number;
  leadsSent: number;
}

export enum CampaignFrequency {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
}

export enum CampaignStatusReasonType {
  RVM_LIMIT = 'RVM_LIMIT',
}

export interface CampaignStatusReason {
  reason: CampaignStatusReasonType;
  ttl?: Timestamp;
}

export interface Campaign extends DocumentId {
  name: string;
  active: boolean;
  version?: number;
  billing?: CampaignBilling;
  contactLists: DocumentReference<ContactList>[];
  sequence: CampaignSequenceItem[];
  options: {
    isScheduled: boolean;
    isBatched: boolean;
    isRestricted: boolean;
    isForwarding?: boolean;
    useStatic?: boolean;
    perBatch: number;
    interval: number;
    frequency: CampaignFrequency;
    timeRange: TimeRange;
    start: Timestamp;
    days: number[];
  };
  messages: number;
  messagesSent?: number;
  processed?: number;
  statusSaved?: number;
  successfully?: number;
  leads: number;
  phones?: number;
  leadsSent?: number;
  lastSent?: Timestamp;
  started?: boolean;
  startedAt?: Timestamp;
  ended?: boolean;
  endedAt?: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  status: CampaignStatus;
  isDrip: boolean;
  dripProgress: Record<string, DripCampaignProgress>;
  wasPaused?: boolean;
  statusReason?: CampaignStatusReason;
  outgoingNumberId?: string;
  clickThroughStartAfter?: DocumentReference;
}

export interface CampaignFunctionResponse {
  campaign: Campaign;
  campaignRef: string;
  id: string;
}

export enum Status {
  ACCEPTED = 'accepted',
  SCHEDULED = 'scheduled',
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  RECEIVING = 'receiving',
  RECEIVED = 'received',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  FAILED = 'failed',
  FAILURE = 'failure',
  FINISHED_CALL = 'finished_call',
}

export enum MessageType {
  OUTGOING = 'outgoing',
  INCOMING = 'incoming',
}

export const MessageDirection = {
  OUTBOUND: 'outbound',
  INBOUND: 'inbound',
} as const;

export type MessageDirectionType =
  typeof MessageDirection[keyof typeof MessageDirection];

export const MessageKind = {
  SMS: 'sms',
  RVM: 'rvm',
  CALL: 'call',
} as const;

export type MessageKindType = typeof MessageKind[keyof typeof MessageKind];

export type MessageProviderType = 'twilio' | 'plivo';

export interface Message extends DocumentId {
  type: MessageType;
  kind: MessageKindType;
  providerType?: MessageProviderType;

  id: string;
  messagingServiceSid?: string;

  errorMessage?: string;

  fromNumber?: string;
  toNumber: string;

  content: string | null;
  isConversation: boolean;
  rvmAudio?: DocumentReference;

  status?: Status;

  deliverable?: {
    code?: string;
    message?: string;
  };

  campaign?: DocumentReference<Campaign>;
  template?: DocumentReference<Template>;
  campaignType?: CampaignType;

  attachments?: MessageAttachment[];

  // Phone Call Message stuff
  direction?: MessageDirectionType;
  duration?: number;

  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface ScheduledMessage extends Message {
  scheduledAt: number;
  text: string;
}
export interface ScheduledMessageCreateParams {
  text: string;
  contactId: string;
  scheduledAt: Date;
  attachments?: MessageAttachment[];
}
export interface ScheduledMessageRemoveParams {
  contactId: string;
  scheduledTaskId: string;
}

export interface MessageAttachment {
  fullPath: string;
  bucket: string;
  url?: string;
  contentType?: string;
}

export interface RVMAudio extends DocumentId {
  audioFile?: AudioFile;
  title?: string;
  description?: string;
  contentType: string;
  fullPath?: string;
  bucket?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface ReportQueryParams {
  campaignId?: string;
  templateId?: string;
  start: Date;
  end: Date;
  accountId?: string;
}

export interface ReportRequest {
  campaignId?: string;
  templateId?: string;
  start: number;
  end: number;
  accountId: string;
}

export interface ReportResponse {
  campaigns: number;
  carrierBlockedMessages: number;
  deliveredMessages: number;
  overallCount: number;
  responses: number;
  sentMessages: number;
  totalMessages: number;
  rvmTotalCount: number;
  labels: Record<string, string>;
  dealsClosed: number;
}
export interface CampaignTemplate extends DocumentId {
  contactId: string;
  listId: string;
  phone: string;
  fields: Record<Header, string>;
  templateId: string;
  replied: boolean;
}

export interface UserInfoCard {
  last4: string;
  id: string;
  brand: string;
  balance?: number;
}

export interface SubscriptionsInfo {
  current_period_end: Timestamp;
}

export interface UserInfoSources {
  data: UserInfoCard[];
}

export interface UserInfoSubscriptions {
  data: SubscriptionsInfo[];
}

interface BillingHistoryDataProps {
  description: string;
  amount: number;
  created: Timestamp;
  id: string;
  receipt_url: string;
  status: CampaignStatus;
}

export interface BillingHistoryProps {
  data: BillingHistoryDataProps[];
}

export interface UserInfo {
  created?: Timestamp;
  default_source?: { id: string };
  sources?: UserInfoSources;
  subscriptions?: UserInfoSubscriptions;
}

export interface IntercomArticle {
  type: string;
  id: string;
  workspace_id: string;
  title: string;
  description: string;
  body: string;
  author_id: string;
  state: 'published' | 'draft';
  created_at: number;
  updated_at: number;
  url: string;
  parent_id: string;
  parent_type: string;
  default_locale: string;
  translated_content: Record<string, unknown> & { type: string };
  statistics: {
    type: string;
    views: number;
    conversation: number;
    reactions: number;
    happy_reaction_percentage: number;
    neutral_reaction_percentage: number;
    sad_reaction_percentage: number;
  };
}

export interface AdminUserFunctionRequest {
  uid: string;
}
export interface AdminUserFunctionResponse {
  password: string;
  user: Auth;
}

export interface AdminUserEmailUpdateFunctionRequest {
  uid: string;
  email: string;
}
export interface AdminUserEmailUpdateFunctionResponse {
  user: Auth;
}

export interface AdminUserPlanTypeUpdateFunctionRequest {
  accountId: string;
  planType: PlanPriceType;
}
export interface AdminUserPlanTypeUpdateFunctionResponse {
  planType: PlanPriceType;
}

export interface AdminUserStatusUpdateFunctionRequest {
  accountId: string;
  status: AccountStatus;
}
export interface AdminUserStatusUpdateFunctionResponse {
  status: AccountStatus;
}

export interface ExportFileRequest {
  campaignId: string;
  mode: string;
}
export interface ExportFile {
  bucket: string;
  filePath: string;
  publicUrl: string;
}

export interface AdminCountersResult {
  accounts: {
    activePrimary: number;
    activeSecondary: number;
    trial: number;
    inactive: number;
    monthNewActive: number;
  };
  plans: {
    elite: number;
    pro: number;
    starter: number;
    standard: number;
    ninja: number;
    top_g: number;
  };
  messages: {
    monthSms: number;
  };
}

export interface CampaignLookupRequest {
  contactListId: string;
  source?: string;
}
export interface CampaignLookupResponse {
  lookupOrderRef: string;
  lookupOrder?: unknown;
}

export interface HeaderTemplate extends DocumentId {
  name: string;
  headers: Header[];
  createdAt: Timestamp | FieldValue;
  updatedAt: Timestamp | FieldValue;
}

export interface SearchCounters {
  campaigns: {
    total: number;
    active: number;
    draft: number;
  };
  contacts: {
    total: number;
    groups: number;
    active: number;
    deleted: number;
  };
  inbox: {
    labels: Record<string, number>;
    groups: Record<string, number>;
    campaigns: Record<string, number>;
  };
}

export interface ExportToFileResult {
  bucket: string;
  filePath: string;
  publicUrl: string;
}

export interface SuppressionsUploadRequest {
  bucket: string;
  filePath: string;
  source: SuppressionType;
}
export interface SuppressionsRemove {
  source: SuppressionType;
  ids?: string[];
}
export interface SuppressionsAdd {
  source: SuppressionType;
  labels?: string[];
  sources?: string[];
}

export enum PodioField {
  DATE = 'date',
  EMAIL = 'email',
  PHONE = 'phone',
  MONEY = 'money',
  CONTACT = 'contact',
}

export interface MembershipPlanParams {
  planId: string;
  startImmediately: boolean;
  isAnnual: boolean;
}

export interface PodioMatchField {
  config: {
    delta: number;
    description?: string;
    hidden: boolean;
    hidden_create_view_edit: boolean;
    label: string;
    mapping?: string;
    required: boolean;
    settings: {
      allowed_currencies?: string[];
      calendar?: boolean;
      call_link_scheme?: string;
      color?: string;
      decimals?: number;
      display?: string;
      end?: string;
      format?: string;
      has_map?: boolean;
      include_in_bcc?: boolean;
      include_in_cc?: boolean;
      multiple?: boolean;
      options?: {
        id: number;
        color: string;
        status: string;
        text: string;
      }[];
      possible_types?: string[];
      size?: string;
      structured?: boolean;
      time?: string;
    };
    unique: boolean;
    visible: boolean;
  };
  external_id: string;
  field_id: string;
  label: string;
  status: string;
  type: PodioField;
}

export enum CustomFieldMethod {
  GET_CONTACT_MESSAGE_HISTORY = 'getContactMessagesHistory',
}

export interface CustomMatchField {
  text: string;
  data?: string[];
  join?: string;
  method?: CustomFieldMethod;
  type: CustomField;
  addition?: 1 | 2; // depth in contact to get data (2 - root, 1 - in additionalInfo object)
}

export interface App {
  app_fields: {
    addition: number;
    data: string[];
    podio_field: PodioMatchField;
    text?: string;
    type?: string;
  };
  app_id: number;
  config: {
    allow_create: boolean;
    allow_edit: boolean;
    description?: string;
    icon: string;
    icon_id: number;
    item_name: string;
    name: string;
    type: string;
    usage?: string;
  };
  current_revision: number;
  fields: PodioMatchField[];
  is_default: boolean;
  item_accounting_info?: {
    count: number;
    limit: number;
    org_id: number;
    percent: number;
  };
  link: string;
  link_add: string;
  match_fields?: {
    field: CustomMatchField;
    podio: PodioMatchField;
  }[];
  match_ready?: number;
  original?: number;
  ready?: number;
  space_id: number;
  status: string;
  url: string;
  url_add: string;
  url_label: string;
}

export interface IntegrationApiInfo {
  id: string;
  key: string;
}

export interface IntegrationPodioAppIdToFieldsMatch {
  [key: number]: App['match_fields'];
}

export interface IntegrationAuthToken {
  access_token: string;
  expires_in: number;
  ref: {
    id: number;
    type: string;
  };
  refresh_token: string;
  scope: string;
  token_type: string;
  givenAt: Timestamp;
}

export enum ApiIntegrationTarget {
  ZAPIER = 'zapier',
  REISIFT = 'reisift',
}

export interface IntegrationMappingsStatusField {
  label: DocumentReference<Label>;
  status: string;
}

export interface IntegrationMappings {
  propertyStatus?: IntegrationMappingsStatusField[];
  phoneStatus?: IntegrationMappingsStatusField[];
  phoneTag?: DocumentReference<Label>[];
  notes?: boolean;
}

export interface Integration extends DocumentId {
  name: string;
  target: ApiIntegrationTarget;
  active: boolean;
  targetUrl?: string;
  apiKey?: string;
  mappings?: IntegrationMappings;
  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
}

export enum SubAccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface DlcFormFields {
  a2pType: A2PType;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  street: string;
  email: string;
  ein: string;
  einIssuingCountry: string;
  companyName: string;
  website: string;
  volume: string;
  vertical: string;
  campaignTitle: string;
  campaignDescription: string;
  sample1: string;
  sample2: string;
  allowUrl: boolean;
}

export interface DlcSecondaryFormFields {
  campaignTitle: string;
  campaignDescription: string;
  sample1: string;
  sample2: string;
  allowUrl: boolean;
}

export interface CreateContactParams {
  firstName: string;
  lastName: string;
  phone: string;
  addToMessenger?: boolean;
}

export interface UpdateContactStatus {
  accountId?: string;
  contactIds: string[];
  status?: ContactStatus;
  read?: boolean;
}

export interface ErrorWithDetails {
  message: string;
  details: string[];
  status: string;
}

export interface ErrorWithCode {
  message: string;
  details: {
    code: string;
  };
  status: string;
}

export interface LookupOrder {
  contactList: DocumentReference<ContactList>;

  chargeId: string | null;
  amount: number;
  price: number;

  contacts: number;
  verifiedContacts: number;

  processed: number;

  phones: number;
  verifiedPhones: number;

  isEnded: boolean;
  endedAt?: Timestamp;

  createdAt: Timestamp;
  updatedAt: Timestamp;
}

interface CampaignScheduleOptions {
  quantity: number;
  sequenceIntervals: number[];
  start: number;
  perBatch: number;
  frequency: CampaignFrequency;
  interval: number;
  days: number[];
  timeRange: TimeRange;
}

export interface RemoveRawContactRequest {
  contactListId: string;
  rawContactId: string;
}

export interface RemoveContactListRequest {
  contactListsIds: string[];
}

export interface UseCampaignScheduleDataRequest {
  campaignId?: string;
  options?: CampaignScheduleOptions;
}

export interface UseCampaignScheduleDataResponse {
  startTime: number | null;
  endTime: number | null;
  nextTime: number | null;
}
export interface SkiptracePushToContacts {
  contactListId: string;
  mobiles: boolean;
  landlines: boolean;
  additional: boolean;
  replaceInitialData: boolean;
}

export interface RegisterPromoterRequest {
  trackingCode: string;
}

export interface RegisterPromoterResponse {
  referralLink: string;
}

export interface PromoterInfoView {
  referUid: string;
  referName: string;
  firstpromoterLink: string;
  createdAt: string;
  updatedAt: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface AddToBalanceUserRequest {
  amount: number;
  accountId: string;
}

export interface CustomerFeedbackRequest {
  score: number;
  details?: string;
}

export interface AccountMembershipTrialNumber {
  phoneId: string;
}

export interface StripeCustomer {
  isStartedTrial?: boolean;
  default_source?: string;
  sources?: {
    data: UserInfoCard[];
  };
}

export interface NotifyMessages {
  success: string;
  error?: string;
}

export interface CampaignCreateTemplatesRequest {
  campaignId: string;
  templates: string[];
}

export interface ExportToCRMRequest {
  integrationId: string;
  labels?: string[];
  groups?: string[];
  campaigns?: string[];
}

export interface ExportToCRMResponse {
  total: number;
  success: number;
}
export interface ContactsIntegrationsExportOne {
  integrationId: string;
  contactId: string;
}

export enum Industry {
  REAL_ESTATE_INVESTING = 'Real Estate Investing',
  GENERAL_SALES_MARKETING = 'General Sales/Marketing',
  EVENT_PROMOTION = 'Event Promotion',
  STAFFING = 'Staffing',
  AUTOMOTIVE = 'Automotive',
  REAL_ESTATE_AGENT = 'Real Estate Agent',
  GYM_FITNESS = 'Gym/fitness',
  LENDING = 'Lending',
}
