import React, { FC, memo, useContext } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { NavLinkProps, useHistory } from 'react-router-dom';

import { Features } from '@app/acl';
import { useFeatureAbility } from '@app/hooks/useFeatureAbility';
import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import EnvironmentLabel from '@app/navigation/components/EnvironmentLabel';

import { ReactComponent as LogoSmarterContactIcon } from '../icons/LogoSmarterContactIcon.svg';
import ROUTES from '../utils/routes';

import AvatarPopover from './components/AvatarPopover';
import HelpSupportPopover from './components/HelpSupportPopover';
import NotificationPopover from './components/NotificationPopover';
import { Link, LinkSettings } from './styles';
import { SettingsSection } from './SettingsSection';

const UINavigationPanel: FC = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const { can: hasV2 } = useFeatureAbility(Features.V2);

  const { isAdmin } = useContext(CurrentAccountContext);

  const isCampaigns: NavLinkProps['isActive'] = (_, current) =>
    current?.pathname.startsWith('/campaign');
  const isCalendar: NavLinkProps['isActive'] = (_, current) =>
    current?.pathname.startsWith('/calendar');
  const isFullMessengerActive = pathname.startsWith('/messenger-full');
  const isMessengerActive: NavLinkProps['isActive'] = (_, current) =>
    current?.pathname.startsWith('/messenger');
  const isSettingsActive: NavLinkProps['isActive'] = (_, current) =>
    current?.pathname.startsWith('/settings');
  const isContactsActive: NavLinkProps['isActive'] = (location, current) =>
    current?.pathname.startsWith('/contacts');

  return (
    <Flex direction="row" justifyContent="space-between" p="20px" width="100%">
      <Flex alignItems="center" direction="row">
        <Box
          cursor="pointer"
          m="0 20px 0 23px"
          position="relative"
          onClick={() => history.push(`${ROUTES.messenger}`)}>
          <LogoSmarterContactIcon />
          <EnvironmentLabel />
        </Box>
        <Flex height="25px">
          <Link
            activeClassName={
              isFullMessengerActive ? 'active activeFull' : 'active'
            }
            isActive={isMessengerActive}
            to={`${ROUTES.messenger}`}>
            <Box textAlign="center" title="Messenger">
              Messenger
            </Box>
          </Link>
          <Link isActive={isCampaigns} to={`${ROUTES.campaigns}`}>
            <Box textAlign="center" title="Campaigns">
              Campaigns
            </Box>
          </Link>
          <Link isActive={isContactsActive} to={`${ROUTES.contacts}/groups`}>
            <Box textAlign="center" title="Contacts">
              Contacts
            </Box>
          </Link>
          <Link isActive={isCalendar} to={`${ROUTES.calendar}/month`}>
            <Box textAlign="center" title="Calendar">
              Calendar
            </Box>
          </Link>
          <Link to={`${ROUTES.skiptrace}`}>
            <Box textAlign="center" title="Skiptrace">
              Skiptrace
            </Box>
          </Link>
          <Link to={ROUTES.reporting}>
            <Box textAlign="center" title="Reporting">
              Reporting
            </Box>
          </Link>
          {isAdmin && (
            <>
              <Link to={ROUTES.userSetup}>
                <Box textAlign="center" title="User setup">
                  User setup
                </Box>
              </Link>
              <Link to={ROUTES.affiliates}>
                <Box textAlign="center" title="User setup">
                  Affiliates
                </Box>
              </Link>
              {/* TODO uncomment when be ready to release 2.4 */}
              {/* <MenuDropdown /> */}
            </>
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center">
        {hasV2 && (
          <>
            <NotificationPopover />
            <HelpSupportPopover />
          </>
        )}
        <Flex alignItems="center" height="20px" mr="30px">
          <LinkSettings
            isActive={isSettingsActive}
            to={`${ROUTES.settings}/profile`}>
            <SettingsSection />
          </LinkSettings>
        </Flex>
        <Box>
          <AvatarPopover />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(UINavigationPanel);
