import React, { memo } from 'react';

const CheckGreen = () => {
  return (
    <svg
      fill="none"
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.333 4L6 11.333 2.667 8"
        stroke="#1F8B24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const CheckGreenIcon = memo(CheckGreen);
export default CheckGreenIcon;
