import { useMemo } from 'react';

import { AccountStatus } from '@app/api/queries/types';

import { useCurrentAccountData } from './useCurrentAccountData';

export const useAccountAttention = () => {
  const account = useCurrentAccountData();
  const isInactiveAccount = useMemo(
    () => account?.status === AccountStatus.INACTIVE,
    [account?.status],
  );
  const userHasNoPlans = useMemo(() => !account.plan, [account.plan]);
  const userHasAttention = isInactiveAccount && userHasNoPlans;

  return userHasAttention;
};
