import React, { FC } from 'react';
import { Box, Button, Center, Flex } from '@chakra-ui/react';
import type { UseDisclosureProps } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';

import Popup from '@app/components/Popup';
import FormItemTextArea from '@app/components/FormItem/FormItemTextArea';
import { useCustomerFeedbackMutation } from '@app/api/mutations/useCustomerFeedbackMutation';
import { catchErrorLog } from '@app/utils/logger';

import { feedbackSchema } from './schema';
import { FeedbackControl } from './FeedbackControl';
import { CustomerFeedbackValues } from './types';

const initialValues: CustomerFeedbackValues = {
  score: null,
  details: '',
};

export const CustomerFeedbackModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const mutation = useCustomerFeedbackMutation();

  const handleSubmit = async (values: CustomerFeedbackValues) => {
    try {
      if (isEmpty(values.details)) {
        delete values.details;
      }
      await mutation.mutateAsync(values);
      onClose();
    } catch (error) {
      catchErrorLog(error, 'CustomerFeedbackModal/handleSubmit');
    }
  };

  return (
    <Popup
      hideCloseIcon
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxW="440px"
      title="How do you rate our service?"
      width="100%"
      onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={feedbackSchema}
        onSubmit={handleSubmit}>
        {({ values, errors }) => (
          <Form>
            <Box p="23px 40px 40px">
              <Flex justifyContent="space-between" marginBottom="25px">
                <FeedbackControl value={1} />
                <FeedbackControl value={2} />
                <FeedbackControl value={3} />
                <FeedbackControl value={4} />
                <FeedbackControl value={5} />
              </Flex>

              {isNumber(values.score) && (
                <Box marginBottom="25px">
                  <FormItemTextArea
                    name="details"
                    placeholder="We’d love to hear your thoughts.."
                    resize="none"
                  />
                </Box>
              )}

              <Center>
                <Button
                  disabled={
                    mutation.isLoading ||
                    !!Object.keys(errors).length ||
                    !isNumber(values.score)
                  }
                  isLoading={mutation.isLoading}
                  loadingText="Submitting…"
                  type="submit"
                  variant="primary">
                  Submit
                </Button>
              </Center>
            </Box>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};
