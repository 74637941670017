import { useFunctions } from 'reactfire';
import { useFunctionsCall } from '@react-query-firebase/functions';

import { CustomerFeedbackRequest } from '../queries/types';

export const useCustomerFeedbackMutation = () => {
  const functions = useFunctions();

  return useFunctionsCall<CustomerFeedbackRequest, unknown>(
    functions,
    'account-feedback',
    undefined,
    {
      notify: {
        operation: 'create',
        success: () => 'Thank you for your feedback!',
        error: (error) => error.message,
      },
    },
  );
};
