const Textarea = {
  variants: {
    primary: {
      border: '1px',
      borderRadius: '22px',
      borderColor: 'secondary.200',
      fontSize: '14px',
      color: 'secondary.400',
      px: { base: 3, lg: 5 },
      _focus: {
        borderColor: 'primary.700',
        color: 'main.400',
      },
      _disabled: {
        borderColor: 'seconndary.200',
        backgroundColor: '#F3F6F9',
        color: 'main.100',
      },
      _invalid: {
        borderColor: '#FF5630',
      },
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        margin: '18px 0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#ABB0B5',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#ABB0B5',
      },
    },
    standard: {
      border: '1px',
      borderRadius: '22px',
      borderColor: 'secondary.200',
      fontSize: '14px',
      color: 'main.400',
      minHeight: 'auto',
      px: { base: 3, lg: 5 },
      _focus: {
        borderColor: 'primary.700',
        color: 'main.400',
      },
      _disabled: {
        borderColor: 'seconndary.200',
        backgroundColor: '#F3F6F9',
        color: 'main.100',
      },
      _invalid: {
        borderColor: '#FF5630',
      },
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        margin: '18px 0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#ABB0B5',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#ABB0B5',
      },
    },
  },
};

export default Textarea;
