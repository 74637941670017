import TagManager from 'react-gtm-module';

const config = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};

const initGTM = () => {
  TagManager.initialize(config);
};

export default initGTM;
