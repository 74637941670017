import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import isEmpty from 'lodash/isEmpty';

import { formatPhone } from '@app/utils/formatPhone';

import CheckIconAnimated from '../InputField/CheckIconAnimated';

export enum InputFieldStatus {
  NONE = 'none',
  PASSED = 'passed',
}

interface PhoneInputProps {
  value: string;
  defaultValue?: string;
  onConfirm: () => void;
  onChange: (value: string) => void;
  isLoading: boolean;
  isCodeSent: boolean;
  status: InputFieldStatus;
  canEdit?: boolean;
  isShowConfirmCheckbox?: boolean;
  isEditPhone?: boolean;
  placeholder: string;
}

const phoneNumberFormat = (phone: string) => `${phone}`.replace(/\D/g, '');

const PhoneInput: FC<PhoneInputProps> = ({
  isCodeSent = false,
  isLoading = false,
  onConfirm,
  onChange,
  value,
  defaultValue = '',
  status,
  canEdit = false,
  isShowConfirmCheckbox,
  isEditPhone,
  placeholder,
}) => {
  const phoneValue = useMemo(() => {
    if (!isEmpty(value)) {
      return value;
    }
    return !isEditPhone && defaultValue ? defaultValue : '';
  }, [defaultValue, isEditPhone, value]);

  const showEditButton = useMemo(() => {
    if (isCodeSent) {
      return false;
    }
    if (isEditPhone) {
      return `+1${value}` === defaultValue;
    }
    if (canEdit) {
      return true;
    }
    return false;
  }, [isCodeSent, isEditPhone, canEdit, value, defaultValue]);

  const btnText = useMemo(() => {
    if (isCodeSent) {
      return 'Resend Code';
    }
    return 'Send Code';
  }, [isCodeSent]);

  const onChangePhone = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const phone = e.target.value;
      onChange(phoneNumberFormat(phone));
    },
    [onChange],
  );

  return (
    <Box position="relative">
      <InputGroup>
        <Input
          as={InputMask}
          autoFocus={isEditPhone}
          borderColor={status === 'passed' ? '#4F8CED' : '#DDDDDD'}
          isDisabled={!isEditPhone && canEdit}
          mask="(999) 999-9999"
          placeholder={placeholder}
          type="tel"
          value={formatPhone(phoneValue)}
          variant="primary"
          onChange={onChangePhone}></Input>
        <InputRightElement w={isCodeSent ? '126px' : '112px'}>
          {status !== InputFieldStatus.PASSED && !showEditButton && (
            <Button
              disabled={phoneNumberFormat(value).length < 10 || isLoading}
              height="36px"
              isLoading={isLoading}
              variant="primary"
              onClick={onConfirm}>
              {btnText}
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
      {isShowConfirmCheckbox && status !== InputFieldStatus.NONE && (
        <Box
          position="absolute"
          right="-8px"
          top="50%"
          transform="translate(100%, -50%)">
          <CheckIconAnimated />
        </Box>
      )}
    </Box>
  );
};

export default memo(PhoneInput);
