import React, { FC } from 'react';
import { useRouteMatch } from 'react-router';

import { ReactComponent as SettingsAttentionActiveIcon } from '@app/icons/navigation/settings-attention-active-icon.svg';
import { ReactComponent as SettingsAttentionIcon } from '@app/icons/navigation/settings-attention-icon.svg';
import { ReactComponent as SettingsActiveIcon } from '@app/icons/navigation/settings-active-icon.svg';
import { ReactComponent as SettingsIcon } from '@app/icons/navigation/settings-icon.svg';
import { useAccountAttention } from '@app/hooks/useAccountAttention';

export const SettingsSection: FC = () => {
  const match = useRouteMatch({
    path: '/settings',
    exact: false,
  });

  const userHasAttention = useAccountAttention();

  if (match) {
    return userHasAttention ? (
      <SettingsAttentionActiveIcon />
    ) : (
      <SettingsActiveIcon />
    );
  }

  return userHasAttention ? <SettingsAttentionIcon /> : <SettingsIcon />;
};
