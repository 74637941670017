import React from 'react';
import {
  createStandaloneToast,
  Text,
  Icon,
  Flex,
  Spacer,
  ToastPosition,
} from '@chakra-ui/react';

import theme from '@app/theme';
import CloseIcon from '@app/icons/Toasts/CloseIcon';
import CloseCircleIcon from '@app/icons/Toasts/CloseCircleIcon';
import CheckGreenIcon from '@app/icons/Toasts/CheckGreenIcon';
import AlertCircleIcon from '@app/icons/Toasts/AlertCircleIcon';
import AlertTriangleIcon from '@app/icons/Toasts/AlertTriangleIcon';

export type StatusTypes = 'info' | 'warning' | 'success' | 'error';

export interface ToastNotifyTypes {
  title: string;
  position?: ToastPosition;
  linkTo?: () => void;
  linkToText?: string;
  status: StatusTypes;
}

const toast = createStandaloneToast({ theme });

const ToastNotify = ({
  title,
  position = 'top-right',
  linkTo,
  linkToText,
  status,
}: ToastNotifyTypes) => {
  const checkStatusBg = () => {
    switch (status) {
      case 'info':
        return '#E6EFFA';
      case 'success':
        return '#C5F2C7';
      case 'warning':
        return '#FBE5C9';
      case 'error':
        return '#FCD0CF';
      default:
        return 'transparent';
    }
  };

  const checkStatusIcon = () => {
    switch (status) {
      case 'info':
        return <Icon as={AlertCircleIcon} mr="10px" />;
      case 'success':
        return <Icon as={CheckGreenIcon} />;
      case 'warning':
        return <Icon as={AlertTriangleIcon} mr="10px" />;
      case 'error':
        return <Icon as={CloseCircleIcon} h="16px" mr="10px" width="16px" />;
      default:
        return 'transparent';
    }
  };

  return toast({
    position,
    isClosable: true,
    render: ({ onClose }) => (
      <Flex
        alignItems="center"
        background={checkStatusBg()}
        borderRadius="3px"
        color="main.400"
        fontSize="11px"
        mt="25px"
        p="10px">
        <Flex mr="8px">{checkStatusIcon()}</Flex>
        {title}
        {linkTo && (
          <Text
            color="#3C8BF4"
            cursor="pointer"
            fontSize="11px"
            onClick={linkTo}>
            &nbsp; {linkToText}
          </Text>
        )}
        <Spacer />
        <Flex cursor="pointer" ml="8px" onClick={onClose}>
          <CloseIcon />
        </Flex>
      </Flex>
    ),
  });
};

export default ToastNotify;
