import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import HelpIcon from '../../../icons/HelpIcon';
import VideoIcon from '../../../icons/VideoIcon';
import {
  HelpSupportVideoContainer,
  LinkArticle,
  DividerContainer,
} from '../../styles';

const HelpSupportPopover: FC = () => {
  const history = useHistory();
  return (
    <Popover isLazy matchWidth>
      <PopoverTrigger>
        <Box mr="30px">
          <Box cursor="pointer" data-cy="help-icon-button">
            <HelpIcon />
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent className="popover-arrow-help-support">
        <PopoverArrow />
        <Box
          backgroundColor="white"
          borderRadius="5px"
          data-cy="help-support-pop-up-window"
          filter="drop-shadow(0px 4px 30px rgba(94, 94, 94, 0.25))"
          height="auto"
          pb="15px"
          zIndex="10000000">
          <Flex direction="column">
            <DividerContainer direction="column" p="14px" width="100%">
              <Box mb="14px">
                <Text
                  as="span"
                  color="secondary.600"
                  fontWeight="bold"
                  size="lg">
                  Help & Support
                </Text>
              </Box>
            </DividerContainer>

            <HelpSupportVideoContainer>
              <Flex direction="row" p="15px">
                <Box>
                  <Text
                    color="#323e4a"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="normal"
                    lineHeight="16px"
                    mb="4px">
                    Guide Video Tour
                  </Text>

                  <Text
                    color="#475f7b"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="normal"
                    lineHeight="14px"
                    mb="4px">
                    For a walkthrough of Campaign, check out the following
                    video.
                  </Text>
                </Box>

                <Box>
                  <VideoIcon />
                </Box>
              </Flex>

              <Flex direction="row" p="15px">
                <Box>
                  <Text
                    color="#323e4a"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="normal"
                    lineHeight="16px"
                    mb="4px">
                    Best Practice Video
                  </Text>

                  <Text
                    color="#475f7b"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="normal"
                    lineHeight="14px"
                    mb="4px">
                    For a walkthrough of Campaign, check out the following
                    video.
                  </Text>
                </Box>

                <Box>
                  <VideoIcon />
                </Box>
              </Flex>
            </HelpSupportVideoContainer>

            <DividerContainer direction="column" p="14px" width="100%">
              <Box mb="14px">
                <Text
                  as="span"
                  color="#475F7B"
                  fontSize="16px"
                  fontWeight="bold">
                  Featured Article
                </Text>
              </Box>
              <LinkArticle to="/">How do I re-activate my account?</LinkArticle>
              <LinkArticle to="/">What’s a static number?</LinkArticle>
              <LinkArticle to="/">
                How to integrate Podio with Smarter Contact
              </LinkArticle>
            </DividerContainer>
            <Text
              color="#51606d"
              fontSize="12px"
              lineHeight="14px"
              mb="10px"
              textAlign="center">
              Can&#44;t find what you&#44;re looking for?
            </Text>

            <Flex justifyContent="center">
              <Button
                data-cy="help-center-button"
                variant="primary"
                onClick={() => history.push(`${ROUTES.settings}/help-support`)}>
                Help Center
              </Button>
            </Flex>
          </Flex>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default HelpSupportPopover;
