import React, { FC, useCallback } from 'react';
import { Box, Button, Flex, Text, UseDisclosureProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import Popup from '../Popup';

const TrialStatusModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();

  const goToPage = useCallback(() => {
    onClose();
    history.push(`${ROUTES.settings}/membership`);
  }, [history, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="340px"
      title="Activate your subscription"
      onClose={onClose}>
      <Box p="25px 40px 25px">
        <Text textAlign="center" variant="list-style">
          To keep using Smarter Contact, activate the subscription
        </Text>

        <Flex direction="column" justifyContent="center" mt="25px">
          <Button variant="primary" w="100%" onClick={goToPage}>
            Activate Subscription
          </Button>
          <Button mt="5px" variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default TrialStatusModal;
