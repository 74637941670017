const Text = {
  variants: {
    'sub-heading': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: 'main.400',
    },

    label: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      textTransform: 'none',
      margin: '5px 12px',
      color: 'rgba(0, 0, 0, 0.3)',
    },

    'secondary-text': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: 'secondary.400',
    },
    'tertiary-title': {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '26px',
      color: 'main.400',
    },
    'heading-fifth': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: 'main.400',
    },
    'page-title': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: 'main.400',
    },
    'list-style': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: 'main.400',
    },
  },
};

export default Text;
