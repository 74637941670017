import React, { FC } from 'react';

import { useEntityMutationNotifier } from '@app/hooks/useEntityMutationNotifier';

const EntityMutationNotifier: FC = ({ children }) => {
  useEntityMutationNotifier();
  return <>{children}</>;
};

export default EntityMutationNotifier;
