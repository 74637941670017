import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { usePlansData } from '@app/api/queries/usePlansData';
import { usePermissionAbility } from '@app/hooks/usePermissionAbility';
import { PermissionActions, Permissions } from '@app/acl';
import { useSearchInboxQueryData } from '@app/api/queries/useSearchInboxQueryData';
import { removeNil } from '@app/utils/removeNil';

import { useCurrentAccountData } from './useCurrentAccountData';
import { useDlcStatus } from './useDlcStatus';

export const usePopupsProvider = () => {
  const { isAccountStatusTrial } = useDlcStatus();
  const { can: canWelcomePopup } = usePermissionAbility(
    PermissionActions.INTERACTION,
    Permissions.WelcomePopup,
  );
  const accountData = useCurrentAccountData();
  const { data: plans } = usePlansData();

  const refetchInterval = useMemo(
    () => accountData?.updateInterval ?? false,
    [accountData],
  );

  const messagesQuery = removeNil({
    accountId: accountData?.id,
    size: 1,
    sort: { last_message_at: 'desc' },
    status: 'active',
  });

  const { data: messages } = useSearchInboxQueryData(messagesQuery, {
    refetchInterval,
  });

  const userPlan = accountData.plan?.path.split('/')[1];

  const currentPlan = useMemo(
    () => plans?.find((plan) => plan.ref.path === accountData?.plan?.path),
    [plans, accountData?.plan?.path],
  );

  const messagesPerMonthLimit = useMemo(
    () => currentPlan?.messagesPerMonthLimit ?? 0,
    [currentPlan?.messagesPerMonthLimit],
  );
  const monthSms = useMemo(
    () => accountData?.monthCampaignSms ?? 0,
    [accountData?.monthCampaignSms],
  );

  const percent = useMemo(
    () =>
      monthSms && messagesPerMonthLimit
        ? Math.round((monthSms * 100) / messagesPerMonthLimit)
        : 0,
    [messagesPerMonthLimit, monthSms],
  );

  const isToday = useMemo(
    () =>
      DateTime.fromJSDate(new Date()) >=
      DateTime.fromJSDate(accountData?.billing?.trialStartedAt?.toDate()).set({
        hour: 24,
        minute: 0,
      }),
    [accountData?.billing?.trialStartedAt],
  );

  const isNextDayAfterRegister = useMemo(
    () =>
      !(
        DateTime.fromJSDate(accountData?.createdAt?.toDate())
          .set({
            hour: 24,
            minute: 0,
          })
          .diffNow('seconds').seconds > 0
      ),
    [accountData?.createdAt],
  );

  const checkDaysSinceRegister = useCallback(
    (days: number) => {
      const { days: daysSinceRegister } = DateTime.fromJSDate(
        accountData?.createdAt?.toDate(),
      )
        .set({
          hour: 24,
          minute: 0,
        })
        .diffNow('days');

      return Math.abs(daysSinceRegister) >= days;
    },
    [accountData?.createdAt],
  );

  return {
    accountData,
    userPlan,
    percent,
    canWelcomePopup,
    messages,
    isToday,
    isAccountStatusTrial,
    isNextDayAfterRegister,
    checkDaysSinceRegister,
  };
};
