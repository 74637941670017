import { useContext } from 'react';
import { DocumentSnapshot } from 'firebase/firestore';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

import Account from '../types/Account';

export default (): DocumentSnapshot<Account> => {
  const { currentAccount: accountSnap } = useContext(CurrentAccountContext);

  return accountSnap;
};
