import { useMemo, useEffect, useState } from 'react';
import { applyActionCode, Auth, checkActionCode } from 'firebase/auth';
import { useAuth } from 'reactfire';
import { FirebaseError } from 'firebase/app';

import ToastNotify from '@app/components/ToastNotifier';

enum AuthActionTypes {
  VerifyEmail = 'verifyEmail',
}

const verifyEmail = async (auth: Auth, code: string) => {
  await checkActionCode(auth, code);
  await applyActionCode(auth, code);
  await auth.currentUser?.reload();
};

export const useEmailVerification = () => {
  const auth = useAuth();

  const params = useMemo(() => new URLSearchParams(location.search), []);

  const oobCode = params.get('oobCode');
  const isVerificationMode = params.get('mode') === AuthActionTypes.VerifyEmail;

  const [status, setStatus] = useState<string>(
    isVerificationMode ? 'loading' : undefined,
  );

  useEffect(() => {
    if (isVerificationMode && oobCode) {
      void (async () => {
        try {
          await verifyEmail(auth, oobCode);

          setStatus('success');
        } catch (error) {
          setStatus('error');

          ToastNotify({
            status: 'error',
            title: (error as FirebaseError)?.message,
          });
        }
      })();
    }
  }, [isVerificationMode, oobCode, auth]);

  return { status };
};
