import React, { FC, useCallback, useMemo } from 'react';
import { Button, Box, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import Popup from '@app/components/Popup';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import {
  accountModalText,
  AccountStatusModal,
} from '@app/utils/accountModalText';

interface ActivateAccountPopupProps {
  isOpen: boolean;
  onClose: () => void;
  canClose?: boolean;
}

const ActivateAccountPopup: FC<ActivateAccountPopupProps> = ({
  isOpen,
  onClose,
  canClose = false,
}) => {
  const history = useHistory();
  const account = useCurrentAccountData();

  const message = useMemo(
    () =>
      account?.masterAccount
        ? accountModalText[AccountStatusModal.SECONDARY_ACCOUNT]
        : accountModalText[AccountStatusModal.IS_INACTIVE],
    [account?.masterAccount],
  );

  const goToPage = useCallback(() => {
    history.push(message.route);
    onClose();
  }, [history, message.route, onClose]);

  return (
    <Popup
      hideCloseIcon={!canClose}
      isOpen={isOpen}
      maxW="330px"
      title={message.title}
      width="330px"
      onClose={onClose}>
      <Box margin="30px 45px">
        <Text fontSize="14px" mb="30px" textAlign="center">
          {message.description}
        </Text>

        <Button
          minWidth="100%"
          mr="20px"
          variant="primary"
          width="100%"
          onClick={account?.masterAccount ? onClose : goToPage}>
          {message.buttonText}
        </Button>
        {canClose && (
          <Button height="40px" variant="cancel" width="100%" onClick={onClose}>
            Cancel
          </Button>
        )}
      </Box>
    </Popup>
  );
};

export default ActivateAccountPopup;
