import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';

import Popup from '../Popup';

const articleUrl =
  'https://intercom.help/smarter-contact/en/articles/6738949-smarter-contact-2-3-release-november-2022';

export const NewVersionModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="416px"
      title=""
      width="100%"
      onClose={onClose}>
      <Box p="40px 40px 40px">
        <Text mb="25px" textAlign="center" variant="tertiary-title">
          Smarter Contact 2.3 Update
        </Text>
        <Image
          borderRadius="5px"
          minHeight="227px"
          src="/modals/new-release-2-3.png"
        />

        <Center mt="25px">
          <Button
            as="a"
            href={articleUrl}
            target="_blank"
            variant="primary"
            width="197px"
            onClick={onClose}>
            What&apos;s new
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
