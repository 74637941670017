export const Radio = {
  variants: {
    base: {
      container: {
        alignItems: 'flex-start',
        width: '100%',
      },
      label: {
        fontSize: '14px',
        lineHeight: '16px',
        color: 'mineShafft',
        fontWeight: 500,
      },
      control: {
        borderColor: 'secondary.300',
        _checked: {
          borderColor: 'primary.600',
          color: 'primary.600',
          background: 'transparent',
          _hover: {
            background: 'primary.100',
            borderColor: 'primary.600',
          },
          _before: {
            width: '8px',
            height: '8px',
          },
        },
        _focus: {
          boxShadow: '0 0 0 transparent',
        },
        _hover: {
          borderColor: 'secondary.200',
        },
      },
    },
    'dlc-radio': {
      container: {
        alignItems: 'flex-start',
        padding: '14px 10px 14px 20px',
        border: '1px solid',
        borderColor: 'secondary.200',
        borderRadius: '24px',
        width: '100%',
        _checked: {
          borderColor: 'primary.600',
          background: 'primary.200',
        },
      },
      label: {
        fontSize: '14px',
        lineHeight: '16px',
        color: 'main.400',
        _checked: {
          color: 'primary.600',
          fontWeight: 500,
        },
      },
      control: {
        borderColor: 'secondary.300',
        _checked: {
          borderColor: 'primary.600',
          color: 'primary.600',
          background: 'transparent',
          _hover: {
            background: 'primary.100',
            borderColor: 'primary.600',
          },
        },
        _focus: {
          boxShadow: '0 0 0 transparent',
        },
        _hover: {
          borderColor: 'secondary.200',
        },
      },
    },
  },
};
