import React, { FC, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  UseDisclosureProps,
  Image,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import RegisterImg from '@app/assets/images/youtube_A2P10DLC_Registration.png';
import ROUTES from '@app/utils/routes';
import { useDlcStatus } from '@app/hooks/useDlcStatus';

import Popup from '../Popup';

import Player from './Player';

const videoMain = 'https://www.loom.com/embed/e21a32777a62465d8d6e05a8783a25af';
const videoSecondary =
  'https://www.loom.com/embed/6f7769d64c8d419483928cb224b5eb2b';

const RegisterBrandModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();

  const { isSecondaryAccount } = useDlcStatus();

  const goToPage = useCallback(() => {
    onClose();
    history.push(`${ROUTES.settings}/dlc-registration`);
  }, [history, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="455px"
      title="A2P 10DLC Registration"
      onClose={onClose}>
      <Box p="20px 40px 40px">
        <Box m="0 0 25px" position="relative">
          <Player
            image={<Image minHeight="211px" src={RegisterImg} />}
            type="loom"
            video={isSecondaryAccount ? videoSecondary : videoMain}
          />
        </Box>
        <Text textAlign="center" variant="list-style">
          We’re asking that ALL customers register. The process is simple &
          we’re committed to making things as smooth as possible. We recommend
          watching the video above ⬆️
        </Text>
        <Flex justifyContent="center" mt="25px">
          <Button variant="primary" w="235px" onClick={goToPage}>
            Сomplete the form
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default RegisterBrandModal;
