import { MutableRefObject } from 'react';
import type Plivo from 'plivo-browser-sdk';
import type { CallInfo } from 'plivo-browser-sdk/managers/callSession';
import { Device, Call as TwilioCall } from '@twilio/voice-sdk';

import type { Contact, MessagingProviderType } from '@app/api/queries/types';

export enum CallType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export interface Call {
  fullName: Contact['fullName'];
  phoneNumber: string;
  outgoingPhoneNumber?: string;
  contactId: Contact['id'];
  type: CallType;
  callUUID?: CallInfo['callUUID'];
}

export enum DialerStatus {
  IDLE = 'IDLE',
  CALLING = 'CALLING',
  ONCALL = 'ONCALL',
  HANGINGUP = 'HANGINGUP',
}

export interface DialerContextValues {
  isMicMuted: boolean;
  isLoggedIn: boolean;
  status: DialerStatus;
  currentCall?: Call | null;
  toggleMic: () => void;
  setCurrentCallDetails: (call: Call | null) => void;
  setDialerStatus: (status: DialerStatus) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  plivoInstance: MutableRefObject<Plivo>;
  twilioInstance: MutableRefObject<Device>;
  twilioCallInstance: MutableRefObject<TwilioCall | null>;
  providerType: MessagingProviderType;
}

export interface DialerProps extends Partial<DialerContextValues> {
  isLoggedIn: boolean;
  makeCall: (callInfo: Call) => void;
  hangupCall: () => void;
  rejectCall: () => void;
  answerCall: () => void;
}
