import React, { FC } from 'react';
import { Container } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Navigation from './navigation/Navigation';
import CypressBindings from './components/CypressBindings';
import OfflineMode from './components/OfflineMode';
import PageViewLogger from './components/PageViewLogger';
import FirebaseProvider from './providers/FirebaseProvider';
import { useOnlineStatus } from './hooks/useOnlineStatus';

const isProduction = process.env.NODE_ENV === 'production';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 900000, refetchOnWindowFocus: false, retry: false },
  },
});

const App: FC = () => {
  const isOnline = useOnlineStatus();

  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_APP_ID}
      autoBoot={isProduction}>
      <QueryClientProvider client={queryClient}>
        <FirebaseProvider>
          <HelmetProvider>
            <Router>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
              <CypressBindings.ExportFirebase />
              <CypressBindings.ExportRouter />
              <PageViewLogger />
              <Container
                backgroundColor="catskillWhite"
                height="100vh"
                maxWidth="100%"
                overflow="hidden"
                p="0">
                {isOnline ? <Navigation /> : <OfflineMode />}
              </Container>
            </Router>
          </HelmetProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </IntercomProvider>
  );
};

export default App;
