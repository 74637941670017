import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { ErrorBoundary } from '@sentry/react';
import { Box } from '@chakra-ui/react';

import PopupsProvider from '@app/providers/PopupsProvider';
import { useBootstrap } from '@app/hooks/useBootstrap';
import CurrentAccountProvider from '@app/providers/CurrentAccountProvider';
import { ErrorFallback } from '@app/components/ErrorFallback';
import AdminRouteGuard from '@app/navigation/AdminRouteGuard';
import EntityMutationNotifier from '@app/components/EntityMutationNotifier';
import { useUserTracker } from '@app/hooks/useUserTracker';
import { PageTitle } from '@app/components/PageTitle';
import { DialerProvider } from '@app/providers/DialerProvider';
import PreloadData from '@app/components/PreloadData';

import ROUTES from '../utils/routes';
import Skeleton from '../components/Skeleton';

import DefaultLayout from './components/DefaultLayout';
import UINavigationPanel from './UINavigationPanel';
import { getInitialRoute } from './utils';

const AuthNavigation = lazy(() => import('./AuthNavigation'));
const Questionnaire = lazy(() => import('@app/pages/Questionnaire'));
const Messenger = lazy(() => import('@app/pages/Messenger'));
const MessengerFull = lazy(() => import('@app/pages/MessengerFull'));
const Campaigns = lazy(() => import('@app/pages/Campaigns'));
const CreateCampaign = lazy(
  () => import('@app/pages/Campaigns/content/CreateCampaign'),
);
const Macros = lazy(() => import('@app/pages/Macros'));
const Calendar = lazy(() => import('@app/pages/Calendar'));
const Contacts = lazy(() => import('@app/pages/Contacts'));
const Reporting = lazy(() => import('@app/pages/Reporting'));
const Affiliate = lazy(() => import('@app/pages/Affiliate'));
const PopupConstructor = lazy(() => import('@app/pages/PopupConstructor'));
const UserSetup = lazy(() => import('@app/pages/UserSetup'));
const Skiptrace = lazy(() => import('@app/pages/Skiptrace'));
const Settings = lazy(() => import('@app/pages/Settings'));

const OAuth = lazy(() => import('@app/pages/OAuth'));
const Logout = lazy(() => import('@app/pages/Logout'));

const NotFound = lazy(() => import('@app/components/NotFound'));
const Dialer = lazy(() => import('@app/features/Dialer'));

const Navigation: FC = () => {
  const {
    location: { pathname },
  } = useHistory();

  const isLogout = pathname.startsWith(ROUTES.logout);

  const data = useBootstrap();

  useUserTracker(data.firebaseAuthUser);

  if (!data.bootstrapped) {
    return <Skeleton />;
  }

  const initialRoute = getInitialRoute(data);

  const { registered, account } = data;

  return (
    <>
      <Redirect exact to={initialRoute} />
      <PageTitle />
      <Switch>
        {!registered && (
          <Suspense fallback={<Skeleton />}>
            <AuthNavigation />
          </Suspense>
        )}

        {registered && !account && (
          <Suspense fallback={<Skeleton />}>
            <Route
              exact
              path={ROUTES.questionnaire}
              render={() => <Questionnaire user={data.firebaseAuthUser} />}
            />
          </Suspense>
        )}

        {registered && account && (
          <ErrorBoundary
            fallback={({ resetError }) => (
              <ErrorFallback resetError={resetError} />
            )}>
            <CurrentAccountProvider account={data.account} role={data.role}>
              <DialerProvider>
                <PopupsProvider>
                  <PreloadData />
                  <EntityMutationNotifier>
                    {isBrowser && !isLogout && <UINavigationPanel />}
                    <Box mx={['0px', '20px']}>
                      <Suspense fallback={<DefaultLayout />}>
                        <Route
                          path={ROUTES.messenger}
                          render={() => <Messenger />}
                        />
                        <Route
                          path={`${ROUTES.messengerFull}/:contactId?/:tabId?`}
                          render={() => <MessengerFull />}
                        />
                        <Route
                          path={ROUTES.campaigns}
                          render={() => <Campaigns />}
                        />
                        <Route
                          path={[ROUTES.createCampaign, ROUTES.editCampaign]}
                          render={() => <CreateCampaign />}
                        />
                        <Route
                          path={ROUTES.contacts}
                          render={() => <Contacts />}
                        />
                        <Route
                          path={`${ROUTES.calendar}/:view?`}
                          render={() => <Calendar />}
                        />
                        <Route
                          path={ROUTES.skiptrace}
                          render={() => <Skiptrace />}
                        />
                        <Route
                          path={ROUTES.reporting}
                          render={() => <Reporting />}
                        />
                        <AdminRouteGuard path={ROUTES.userSetup}>
                          <UserSetup />
                        </AdminRouteGuard>
                        <Route
                          path={ROUTES.affiliates}
                          render={() => <Affiliate />}
                        />
                        <Route
                          path={ROUTES.popups}
                          render={() => <PopupConstructor />}
                        />
                        <Route path={ROUTES.macros} render={() => <Macros />} />
                        <Route
                          path={ROUTES.settings}
                          render={() => <Settings />}
                        />
                        <Dialer />

                        <Route path={ROUTES.oauth} render={() => <OAuth />} />
                        <Route path={ROUTES.logout} render={() => <Logout />} />

                        <Route
                          component={() => <NotFound />}
                          path={ROUTES.notFound}
                        />
                      </Suspense>
                    </Box>
                  </EntityMutationNotifier>
                </PopupsProvider>
              </DialerProvider>
            </CurrentAccountProvider>
          </ErrorBoundary>
        )}
      </Switch>
    </>
  );
};

export default Navigation;
