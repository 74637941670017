import { Ability } from '@casl/ability';

export type ValueOf<T> = T[keyof T];

export const Features = {
  RVM: 'rvm',
  FreeLookup: 'free-lookup',
  APIIntegration: 'api-integration',
  TextSuppressions: 'text-suppressions',
  CampaignThrottling: 'campaign-throttling',
  CampaignScheduling: 'campaign-scheduling',
  CampaignThrottlingActive: 'campaign-throttling-active',
  CampaignSchedulingActive: 'campaign-scheduling-active',
  DripCampaigns: 'drip-campaigns',
  CampaignRules: 'campaign-rules',
  CampaignRulesActive: 'campaign-rules-active',
  MobileTextAlerts: 'mobile-text-alerts',
  ShowMobileTextAlerts: 'show-mobile-text-alerts',
  SubAccounts: 'sub-accounts',
  ShowSubAccounts: 'show-sub-accounts',
  Dialer: 'dialer',
  ShowDialer: 'show-dialer',
  SMSLimitPopup: 'sms-limit-popup',
  ActiveIncoming: 'active-inocming',
  IncomingCalls: 'incoming-calls',
  MessageSchedule: 'message-schedule',
  V2: 'v2',
} as const;

export const FeatureActions = {
  ENABLED: 'enabled',
} as const;

export const Permissions = {
  Skiptrace: 'skiptrace',
  Campaign: 'campaign',
  PhoneNumber: 'phone-number',
  Conversation: 'conversation',
  Label: 'label',
  ReplyMessenger: 'reply-messenger',
  Favorite: 'favorite',
  Dlc: 'dlc',
  WelcomePopup: 'welcome-popup',
  Membership: 'membership',
  Template: 'template',
} as const;

export const PermissionActions = {
  INTERACTION: 'interaction',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  BUY: 'buy',
} as const;

export type FeatureActionsType = ValueOf<typeof FeatureActions>;
export type FeaturesType = ValueOf<typeof Features>;
export type FeaturesAbilityType = Ability<[FeatureActionsType, FeaturesType]>;

export type PermissionActionsType = ValueOf<typeof PermissionActions>;
export type PermissionsType = ValueOf<typeof Permissions>;
export type PermissionsAbilityType = Ability<
  [PermissionActionsType, PermissionsType]
>;
