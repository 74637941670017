import { useMemo } from 'react';

import { A2PStatus, A2PType, AccountStatus } from '@app/api/queries/types';
import { usePhoneNumberData } from '@app/api/queries/usePhoneNumberData';

import { useCurrentAccountData } from './useCurrentAccountData';

export const useDlcStatus = () => {
  const account = useCurrentAccountData();

  const { data: phones } = usePhoneNumberData({
    orderBy: [['createdAt', 'desc']],
    where: [['a2pStatus', '==', A2PStatus.APPROVED]],
    limit: 1,
  });

  const messaging = useMemo(() => account?.messaging, [account]);

  const isAccountStatusTrial = useMemo(
    () => account?.status === AccountStatus.TRIAL,
    [account.status],
  );

  const isSecondaryAccount = useMemo(
    () => !!account?.masterAccount,
    [account?.masterAccount],
  );

  const isAccountHasPhones = useMemo(() => !!phones?.length, [phones?.length]);

  const isAccountTrialHasNoPhones = useMemo(
    () => isAccountStatusTrial && !isAccountHasPhones,
    [isAccountHasPhones, isAccountStatusTrial],
  );

  const isNoBrand = useMemo(
    () =>
      !messaging?.status ||
      ![A2PStatus.APPROVED, A2PStatus.PENDING, A2PStatus.REVIEW].some((el) =>
        messaging?.status.includes(el),
      ),
    [messaging?.status],
  );

  const statusExists = useMemo(
    () => !!(messaging?.status && messaging?.status !== A2PStatus.NONE),
    [messaging],
  );

  const isStarter = useMemo(
    () => messaging?.a2pType === A2PType.STARTER,
    [messaging?.a2pType],
  );

  const isStandard = useMemo(
    () => messaging?.a2pType === A2PType.STANDARD,
    [messaging?.a2pType],
  );

  const isPending = useMemo(
    () => [A2PStatus.PENDING, A2PStatus.REVIEW].includes(messaging?.status),
    [messaging?.status],
  );

  const isDeclined = useMemo(
    () => messaging?.status === A2PStatus.DECLINED,
    [messaging?.status],
  );

  const isApproved = useMemo(
    () => messaging?.status === A2PStatus.APPROVED,
    [messaging?.status],
  );

  const isStarterApproved = useMemo(
    () => isStarter && isApproved,
    [isApproved, isStarter],
  );

  const isStandardApproved = useMemo(
    () => isStandard && isApproved,
    [isApproved, isStandard],
  );

  const isStandardPending = useMemo(
    () => isStandard && isPending,
    [isStandard, isPending],
  );

  const isStarterUpdated = useMemo(
    () => isStarter && messaging?.status === A2PStatus.NONE,
    [isStarter, messaging?.status],
  );

  return {
    isNoBrand,
    isStarter,
    isPending,
    isDeclined,
    isStarterApproved,
    isStandardApproved,
    isStarterUpdated,
    isApproved,
    statusExists,
    isStandardPending,
    messaging,
    isAccountStatusTrial,
    isAccountHasPhones,
    isAccountTrialHasNoPhones,
    isSecondaryAccount,
  };
};
