import { defineAbility } from '@casl/ability';

import { A2PStatus, A2PType, AccountStatus } from '@app/api/queries/types';
import Account, { AccountType } from '@app/types/Account';
import { PlanPermissionType } from '@app/hooks/usePlanPermission';

import {
  Features,
  FeatureActions,
  FeaturesAbilityType,
  Permissions,
  PermissionActions,
  PermissionsAbilityType,
} from './types';

export * from './types';

export function defineFeatureRules(
  { masterAccount, status, messaging }: Account,
  {
    isAdvanced,
    isMiddleAdvanced,
    isNewPro,
    isOldElite,
    isAllNewPlans,
    isElite,
    isNewStarter,
  }: PlanPermissionType,
) {
  const isStandard10DLC =
    messaging?.a2pType === A2PType.STANDARD &&
    messaging?.status === A2PStatus.APPROVED;

  return defineAbility<FeaturesAbilityType>((can, cannot) => {
    can(FeatureActions.ENABLED, Features.Dialer);
    can(FeatureActions.ENABLED, Features.ShowDialer);
    can(FeatureActions.ENABLED, Features.SMSLimitPopup);
    can(FeatureActions.ENABLED, Features.CampaignRules);
    can(FeatureActions.ENABLED, Features.CampaignRulesActive);
    can(FeatureActions.ENABLED, Features.ShowSubAccounts);
    can(FeatureActions.ENABLED, Features.ActiveIncoming);
    can(FeatureActions.ENABLED, Features.IncomingCalls);
    can(FeatureActions.ENABLED, Features.CampaignThrottlingActive);
    can(FeatureActions.ENABLED, Features.CampaignSchedulingActive);
    can(FeatureActions.ENABLED, Features.MessageSchedule);
    cannot(FeatureActions.ENABLED, Features.MobileTextAlerts);
    cannot(FeatureActions.ENABLED, Features.ShowMobileTextAlerts);
    cannot(FeatureActions.ENABLED, Features.APIIntegration);

    if (isAdvanced && isStandard10DLC) {
      can(FeatureActions.ENABLED, Features.RVM);
    }

    if (isAdvanced) {
      can(FeatureActions.ENABLED, Features.TextSuppressions);
      can(FeatureActions.ENABLED, Features.CampaignThrottling);
      can(FeatureActions.ENABLED, Features.CampaignScheduling);
    }

    if (isMiddleAdvanced) {
      can(FeatureActions.ENABLED, Features.DripCampaigns);
      can(FeatureActions.ENABLED, Features.APIIntegration);
    }

    if (isAllNewPlans || isOldElite) {
      can(FeatureActions.ENABLED, Features.FreeLookup);
    }

    if (isNewPro || isOldElite) {
      cannot(FeatureActions.ENABLED, Features.SMSLimitPopup);
    }

    if (isElite) {
      can(FeatureActions.ENABLED, Features.SubAccounts);
      can(FeatureActions.ENABLED, Features.MobileTextAlerts);
      can(FeatureActions.ENABLED, Features.ShowMobileTextAlerts);
    }

    cannot(FeatureActions.ENABLED, Features.V2);

    if (status === AccountStatus.INACTIVE) {
      cannot(FeatureActions.ENABLED, Features.Dialer);
    }

    if (isNewStarter) {
      cannot(FeatureActions.ENABLED, Features.Dialer);
      cannot(FeatureActions.ENABLED, Features.IncomingCalls);
    }

    if (status === AccountStatus.TRIAL) {
      cannot(FeatureActions.ENABLED, Features.SubAccounts);
      cannot(FeatureActions.ENABLED, Features.MobileTextAlerts);
      cannot(FeatureActions.ENABLED, Features.ActiveIncoming);
      cannot(FeatureActions.ENABLED, Features.IncomingCalls);
      cannot(FeatureActions.ENABLED, Features.DripCampaigns);
      cannot(FeatureActions.ENABLED, Features.CampaignSchedulingActive);
      cannot(FeatureActions.ENABLED, Features.CampaignThrottlingActive);
      cannot(FeatureActions.ENABLED, Features.CampaignRulesActive);
      cannot(FeatureActions.ENABLED, Features.ShowDialer);
      cannot(FeatureActions.ENABLED, Features.MessageSchedule);
    }

    if (masterAccount) {
      cannot(FeatureActions.ENABLED, Features.SubAccounts);
      cannot(FeatureActions.ENABLED, Features.ShowSubAccounts);
    }
  });
}

export function definePermissionRules({
  accountType,
  status,
  masterAccount,
}: Account) {
  return defineAbility<PermissionsAbilityType>((can, cannot) => {
    can(PermissionActions.CREATE, Permissions.Skiptrace);
    can(PermissionActions.INTERACTION, Permissions.WelcomePopup);
    can(PermissionActions.INTERACTION, Permissions.Membership);
    can(PermissionActions.CREATE, Permissions.Template);
    can(PermissionActions.UPDATE, Permissions.Template);
    can(PermissionActions.DELETE, Permissions.Template);
    if (status === AccountStatus.ACTIVE) {
      can(PermissionActions.CREATE, Permissions.Dlc);
      can(PermissionActions.INTERACTION, Permissions.WelcomePopup);
    }
    if (status === AccountStatus.ACTIVE || status === AccountStatus.TRIAL) {
      can(PermissionActions.CREATE, Permissions.Campaign);
      can(PermissionActions.CREATE, Permissions.ReplyMessenger);
      can(PermissionActions.CREATE, Permissions.Conversation);

      can(PermissionActions.BUY, Permissions.PhoneNumber);

      can(PermissionActions.UPDATE, Permissions.Label);
      can(PermissionActions.UPDATE, Permissions.Favorite);
    }
    if (status === AccountStatus.TRIAL) {
      cannot(PermissionActions.CREATE, Permissions.Template);
      cannot(PermissionActions.DELETE, Permissions.Template);
      if (accountType === AccountType.REAL_ESTATE) {
        cannot(PermissionActions.UPDATE, Permissions.Template);
      }
    }
    if (masterAccount) {
      cannot(PermissionActions.INTERACTION, Permissions.WelcomePopup);
      cannot(PermissionActions.INTERACTION, Permissions.Membership);
    }
  });
}
