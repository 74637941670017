const Avatar = {
  parts: ['container', 'excessLabel', 'label'],

  baseStyle: {
    container: {
      background: 'gallery',
    },
    excessLabel: {},
    label: {
      color: 'main.400',
      fontWeight: 500,

      '&.chakra-avatar__initials': {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
  },
  sizes: {
    md: {
      container: {
        width: '38px',
        height: '38px',
      },
    },
  },
};

export default Avatar;
