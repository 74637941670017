import {
  QueryDocumentSnapshot,
  startAfter,
  query,
  Query,
} from 'firebase/firestore';
import last from 'lodash/last';
import size from 'lodash/size';

export const DEFAULT_LIMIT = process.env.NODE_ENV === 'development' ? 10 : 25;

export const createConvertor = <T>() => {
  return {
    fromFirestore(snapshot: QueryDocumentSnapshot<T>) {
      return {
        ...snapshot.data(),
        id: snapshot.id,
        path: snapshot.ref.path,
        ref: snapshot.ref,
      };
    },
    toFirestore(modelObject: T) {
      return modelObject;
    },
  };
};

export const createInfiniteNextQuery =
  <T>(dataQuery: Query<T>, limit: number, afterKey = 'createdAt') =>
  (snapshot: T[]) => {
    if (size(snapshot) < limit) {
      return undefined;
    }

    const lastDocument = last(snapshot);

    if (lastDocument[afterKey]) {
      return query(dataQuery, startAfter(lastDocument[afterKey]));
    }

    return undefined;
  };
