import { useRouteMatch } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

const ROUTES_NOT_USED_DEFAULT_LAYOUT = [ROUTES.messenger];

export const useDefaultLayoutInMobile = () => {
  const { url } = useRouteMatch();

  return !ROUTES_NOT_USED_DEFAULT_LAYOUT.includes(url);
};
