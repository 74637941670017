import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip = {
  baseStyle: {
    borderRadius: '8px',
    backgroundColor: 'white',
    color: 'arsenic',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 12px',
    boxShadow: '0px 6px 18px rgba(139, 139, 139, 0.25)',
    [$arrowBg.variable]: 'white',
  },
};
