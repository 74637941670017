import { useMemo } from 'react';

import {
  definePermissionRules,
  PermissionsType,
  PermissionActionsType,
} from '@app/acl';

import { useCurrentAccountData } from './useCurrentAccountData';

export const usePermissionAbility = (
  action: PermissionActionsType,
  subject: PermissionsType,
) => {
  const account = useCurrentAccountData();

  const ability = useMemo(() => definePermissionRules(account), [account]);

  return {
    can: ability.can(action, subject),
  };
};
