import React, { FC, ReactNode, useState } from 'react';
import YouTube, { PlayerVars } from 'react-youtube';

import PlayIcon from './PlayIcon';

const videoOptions = {
  playerVars: {
    autoplay: 1,
    controls: 1,
    rel: 0,
    showinfo: 0,
    mute: 0,
    loop: 1,
  },
} as PlayerVars;

interface PlayerProps {
  video: string;
  image?: ReactNode;
  width?: string;
  height?: string;
  type?: 'youtube' | 'loom';
  fullWidth?: boolean;
  isButtonShadow?: boolean;
}

const Player: FC<PlayerProps> = ({
  video,
  image,
  width = '375',
  height = '235',
  fullWidth = false,
  type = 'youtube',
  isButtonShadow = false,
}) => {
  const [showVideo, setShowVideo] = useState(image ? false : true);

  const componentMap = {
    youtube: (
      <YouTube opts={{ ...videoOptions, width, height }} videoId={video} />
    ),
    loom: (
      <iframe
        allowFullScreen={true}
        frameBorder="0"
        height={height}
        src={video}
        width={fullWidth ? '100%' : width}
      />
    ),
  };

  return (
    <>
      {showVideo ? (
        componentMap[type]
      ) : (
        <>
          {image}
          <PlayIcon
            isButtonShadow={isButtonShadow}
            onClick={() => setShowVideo(true)}
          />
        </>
      )}
    </>
  );
};

export default Player;
