import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { ReactComponent as Icon } from '@app/icons/play-icon-blue.svg';

const PlayIcon: FC<{ onClick: () => void; isButtonShadow: boolean }> = ({
  onClick,
  isButtonShadow,
}) => {
  return (
    <Flex
      _hover={{
        opacity: 0.9,
        boxShadow: isButtonShadow && '0px 7px 31px rgba(68, 68, 68, 0.22)',
      }}
      alignItems="center"
      bg="whiteAlpha.900"
      borderRadius="full"
      boxShadow={isButtonShadow && '0px 7px 31px rgba(68, 68, 68, 0.52)'}
      cursor="pointer"
      h="72px"
      justifyContent="center"
      left="calc(50% - 36px)"
      position="absolute"
      top="calc(50% - 36px)"
      transition="all 0.1s linear"
      w="72px"
      onClick={onClick}>
      <Icon />
    </Flex>
  );
};

export default PlayIcon;
