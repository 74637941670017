export const Link = {
  variants: {
    inline: {
      textDecoration: 'none',
      color: '#2f80ed',
      _hover: {
        textDecoration: 'none',
      },
    },
    primary: {
      color: 'primary.600',
    },
    link: {
      textDecoration: 'underline',
      color: 'primary.600',
      _hover: {
        textDecoration: 'none',
      },
    },
    textLink: {
      textDecoration: 'none',
      color: 'main.400',
      _hover: {
        textDecoration: 'none',
        color: 'primary.600',
      },
    },
  },
};
