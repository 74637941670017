import { useAuth } from 'reactfire';
import { useAuthSignOut } from '@react-query-firebase/auth';
import { useHistory } from 'react-router';

import { useTracking } from 'react-tracking';
import ROUTES from '@app/utils/routes';

export const useSignOut = (): {
  signOut: () => void;
  redirectToLogout: () => void;
} => {
  const history = useHistory();
  const auth = useAuth();
  const { trackEvent } = useTracking();
  const logoutMutation = useAuthSignOut(auth);

  const signOut = async () => {
    trackEvent({ event: 'sign_out', user_id: '' });
    await logoutMutation.mutateAsync();

    window.location.replace(ROUTES.login);
  };

  const redirectToLogout = () => {
    history.push(ROUTES.logout);
  };

  return {
    signOut,
    redirectToLogout,
  };
};
