import React, { memo } from 'react';

const HelpIcon = () => {
  return (
    <svg
      fill="none"
      height="20px"
      viewBox="0 0 20 20"
      width="20px"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#help_svg__clip0)">
        <path
          d="M9.908 5.021c1.107.046 2.127.515 2.69 1.709.44.924.288 1.816-.32 2.609-.315.41-.71.761-1.09 1.12-.423.4-.716.833-.692 1.455.017.426-.348.731-.769.726-.42-.006-.727-.311-.755-.753a2.704 2.704 0 01.665-2.004c.298-.346.64-.66.96-.98.136-.137.28-.266.401-.414.487-.592.347-1.319-.33-1.695-.976-.557-2.049-.154-2.41.905-.15.44-.55.66-.97.543-.42-.117-.627-.55-.51-.99.365-1.37 1.5-2.222 3.13-2.231zM10.507 14.174a.77.77 0 11-1.538.03.77.77 0 011.538-.03z"
          fill="#8F9EAF"
        />
        <circle cx={10} cy={10} r={9.35} stroke="#8F9EAF" strokeWidth={1.3} />
      </g>
      <defs>
        <clipPath id="help_svg__clip0">
          <path d="M0 0h20v19.992H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoHelpIcon = memo(HelpIcon);
export default MemoHelpIcon;
