export const AudioPlayer = {
  parts: [
    'container',
    'play',
    'pause',
    'timer',
    'progressBar',
    'progressBarRange',
  ],

  baseStyle: {
    container: {
      border: '1px solid var(--chakra-colors-primary-500)',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    play: { color: 'var(--chakra-colors-primary-600)' },
    pause: { color: 'var(--chakra-colors-secondary-300)' },
    timer: { color: 'main.400' },
    progressBar: { backgroundColor: 'var(--chakra-colors-primary-100)' },
    progressBarRange: { backgroundColor: 'var(--chakra-colors-primary-500)' },
  },
  variants: {
    RVM: {
      container: {
        paddingLeft: '10px',
        paddingRight: '18px',
      },
      play: { color: 'white' },
      pause: { color: 'white' },
      timer: { color: 'white' },
      progressBar: { backgroundColor: 'var(--chakra-colors-main-400)' },
      progressBarRange: { backgroundColor: 'white' },
    },
  },
};
