import React, { FC, useCallback } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { usePopupsContext } from '@app/hooks/usePopupsContext';

import Popup from '../Popup';

interface ErrorModalProps {
  error: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ErrorModal: FC<ErrorModalProps> = ({
  error,
  title = 'Notification',
  isOpen,
  onClose,
}) => {
  const { setError } = usePopupsContext();
  const onClosePopup = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose, setError]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="350px"
      title={title}
      titlePadding="40px 0 20px"
      onClose={onClosePopup}>
      <Box p="0 40px 40px">
        <Text
          sx={{
            a: {
              color: 'primary.600',
            },
          }}
          textAlign="center">
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </Text>
        <Flex justifyContent="center" mt="30px">
          <Button variant="primary" w="110px" onClick={onClosePopup}>
            Close
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default ErrorModal;
