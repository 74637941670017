import { useCallback, useEffect, useRef, useReducer, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  DocumentSnapshot,
  onSnapshot,
  limit,
  query,
  where,
  CollectionReference,
  Unsubscribe,
} from 'firebase/firestore';

import Account, { AccountClaimRole } from '@app/types/Account';
import Bootstrap from '@app/types/Bootstrap';
import { catchErrorLog } from '@app/utils/logger';
import { AccountStatus } from '@app/api/queries/types';

import { CLAIMS_KEY, useInitialUserDetection } from './useInitialUserDetection';
import { useEmailVerification } from './useEmailVerification';

// initial state
const initialState = {
  account: undefined as DocumentSnapshot<Account>,
  registered: false,
  bootstrapped: false,
  role: undefined as AccountClaimRole,
};

type Action =
  | { type: 'guest' }
  | {
      type: 'login';
      account: DocumentSnapshot<Account>;
      role: AccountClaimRole;
    }
  | { type: 'logout' };

const bootstrapReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'guest':
      return { ...state, bootstrapped: true };
    case 'login':
      return {
        registered: true,
        bootstrapped: true,
        account: action.account,
        role: action.role,
      };
    case 'logout':
      return { ...initialState };
    default:
      return state;
  }
};

export const useBootstrap = (): Bootstrap => {
  const firestore = useFirestore();
  const { status, data } = useInitialUserDetection();
  const { status: verificationStatus } = useEmailVerification();

  const [state, dispatch] = useReducer(bootstrapReducer, initialState);

  const unsubscribeRef = useRef<Unsubscribe>();

  const role = useMemo<AccountClaimRole>(
    () =>
      (data?.errors?.[CLAIMS_KEY] as Record<string, string>)
        ?.role as AccountClaimRole,
    [data],
  );

  const isImpersonate = useMemo(
    () => role === AccountClaimRole.IMPERSONATE,
    [role],
  );

  const run = useCallback(
    (id: string) => {
      const accountsQuery = query<Account>(
        collection(firestore, 'accounts') as CollectionReference<Account>,
        where(`members.${id}`, '==', 'owner'),
        limit(1),
      );

      return onSnapshot(
        accountsQuery,
        (snapshots) => {
          const account = snapshots?.docs[0];

          const isAccountInvalid =
            account === undefined ||
            account?.get('status') === AccountStatus.ABANDONED;

          dispatch({
            type: 'login',
            role,
            account: isAccountInvalid ? undefined : account,
          });
        },
        (error) => {
          catchErrorLog(error, 'useBootstrap/onSnapshot/error');
          if (['permission-denied', 'unauthenticated'].includes(error.code)) {
            dispatch({ type: 'logout' });
          }
        },
      );
    },
    [firestore, role],
  );

  useEffect(() => {
    // guest case
    if (status === 'success' && !data?.user && state.bootstrapped === false) {
      dispatch({ type: 'guest' });
    }

    // logout case
    if (state.account && !data?.user && state.bootstrapped === false) {
      dispatch({ type: 'logout' });
    }

    if (
      status === 'success' &&
      !state.account &&
      data?.user &&
      state.registered === false
    ) {
      unsubscribeRef.current = run(data.user.uid);
    }
  }, [data, state, status, run]);

  useEffect(() => () => unsubscribeRef.current?.(), []);

  return {
    account: state.account,
    role: state.role,
    firebaseAuthUser: data?.user,
    registered:
      state.registered && (isImpersonate || !!data?.user?.emailVerified),
    bootstrapped:
      status !== 'loading' &&
      verificationStatus !== 'loading' &&
      state.bootstrapped,
  };
};
