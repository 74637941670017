export const Badge = {
  variants: {
    color: {
      display: 'flex',
      borderRadius: '22px',
      padding: '0px 7px',
      lineHeight: '28px',
      height: '28px',
      flex: '1',
      textTransform: 'none',
      fontWeight: '400',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
};

export default Badge;
