import React from 'react';
import { useAuth, useFirestore, useFunctions, useStorage } from 'reactfire';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebaseStorage } from 'firebase/storage';
import { useHistory } from 'react-router';
import { History } from 'history';

export interface WindowWithCypressBindings extends Window {
  Cypress?: unknown;
  cy_auth?: Auth;
  cy_firestore?: Firestore;
  cy_functions?: Functions;
  cy_storage?: FirebaseStorage;
  cy_history?: History;
}

const ExportFirebase: React.FC = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const functions = useFunctions();
  const storage = useStorage();

  const w: WindowWithCypressBindings = window;

  if (w.Cypress) {
    w.cy_auth = auth;
    w.cy_firestore = firestore;
    w.cy_functions = functions;
    w.cy_storage = storage;
  }

  return null;
};

const ExportRouter: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory();

  const w: WindowWithCypressBindings = window;

  if (w.Cypress) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    w.cy_history = history;
  }

  return null;
};

export default {
  ExportFirebase,
  ExportRouter,
};
