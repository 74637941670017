import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';

import useCurrentAccount from '@app/hooks/useCurrentAccount';
import Account from '@app/types/Account';

import { PayloadUpdate } from '../queries/types';

export const useAccountMutation = (notify = false) => {
  const account = useCurrentAccount();

  return useFirestoreDocumentMutation<PayloadUpdate<Account>>(
    account.ref,
    {
      merge: true,
    },
    {
      notify: notify
        ? {
            operation: 'update',
          }
        : undefined,
    },
  );
};
