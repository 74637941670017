import sbjs from 'sourcebuster';

const initSourceBuster = () => {
  sbjs.init({
    domain: {
      host: 'smartercontact.com',
      isolate: false,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.__sbjs = sbjs;
};

export default initSourceBuster;
