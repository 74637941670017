import React, { FC } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useIsMutating } from 'react-query';

import Popup from '@app/components/Popup';

interface ConfirmProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  handler: () => void;
  confirmText: string;
  description: JSX.Element;
  verticalActions?: boolean;
}

const ConfirmAlert: FC<ConfirmProps> = ({
  title,
  isOpen,
  onClose,
  handler,
  confirmText,
  description,
  verticalActions = false,
}) => {
  const isMutating = useIsMutating(['confirm-modal']);

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="374px"
      title={title}
      onClose={onClose}>
      <Box margin="0 auto" p="30px">
        {description}
        <Flex direction="row" justifyContent="center">
          <Flex
            flexDirection={verticalActions ? 'column-reverse' : 'row'}
            justifyContent="center"
            w={verticalActions && '100%'}>
            <Button
              variant={verticalActions ? 'cancel' : 'outlined'}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isMutating > 0}
              ml={!verticalActions && '20px'}
              variant="primary"
              onClick={handler}>
              {confirmText}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Popup>
  );
};

export default ConfirmAlert;
