import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';

import useCurrentAccount from '@app/hooks/useCurrentAccount';

import { Phone, QueryParams } from './types';
import { createConvertor } from './utils';

const convertor = createConvertor<Phone>();

export const usePhoneNumberData = (
  params?: QueryParams<Phone>,
  enabled = true,
) => {
  const account = useCurrentAccount();
  const items = [limit(params?.limit || Infinity)];

  if (params?.where) {
    params.where.forEach((item) => {
      items.push(where(...item));
    });
  }

  if (params?.orderBy) {
    params.orderBy.forEach(([field, direction]) => {
      items.push(orderBy(field, direction));
    });
  }

  const dataQuery = query(
    collection(account.ref, 'phones').withConverter(convertor),
    ...items,
  );

  return useFirestoreQueryData<Phone>(
    ['phones', params],
    dataQuery,
    {
      subscribe: true,
    },
    {
      enabled,
      refetchOnMount: 'always',
    },
  );
};
