import React, { FC, useCallback } from 'react';
import { Box, Text, Image, UseDisclosureProps } from '@chakra-ui/react';

import YoutubeIcon from '@app/assets/images/trial-youtube-icon.png';
import Popup from '@app/components/Popup';
import { useAccountMutation } from '@app/api/mutations/useAccountMutation';

import Player from '../10Dlc/Player';

const videoId = 'vzpl86HrdAM';

const SecondDayTrialPopup: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const accountMutation = useAccountMutation();

  const onSubmitPopup = useCallback(async () => {
    await accountMutation.mutateAsync({
      notification: {
        popupTrialSecondDay: true,
      },
    });
    onClose();
  }, [accountMutation, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="455px"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="0 40px 40px">
        <Box>
          <Text
            color="main.400"
            fontSize="22px"
            fontWeight="500"
            lineHeight="25px"
            m="40px auto 18px"
            textAlign="center">
            Congrats on your second day with Smarter Contact and hopefully
            you`re getting the hang of things.
          </Text>
        </Box>
        <Box cursor="pointer" mb="23px" position="relative">
          <Player image={<Image src={YoutubeIcon} />} video={videoId} />
        </Box>
        <Box lineHeight="22px" m="10px auto" maxW="371px" textAlign="center">
          <Text as="span" fontSize="14px">
            Today we`ll be doing a deep dive into strategy 🤠... (probably the
            most important). This is truly CAN`T MISS and if you follow these
            steps in this video, you`ll be on your way to closing your next
            deals 🏡
          </Text>
        </Box>
      </Box>
    </Popup>
  );
};

export default SecondDayTrialPopup;
