import React, { memo } from 'react';

const CloseCircle = () => {
  return (
    <svg
      fill="none"
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334zM10 6l-4 4M6 6l4 4"
        stroke="#DA100B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const CloseCircleIcon = memo(CloseCircle);
export default CloseCircleIcon;
