import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

import Account from '../types/Account';

export const useCurrentAccountData = (): Account => {
  const { currentAccount: accountSnap } = useContext(CurrentAccountContext);

  return accountSnap?.data();
};
