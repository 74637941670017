import React, { memo } from 'react';

const Close = () => {
  return (
    <svg
      fill="none"
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4l-8 8M4 4l8 8"
        stroke="#838A91"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const CloseIcon = memo(Close);
export default CloseIcon;
