import React, { memo, SVGProps } from 'react';

const UserIcon = ({ color }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.333 14v-1.333A2.667 2.667 0 0010.667 10H5.333a2.667 2.667 0 00-2.666 2.667V14M8 7.333A2.667 2.667 0 108 2a2.667 2.667 0 000 5.333z"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
      />
    </svg>
  );
};

const MemoUserIcon = memo(UserIcon);
export default MemoUserIcon;
