import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { Input } from '@chakra-ui/react';

interface CodeFieldProps {
  id: string;
  onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Code: FC<CodeFieldProps> = ({
  id,
  onKeyPress,
  onKeyDown,
  onChange,
}) => {
  return (
    <Input
      autoComplete="off"
      border="1px"
      borderRadius="24px"
      height="44px"
      id={id}
      maxLength={2}
      placeholder="- -"
      textAlign="center"
      transition="max-height .3s"
      variant="auth"
      width="90px"
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
    />
  );
};
