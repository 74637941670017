import React, { FC, useCallback } from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Flex,
  Progress,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import upperFirst from 'lodash/upperFirst';
import { useHistory } from 'react-router-dom';

import Popup from '@app/components/Popup';
import { ReactComponent as Infinity } from '@app/icons/infinity.svg';
import { ReactComponent as Checked } from '@app/icons/check-done-blue.svg';
import ROUTES from '@app/utils/routes';
import { usePlanPermission } from '@app/hooks/usePlanPermission';

interface SmsLimitPopupProps extends UseDisclosureProps {
  percent: number;
  planName: string;
}

const SmsLimitPopup: FC<SmsLimitPopupProps> = ({
  isOpen,
  onClose,
  percent,
  planName,
}) => {
  const history = useHistory();

  const { isOldStarter } = usePlanPermission();
  const percentProgress = isOldStarter ? Math.round(percent / 2) : percent;
  const onSubmitPopup = useCallback(() => {
    Cookies.set('show_sms_limit', 'false', { expires: 1 });
    onClose();
  }, [onClose]);
  const planText = upperFirst(planName);
  const onClick = useCallback(() => {
    onSubmitPopup();

    history.push(`${ROUTES.settings}/membership`);
  }, [history, onSubmitPopup]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="385px"
      title={`You've almost reached the ${planText} SMS limit`}
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="0 40px 40px">
        <Flex alignItems="center" justifyContent="space-between">
          <Box
            _after={{
              content: `''`,
              position: 'absolute',
              width: '60px',
              height: '1px',
              borderBottom: '1px dashed #dbdbdb',
              transform: 'rotate(90deg)',
              top: '50%',
              zIndex: '1',
              left: 'calc(100% - 30px)',
            }}
            _before={{
              content: `''`,
              position: 'absolute',
              width: '60px',
              height: '1px',
              borderBottom: '1px dashed #dbdbdb',
              transform: 'rotate(90deg)',
              top: '50%',
              zIndex: '1',
              left: 'calc(50% - 30px)',
            }}
            position="relative">
            <Progress
              isAnimated
              borderRadius="4px"
              colorScheme="primary"
              h="8px"
              size="xs"
              value={percentProgress}
              width="240px"
            />
          </Box>
          <Infinity />
        </Flex>

        <Flex
          color="#596469"
          fontSize="18px"
          fontWeight={500}
          mt="10px"
          textAlign="center">
          <Text flex="5">Starter</Text>
          <Text flex="5">Pro</Text>
          <Text flex="3" textAlign="right">
            Elite
          </Text>
        </Flex>
        <Box m="20px auto" maxW="202px">
          <Text fontWeight={500} mb="10px" variant="heading-fifth">
            The next tier plan up allows you:
          </Text>

          <Box>
            <Flex alignItems="center">
              <Checked />
              <Text ml="8px" variant="list-style">
                {isOldStarter
                  ? 'More Messages Per Month'
                  : 'Unlimited Messaging'}
              </Text>
            </Flex>
            <Flex>
              <Checked />
              <Text ml="8px" variant="list-style">
                More robust features
              </Text>
            </Flex>
            <Flex>
              <Checked />
              <Text ml="8px" variant="list-style">
                Cheaper SMS rates
              </Text>
            </Flex>
          </Box>
        </Box>
        <Flex
          flexDirection="row"
          justifyContent="space-around"
          m="0 auto"
          w="275px">
          <Button variant="primary" w="220px" onClick={onClick}>
            Upgrade Your Plan
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default SmsLimitPopup;
