import { useFirestoreDocumentData } from '@react-query-firebase/firestore';
import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

import { Account } from './types';
import { createConvertor } from './utils';

const convertor = createConvertor<Account>();

const UNKNOWN_ID = '-1';

export const useMasterAccountData = () => {
  const account = useCurrentAccountData();
  const firestore = useFirestore();
  const refPath = account?.masterAccount?.path ?? `accounts/${UNKNOWN_ID}`;

  const query = doc(firestore, refPath).withConverter(convertor);

  return useFirestoreDocumentData<Account>(
    ['master-account', refPath],
    query,
    {
      subscribe: true,
    },
    {
      enabled: !!account?.masterAccount,
    },
  );
};
