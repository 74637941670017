import React, { FC, ReactNode } from 'react';
import { Text } from '@chakra-ui/react';

const Label: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text color="main.300" m="18px 0 12px" variant="secondary-text">
      {children}
    </Text>
  );
};

export default Label;
