const Input = {
  parts: ['field', 'addon'],
  variants: {
    primary: {
      field: {
        border: '1px',
        borderRadius: '22px',
        borderColor: 'secondary.200',
        fontSize: '14px',
        color: 'main.400',
        px: { base: 3, lg: 5 },
        _placeholder: { color: 'secondary.400' },
        _hover: {
          borderColor: 'secondary.300',
        },
        _focus: {
          borderColor: 'primary.700',
          color: 'main.400',
        },
        _disabled: {
          borderColor: 'secondary.200',
          backgroundColor: '#F3F6F9',
          color: 'main.100',
        },
        _invalid: {
          borderColor: '#FF5630',
        },
        _value: {
          color: 'red',
        },
      },
    },
    auth: {
      field: {
        border: '1px',
        borderRadius: '24px',
        borderColor: '#DDDDDD',
        fontSize: '15px',
        height: '48px',
        color: 'darkCharcoal',
        p: '13px 20px',
        _placeholder: { color: 'dustyGray' },
        _hover: {
          borderColor: '#4F8CED',
        },
        _focus: {
          borderColor: '#4F8CED',
          color: 'darkCharcoal',
          backgroundColor: 'rgba(79, 140, 237, 0.05)',
        },
        _disabled: {
          borderColor: 'secondary.200',
          backgroundColor: '#F3F6F9',
          color: 'main.100',
        },
        _invalid: {
          borderColor: '#FF5630',
        },
        _value: {
          color: 'red',
        },
      },
    },
    search: {
      field: {
        border: '1px solid #EAEDF3',
        borderRadius: '34px',
        fontSize: '14px',
        lineHeight: '16px',
        height: '36px',
        width: '260px',
        color: '#323E4A',
        padding: '10px 40px 10px 20px',
        _placeholder: { color: '#8F9EAF' },
        _hover: {
          borderColor: '#B4BDC8',
        },
        _focus: {
          borderColor: '#1A89FF',
        },
      },
    },
  },
};

export default Input;
