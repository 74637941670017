import React, { FC, useEffect } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';

import { useServiceWorker } from '@app/hooks/useServiceWorker';
import { ReactComponent as Info } from '@app/icons/InfoIcon.svg';

const ToastContent: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Flex
      alignItems="center"
      background="primary.200"
      borderRadius="6px"
      fontSize="11px"
      lineHeight="13px"
      p="10px">
      <Box>
        <Flex alignItems="center">
          <Text as="span" color="primary.500" mr="10px">
            <Info />
          </Text>
          <Text>New content is available</Text>
        </Flex>
      </Box>
      <Button ml="15px" variant="primary" onClick={onClose}>
        Restart
      </Button>
    </Flex>
  );
};

const UpdateApp = () => {
  const toast = useToast();

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    if (showReload && waitingWorker) {
      toast({
        title: 'New content is available',
        description: '60',
        status: 'info',
        duration: 100 * 1000,
        position: 'top-right',
        containerStyle: {
          maxWidth: '270px',
          minWidth: '270px',
        },
        render: () => <ToastContent onClose={reloadPage} />,
      });
    }
  }, [reloadPage, showReload, toast, waitingWorker]);

  return <div></div>;
};

export default UpdateApp;
