import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const isDevelopment = process.env.NODE_ENV === 'development';

const bannedUrls = /(firestore.googleapis.com|clarity.ms)/g;

const initSentry = (): void => {
  console.info(
    `%c 🚀 version: ${process.env.REACT_APP_VERSION}`,
    'background: #377EF5; color: #ffffff; font-size: 16px; padding: 10px; text-align: center;',
  );
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    allowUrls: [/https?:\/\/((app|next)\.)?smartercontact\.com/],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ChunkLoadError',
      'Missing or insufficient permissions',
      'NotSupportedError',
      /Unexpected token '<'/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /chrome-extension:\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          'localhost',
          'smartercontact.com',
          'app.smartercontact.com',
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: isDevelopment ? 0 : 0.1,
    sampleRate: isDevelopment ? 0 : 1,
    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.category === 'fetch' &&
        bannedUrls.test(breadcrumb?.data?.url as string)
      ) {
        return null;
      }

      return breadcrumb;
    },
  });
};

export const handleException = (error: Error): void => {
  Sentry.captureException(error);
};

export const handleErrorMessage = (errorMessage: string): void => {
  Sentry.captureMessage(errorMessage);
};

export default initSentry;
