import { useMemo } from 'react';

import { defineFeatureRules, FeatureActions, FeaturesType } from '@app/acl';

import { useCurrentAccountData } from './useCurrentAccountData';
import { usePlanPermission } from './usePlanPermission';

export const useFeatureAbility = (subject: FeaturesType) => {
  const account = useCurrentAccountData();
  const {
    isAdvanced,
    isMiddleAdvanced,
    isNewPro,
    isOldElite,
    isNewStarter,
    isAllNewPlans,
    isElite,
  } = usePlanPermission();
  const ability = useMemo(
    () =>
      defineFeatureRules(account, {
        isAdvanced,
        isMiddleAdvanced,
        isNewPro,
        isOldElite,
        isNewStarter,
        isAllNewPlans,
        isElite,
      }),
    [
      account,
      isAdvanced,
      isAllNewPlans,
      isElite,
      isMiddleAdvanced,
      isNewStarter,
      isNewPro,
      isOldElite,
    ],
  );

  return {
    can: ability.can(FeatureActions.ENABLED, subject),
  };
};
