import { object, number, string } from 'yup';

export const feedbackSchema = object().shape({
  score: number().moreThan(0).lessThan(6).label('Score').required(),
  details: string()
    .label('Message')
    .min(1)
    .max(1024)
    .when('score', {
      is: (score: number) => [1, 2, 3].includes(score),
      then: string().required(''),
      otherwise: string().notRequired(),
    }),
});
