const ROUTES = {
  calendar: '/calendar',
  campaigns: '/campaigns',
  createCampaign: '/campaign/create',
  editCampaign: '/campaign/edit/:id',
  contacts: '/contacts',
  forgotPassword: '/forgot-password',
  login: '/login',
  logout: '/logout',
  macros: '/macros',
  messenger: '/messenger',
  messengerFull: '/messenger-full',
  reporting: '/reporting',
  signUp: '/signup',
  popups: '/popups',
  skiptrace: '/skiptrace',
  changePassword: '/change-password',
  questionnaire: '/questionnaire',
  settings: '/settings',
  oauth: '/oauth',
  notFound: '/404',
  userSetup: '/user-setup',
  affiliates: '/affiliates',
  labels: '/labels',
  groups: '/groups',
  impersonate: '/impersonate',
};

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.logout,
  ROUTES.signUp,
  ROUTES.forgotPassword,
  ROUTES.changePassword,
  ROUTES.notFound,
];

export default ROUTES;
