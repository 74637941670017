const Switch = {
  parts: ['container', 'track', 'thumb'],
  variants: {
    'api-switch': {
      container: {},
      track: {
        bg: '#FFFFFF',
        border: '1px solid #E4E4EB',
        borderRadius: '8px',

        _checked: {
          border: 'none',
          backgroundColor: '#3E7BFA',
        },
      },

      thumb: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #E4E4EB',
        borderRadius: '8px',
        boxShadow: '1px 2px 4px rgba(111, 111, 111, 0.25)',

        _checked: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },

    primary: {
      container: {},
      track: {
        bg: 'secondary.100',
        border: '1px solid',
        borderColor: 'secondary.100',
        borderRadius: '20px',
        padding: '1px',
        _checked: {
          border: '1px solid',
          borderColor: 'primary.600',
          backgroundColor: 'primary.600',
        },
      },

      thumb: {
        backgroundColor: '#FFFFFF',
        border: '0 none',
        borderRadius: '20px',
        boxShadow: '0 0 0 transparent',

        _checked: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },

    general: {
      track: {
        bg: '#DFE1E5',
        _focus: {
          boxShadow: 'unset',
        },

        _checked: {
          bg: '#067EFE',
        },
      },
    },
  },
};

export default Switch;
