const Select = {
  parts: ['field', 'icon'],
  variants: {
    settings: {
      field: {
        border: '1px solid #D8DDE2',
        borderRadius: '20px',
        paddingLeft: '20px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#90A2B1',
      },
    },
  },
};

export default Select;
