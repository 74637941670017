import React, { createContext, FC, useMemo } from 'react';
import { DocumentSnapshot } from 'firebase/firestore';

import Account, { AccountClaimRole } from '@app/types/Account';
import { AccountStatus } from '@app/api/queries/types';

interface AccountContextProps {
  currentAccount: DocumentSnapshot<Account>;
  isActiveAccount: boolean;
  isAdmin: boolean;
  isInactiveWithPlanAccount: boolean;
  isImpersonate: boolean;
}

interface AccountProviderProps {
  role: AccountClaimRole;
  account: DocumentSnapshot<Account>;
}

export const CurrentAccountContext = createContext<AccountContextProps>(null);

const CurrentAccountProvider: FC<AccountProviderProps> = ({
  role,
  account,
  children,
}) => {
  const isActiveAccount = useMemo(
    () =>
      !(
        account?.data().status === AccountStatus.INACTIVE &&
        (account?.data().plan === null || account?.data().masterAccount)
      ),
    [account],
  );

  const isInactiveWithPlanAccount = useMemo(
    () =>
      !!(
        account?.data().status === AccountStatus.INACTIVE &&
        !account?.data().masterAccount &&
        account?.data().plan
      ),
    [account],
  );

  const isImpersonate = useMemo(
    () => role === AccountClaimRole.IMPERSONATE,
    [role],
  );

  const isAdmin = useMemo(() => role === AccountClaimRole.ADMIN, [role]);

  return (
    <CurrentAccountContext.Provider
      value={{
        currentAccount: account,
        isActiveAccount,
        isInactiveWithPlanAccount,
        isImpersonate,
        isAdmin,
      }}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

export default CurrentAccountProvider;
