export const Modal = {
  parts: ['content'],
  baseStyle: {
    dialog: {
      borderRadius: '20px',
    },
  },
};

export default Modal;
