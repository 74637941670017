import React, { FC } from 'react';
import { Box, Button, Center, Text } from '@chakra-ui/react';

interface ErrorFallbackProps {
  resetError?: () => void;
}

export const ErrorFallback: FC<ErrorFallbackProps> = () => {
  return (
    <Center height="100vh">
      <Box>
        <Text>Oh no, you got an error.</Text>
        <Text>Please, try to click this button or refresh this page.</Text>
        <Center marginTop="20px">
          <Button onClick={() => location.reload()}>Click here</Button>
        </Center>
      </Box>
    </Center>
  );
};
